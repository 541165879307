@import 'Styles/colors.module.scss';
@import "Styles/variables.module.scss";

.editorLinkPanel {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: white;
  justify-content: space-between;
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $light-gray;
    margin-top: 16px;
    padding-top: 16px;
  }
}

.editorLinkPreviewPanel {
  background-color: white;
  padding: 8px 12px;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  .linkContainer {
    max-width: 300px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    .globeIcon {
      margin-right: 9px;
      color: $findest-dark-gray;
    }

    .link {
      color: inherit;
      text-decoration: inherit;
      font-family: $font-primary, $font-secondary, $font-tertiary;
      font-size: $font-size-m;
      font-weight: $font-weight-400;
    }
  }

  .actionButton {
    color: $main-text-color;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
}
