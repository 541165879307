@use 'Styles/colors.module.scss' as *;

.findestButton {
    height: 32px;
    padding: 0 16px;
    border-radius: 0.25rem;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1.246px;
    cursor: pointer;
    border: 0;
    font-family: 'IBM Plex Sans', sans-serif;
    outline: none;
    background-color: $primaryButtonColorDefault;
    color: $white;
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;

    &.hasLeftIcon {
        padding-left: 13px;
    }

    &.hasRightIcon {
        padding-right: 13px;
    }

    &:hover {
        background-color: $primaryButtonColorHover;
    }

    &.disabled {
        opacity: .15;
        cursor: not-allowed;
        &:hover {
            background-color: $primaryButtonColorDefault;
        }
    }

    &.colorless {
        color: $findest-text;
        background-color: white;
    
        &:hover {
            box-shadow: -2px 2px 6px rgba(0, 0, 0, 0.05);
            border-color: $findest-text;
        }
    }
    &.cancel {
        color: $primaryButtonColorDefault;
        background-color: white;
        &:hover {
            background-color: $primaryButtonColorDisabled;
        }
    }

    &.secondary {
        color: $main-text-color;
        background-color: $secondaryButtonColorDefault;
        &.disabled {
            opacity: .3;
            &:hover {
                background-color: $secondaryButtonColorDefault;
            }
        }
        &:hover {
            background-color: $secondaryButtonColorHover;
        }
    }

    &.tertiary {
        color: $main-text-color;
        background-color: white;
        &:hover {
            background-color: $secondaryButtonColorDefault;
        }
    }

    &.quarternary {
        color: $main-text-color;
        background-color: white;
        padding: 0;

        &:hover {
            text-decoration: underline;
        }
    }

    &.danger {
        color: $white;
        background-color: $red-500;
        &:hover {
            background-color: $red-600;
        }
    }

    &.noWrap {
        white-space: nowrap;
    }
    &.textTransformNone {
        text-transform: none;
    }
    &.iconOnly {
        padding: 0 10px;
        font-size: 1rem;
        svg {
            margin: 0;
        }
    }

    &.isLoading {
        width: 102px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $light-gray;
        pointer-events: none;
    }

    &.noPadding {
        padding: 0;
    }

    &.fullWidth {
        width: 100%
    }

    &.justifyCenter {
        justify-content: center;
    }

    .leftIcon {
        margin-right: 9.3px;        
    }

    .rightIcon {
        margin-left: 9.3px;
    }
}
