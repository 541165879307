@import "Styles/colors.module.scss";

.input {
    color: $findest-text;
    border: 1px solid $findest-gray;
    border-radius: 4px;
    font-size: 1rem;
    outline: none;
    padding: 6px 16px;
    width: 70px;
    text-align: center;
    
    &::placeholder {
        color: $findest-dark-gray;
        font-size: 0.875rem;
        line-height: 1.625rem;
        letter-spacing: 0.252px;
    }
}