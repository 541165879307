@import "Styles/colors.module.scss";

.toggleLabelContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;

    &.readOnly {
        .toggle {
            cursor: not-allowed;
        }
    }

    .toggle {
        position: relative;
        appearance: none;
        width: 24px;
        height: 12px;
        background-color: rgba(0, 0, 0, 0.38);
        border-radius: 50px;
        cursor: pointer;
        transition: 0.4s;
    
        &::after {
            position: absolute;
            content: "";
            width: 16px;
            height: 16px;
            top: calc(50% - 8px);
            left: -4px;
            background-color: white;
            border-radius: 50%;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
            transition: 0.4s;
        }
    
        &:checked {
            background-color: rgba(0, 122, 255, 0.25);
    
            &::after {
                left: 16px;
                background-color: $primaryButtonColorDefault;
            }
        }
    }
}
