@use "Styles/colors.module.scss" as *;

@mixin windowActionButton {
  width: 32px;
  height: 32px;
  font-size: 1em;
  border-radius: 4px;
  text-align: center;
  line-height: 32px;
  cursor: pointer;

  &.close {
    background-color: $light-red-2;
    color: $main-text-color;

    &:hover {
      background-color: $main-light-red;
      color: $main-gray;
    }
  }

  &.minimize {
    background-color: $light-yellow;
    color: $main-text-color;
    margin-right: 4px;

    svg {
      width: 12px;
      padding-bottom: 2px;
      transform: scaleY(1.34);
    }

    &:hover {
      background-color: $findest-yellow;
    }
  }
}
