@import "Styles/colors.module.scss";

.loginImageSlider {
    width: 100%;

    .itemContainer {
        transition: 4s opacity;
        visibility: hidden;
        opacity: 0;
        height: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 30px;
        max-width: 610px;
        margin: auto;

        > svg {
            height: 300px;
            align-self: center;
        }

        .textContainer {
            color: $white;
            font-weight: 300;
            margin-bottom: 30px;

            .title {
                font-size: 2.8rem;
                margin-bottom: .9rem;
            }

            .subTitle {
                font-size: 1.8rem;
                font-weight: 300;
                line-height: 1.2;
            }
        }

        &.currentSlide {
            visibility: visible;
            opacity: 1;
            height: auto;
        }
    }
}