@use "Styles/colors.module.scss" as colors;

.extractedTechnology {
  .addAsEntityChip {
    border-radius: 100px;
    background: colors.$turquoise-200;
    padding: 6px 9px;
    display: inline-flex;
    gap: 5px;
    color: colors.$main-black;
  }
}
