@import "Styles/colors.module.scss";
@import 'Styles/customScrollbar.module.scss';

.container {
    width: auto;
    padding: 16px 0;
    @include customScrollbar;
    overflow-x: auto;

    .header {
        padding: 0 2rem;
    }

    .mainContainer {
        display: flex;
        padding: 0 1rem 1rem;

        .objectOptionsContainer {
          display: flex;
          border-radius: 8px;
          max-width: 66%;
        }

        &.hasAdvanced {
          .objectOptionsContainer {
            max-width: 50%;
          }
        }

        .createNewCard {
            width: 300px;
            min-height: 448px;
            margin: 0 1rem;
            border-radius: 4px;
            box-shadow: -1px 1px 10px $buttonShadowColor;

            .cardHeader {
                margin-bottom: 1rem;
                padding: 2rem;
                border-radius: 4px 4px 0 0;

                &.newStudyCardHeader {
                    background-color: #5856D615;
                }

                &.newEntityCardHeader {
                    background-color: #00ADEF15;
                }

                &.newDocCardHeader {
                    background-color: #00317115;
                }

                &.newQueryCardHeader {
                    background-color: #A3007615;
                    min-height: 220px;
                }

                .cardTitle {
                    display: flex;
                    align-items: center;

                    svg {
                        font-size: 30px;
                        margin-right: 12px;
                    }

                    svg[class*="fa-book-open-reader"] {
                        color: $study-count-color;
                    }

                    svg[class*="fa-dice-d6"] {
                        color: $entity-count-color;
                    }

                    svg[class*="fa-file"] {
                        color: $document-count-color;
                        font-size: 28px;
                    }


                    svg[class*="fa-magnifying-glass"] {
                        color: $query-count-color;
                        font-size: 28px;
                    }

                    h2 {
                        padding: 0;
                    }
                }
                .cardDescription {
                    margin-top: 8px;
                }
            }

            .cardContent {
                padding: 0 2rem 2rem 2rem;
                &.isWider {
                    padding: 0 1rem 1rem 1rem;
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .container {
        min-width: 80%;
    }
    .mainContainer {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 20px;
        .objectOptionsContainer {
          max-width: 100% !important;
          flex-wrap: wrap;
          justify-content: center;
          gap: 20px;
        }
    }
}