@import 'Styles/colors.module.scss';
@import 'Styles/table.module.scss';

.editorContent {
    word-break: break-word;
    display: block;

    p[data-placeholder] {
        position: relative;
    
        &:before {
            content: attr(data-placeholder);
            position: absolute;
            color: $findest-dark-gray;
            cursor: text;
            font-style: italic;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.496px;
            top: 0px;
            text-align: justify;
        }
    }

    div {
        outline: none;
    
        * {
            &:first-child {
                margin-top: 0;
            }
        }
    }

    a {
        cursor: pointer;
    }
}