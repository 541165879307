@import "Styles/colors.module.scss";

.objectItemContainer {
    padding: 5px 12px 2px 12px;
    border-radius: 4px;
    width: max-content;
    max-width: 500px;
    min-width: 200px;
    cursor: default;
    color: $main-text-color;
    display: flex;
    justify-content: space-between;
    padding-right: 0;

    &.simpleView {
        background-color: transparent;
        color: white;
        .objectDetailsContainer {
            display: flex;
            align-items: center;
        }
        .objectItemName {
            font-size: 14px;
            font-weight: 400;
            color: white;
        }
    }

    &.clickAction {
        border: 1px solid;
        cursor: pointer;
        margin-right: 16px;
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }

        &:hover {
            border-color: $findest-gray;
        }
    }

    &.entity:not(.simpleView) {
        background-color: $entity-color-10;
        border-color: $entity-color-10;

        .objectIcon {
            color: $entity-count-color;
        }

        .moreActionsButton {
            &.moreActionsDropdownButtonHover, &:hover {
                background-color: $entity-color-15;
            }
        }
    }

    &.study:not(.simpleView) {
        background-color: $study-color-10;
        border-color: $study-color-10;

        .objectIcon {
            color: $study-count-color;
        }

        .moreActionsButton {
            &.moreActionsDropdownButtonHover, &:hover {
                background-color: $study-color-15;
            }
        }
    }

    &.draggable {
        cursor: move !important;
        &:hover {
            border-color: $findest-blue;
        }
    }

    .objectDetailsContainer {
        max-width: calc(100% - 44px);
    }

    .moreActionsDropdownButton {
        margin-right: 5px;
        .moreActionsButton {
            background-color: transparent;
            &:hover {
                border-radius: 4px;
            }
        }
    }

    .objectItemTypeContainer {
        display: flex;

        .iconContainer {
            width: 12px;
            height: 13px;
            display: flex;
            align-items: center;
            margin-right: 6px;
            .objectIcon {
                font-size: 12px;
            }
        }
        .typeText {
            margin: 0;
            line-height: 13px;
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    .objectItemName {
        border: none;
        background: transparent;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.144px;
        margin: 0;
        text-overflow: ellipsis;
        width: 100%;
        outline: none;
        white-space: nowrap;
        overflow: hidden;

        .editable {
            cursor: pointer;
        }
    }
}

.objectItemActionsPopover {
    z-index: 9999;
    min-width: unset !important;
    padding: 0 !important;
    box-shadow: unset !important;
    overflow: visible;
    &:before {
        content: " ";
        height: 100%;
        width: 16px;
        display: block;
        position: absolute;
        left: -16px;
        overflow: visible;
    }
    .nodeControls {
        position: absolute;
        left: 0;
    }
}