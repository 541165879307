@import "Styles/colors.module.scss";
@import 'Styles/customScrollbar.module.scss';

.publicationsPerYearModalContainer {
    width: 70% !important;
    overflow-y: auto;
    @include customScrollbar;

    .publicationsPerYearContainer {
        &.isDisabled {
            opacity: 0.15;
            pointer-events: none;
        }

        .publicationsPerYearContent {
            padding: 10px;
            border: 1px solid $findest-gray;
            border-radius: 4px;
        }
    
        .saveGraphAsImageTitle {
            line-height: 16px;
            font-size: 10px;
            font-weight: 500;
            color: $main-text-color;
            text-transform: uppercase;
            margin-top: 20px;
            letter-spacing: 1.5px;
        }
    
        .captionTextBox, .suggestionsContainer {
            margin-top: 10px;
        }
    }

    .buttonsContainer {
        display: flex;
        margin-top: 10px;
        width: 145px;

        .createButton {
            > button {
                background-color: $primaryButtonColorDefault;

                &:hover {
                    background-color: $primaryButtonColorHover;
                }
            }
        }
    }
}