@import "Styles/colors.module.scss";
@import "Styles/customScrollbar.module.scss";

.tenantMenu {
  background-color: $white;
  border: 1px solid $findest-gray;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 8px;
  max-width: 400px;
  overflow-y: auto;
  max-height: 400px;
  @include customScrollbar;

  .infoText {
    padding: 8px;
    font-size: 0.825rem;
    color: $findest-text-gray;
    text-align: center;
  }

  .currentTenant {
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 8px;

    .tenantName {
      flex-grow: 1;
    }

    .currentBadge {
      background-color: $secondaryButtonColorSelected;
      color: $white;
      padding: 2px 4px;
      border-radius: 4px;
      font-size: 12px;
    }
  }

  .sectionTitle {
    font-weight: bold;
    margin-top: 16px;
    margin-bottom: 8px;
    font-size: 0.825rem;
    padding-left: 8px;
  }

  .divider {
    border-bottom: 1px solid $light-gray;
    margin: 0 8px;
  }

  .tenantItem {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    font-size: 0.825rem;
    line-height: 16px;
    height:36px;

    &:hover {
      background-color: $findest-background-03;

      .arrowIcon {
        background-color: $findest-blue;

        padding: 2px;
        border-radius: 50%;
        color: $white;
      }
    }

    .icon {
      margin-right: 8px;
    }

    .tenantName {
      flex-grow: 1;
    }

    .currentBadge {
      background-color: $secondaryButtonColorSelected;
      color: $white;
      padding: 2px 4px;
      border-radius: 4px;
      font-size: 12px;
      margin-left: 8px;
    }

    .arrowIcon {
      margin-left: 8px;
      width: 16px;
      height: 16px;
      color: $findest-dark-gray;
      padding: 2px;
    }
  }
}
