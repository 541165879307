@import 'Styles/colors.module.scss';

.overlay {
    z-index: 1000;
    position: fixed;
    inset: 0px;
    background-color: #0000006b;

    &.flex {
        display: flex;
    }
}

.modal {
    position: absolute;
    background: rgb(255, 255, 255);
    border-radius: 4px;
    outline: none;
    box-shadow: -3px 3px 16px rgba(0, 0, 0, 0.16);
    padding: 16px 28px 24px;
    z-index: 5;
    max-width: 90%;
    max-height: 90%;
    width: 535px;

    &:not(.ignoreInternalAlignment) {
        left: 50%;
        transform: translate(-50%, -20%);
        top: 20%;
    }

    &.fullscreen {
        z-index: 4;
        width: 95%;
        height: 95%;
        max-width: 95%;
        max-height: 95%;
    }

    &.center {
        top: 50%;
    }

    &.flex {
        margin: auto;
        inset: unset;
        position: unset;
    }
    .header {
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.5;
        margin-bottom: 21px;
        align-items: center;
        display: flex;
        flex-shrink: 0;
        justify-content: space-between;
        position: relative;

        .headerText {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            .headerIcon {
                padding: 6px 8px;
                color: $findest-gray;
                margin: 5px 8px 0px 0px;
                font-size: 1.5rem;
                transition: 0.3s ease-in-out;
                &.clickable {
                    cursor: pointer;
                }
                &:hover {
                    color: $main-purple;
                }
            }
        }
        
        .closeModal {
            cursor: pointer;
            width: 30px;
            height: 30px;
            font-size: 1em;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            transition: 0.3s ease-out;

            &:hover {
                background-color: $light-background-color;

                > svg {
                    color: $findest-text;
                }
            }
            
            > svg {
            
                color: $findest-dark-gray;
            } 
        }

        
    }

    .title {
        line-height: 16px;
        font-size: 10px;
        font-weight: 500;
        color: $main-text-color;
        text-transform: uppercase;
        margin-bottom: 4px;
        letter-spacing: 1.5px;
    }
    .section {
        margin-bottom: 16px;
    }

    .button {
        padding: 6px 8px;
        color: $white;
        box-shadow: none;
        height: 32px;
        font-size: 14px;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        > svg {
            margin-right: 9px;
        }
    }
    .buttonMarginTop {
        margin-top: 24px;
    }
    .footer {
        display: flex;
    }
}