@import "Styles/colors.module.scss";

.comment {
    position: relative;
    padding: 8px 16px 8px 32px;
    display: flex;
    flex-direction: column;

    &:not(.edit):hover {
        background-color: $highlightBackgroundHoverColor;
        .commentActions {
            visibility: visible;
        }
    }

    &:last-of-type {
        margin-bottom: 12px;
    }
    &:first-of-type {
        margin-top: 4px;
    }

    .commentContent {
        color: $main-text-color;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.252px;
    }

    .commentDetails {
        display: flex;
        flex-direction: row;
        align-items: center;
        .title {
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.396px;
            font-weight: 500;
        }
        .username {
            margin-left: 8px;
            max-width: 172px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .dateAdded {
            margin-left: 12px;
            color: $findest-dark-gray;
            flex-shrink: 0;
            font-weight: 400;
        }

        .commentActions {
            display: flex;
            margin-left: auto;
            visibility: hidden;

            .commentAction {
                margin-left: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
                color: $light-gray-2;
                cursor: pointer;

                > svg {
                    width: 12px;
                    height: 12px;
                }
                
                &:hover {
                    color: $main-text-color;
                    &.delete {
                        color: $main-light-red;
                    }
                }
            }
        }
    }

    .commentReplies {
        .comment {
            border-top: 1px solid $light-background-color;
            &::before {
                display: none;
            }
        }
    }

    .commentRepliesContent {
        margin-left: 10px;
    }

    .commentReplyButton {
        display: inline-block;
        border: 1px solid $findest-gray;
        border-radius: 0.25rem;
        color: #757575;
        cursor: pointer;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        padding: 0.25rem 0.5rem;
        text-transform: uppercase;
        transition: .3s ease-in-out;
        margin-top: 8px;
        > svg {
            margin-right: 4px;
        }
    }

    .smallUserIcon {
        width: 20px;
        height: 20px;
        font-size: 14px;
        margin: 0;
    }
}
