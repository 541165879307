.objectRatingPopoverContainer {
    z-index: 10000 !important;
}
.objectRatingPopover {
    .title {
        padding: 16px 32px 4px;
    }

    .objectRatingContainer {
        display: flex;
        padding: 4px 32px 4px 24px;
        align-items: center;
        .objectRatingStar {
            position: relative;
            margin-right: 16px;
            &:before {
                content: " ";
                display: block;
                position: absolute;
                width: 5px;
                height: 100%;
                left: -5px;
            }
        }
    }
    
}