@import "Styles/colors.module.scss";

.container {
  font-family: "IBM Plex Sans", sans-serif;

  .table {
    &.displayNone {
      display: none;
    }

    .existingObjectSuggestions {
      margin-bottom: 16px;
      .title {
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 700;
        line-height: 18.2px;
        text-transform: none;
        letter-spacing: 0;
      }
    }

    .footer {
      display: flex;
      column-gap: 8px;
      width: fit-content;
      margin-top: 1rem;

      button {
        box-shadow: 0 -3px 10px $general-shadow-color;
        &.submitButton {
          background-color: $findest-blue;
        }
      }
    }
  }
}
