@import "Styles/colors.module.scss";

.addableSynonym {
    padding: 0.25rem 0.75rem;
    transition: 0.3s ease-in-out;
    height: 32px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:nth-child(odd) {
        background-color: $findest-background-03;
    }

    .addSynonymButton {
        margin-right: 16px;
        height: 24px;
        font-size: 12px;
    }
}