@import 'Styles/colors.module.scss';

.collapsibleListItem {
  font-size: 0.875rem;

  .collapsibleListItemInner {
    cursor: pointer;
    display: flex;
    border-radius: 4px;
    height: 2.5rem;

    &.heading {
      &-2 {
        margin-left: 32px;
      }

      &-3 {
        margin-left: 64px;
      }
    }

    &.isActive {
      font-weight: 500;
    }

    &:hover {
      background-color: $light-gray;
      color: $findest-blue;
    }
  }

  .collapsibleListItemIconContainer {
    color: $main-text-color;
    width: 1rem;
    align-items: center;
    display: flex;
    flex-shrink: 0;
    height: 2.5rem;
    justify-content: center;

    svg {
      height: 8px;
    }
  }
}
