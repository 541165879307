@import 'Styles/colors.module.scss';

.inputsContainer {
    position: relative;

    .minInput, .maxInput {
        width: 100%;
        position: absolute;
        pointer-events: none;
        box-sizing: border-box;
        appearance: none;
        overflow: hidden;
        outline: none;
        background: transparent;

        &:active,
        &:focus {
            outline: none;
        }

        &::-webkit-slider-thumb {
            height: 16px;
            width: 16px;
            border-radius: 50%;
            background-color: $findest-purple;
            position: relative;
            margin: 5px 0;
            cursor: grab;
            appearance: none;
            pointer-events: all;
        }
    }
}
