@import "Styles/colors.module.scss";

.draggableNewObjectContainer {
    cursor: move;
    padding: 5px 12px 2px 12px;
    border-radius: 4px;
    border: 1px solid;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:last-child {
        margin-bottom: 0;
    }

    &.newEntity {
        background-color: $entity-color-10;
        border-color: $entity-color-10;

        .objectIcon {
            color: $entity-count-color;
        }
    }

    &.newStudy {
        background-color: $study-color-10;
        border-color: $study-color-10;

        .objectIcon {
            color: $study-count-color;
        }
    }

    &:hover {
        border-color: $findest-blue;
    }

    .typeContainer {
        display: flex;

        .iconContainer {
            width: 12px;
            height: 13px;
            display: flex;
            align-items: center;
            margin-right: 6px;
            .objectIcon {
                font-size: 12px;
            }
        }
        .typeText {
            margin: 0;
            line-height: 13px;
            color: $findest-dark-gray;
        }
    }

    .objectName {
        font-weight: 600;
    }

    .plusIcon {
        color: $main-text-color;
    }
}