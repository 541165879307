@import "Styles/colors.module.scss";

.filtersContainer {
    display: flex;
    align-items: center;
    height: 100%;

    .filteredItems {
        display: flex;
    }

    .filteredItem {
        display: flex;
        flex-shrink: 0;
        margin-right: 16px;
        padding: 8px 12px;
        background-color: $secondaryButtonColorSelected;
        border-radius: 4px;
        color: $white;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 1.246px;
        font-weight: 500;
        text-transform: uppercase;
        cursor: pointer;
        svg {
            margin-left: 16px;
        }
        &.defaultChip {
            cursor: initial;
        }
    }

    .addFilterButtonContainer {
        position: relative;
        .addFilterButton {
            padding: 8px 12px;
            height: 100%;
            background-color: $main-gray;
            color: $main-text-color;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 1.246px;
            font-weight: 500;
            border-radius: 4px;
            text-transform: uppercase;
            white-space: nowrap;

            &:hover {
                background-color: $secondaryButtonColorHover;

                &:before {
                    content: " ";
                    display: block;
                    position: absolute;
                    height: 4px;
                    top: -4px;
                    left: 0;
                    right: 0;
                }
                &:after {
                    content: " ";
                    display: block;
                    position: absolute;
                    height: 4px;
                    bottom: -4px;
                    left: 0;
                    right: 0;
                }
            }

            > svg {
                margin-right: 10px;
            }
        }
        
        .popover {
            .selected {
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: #D9EBFF;
            }
            li:not(.selected):hover {
                background-color: $main-gray;
            }
        }
    }
}