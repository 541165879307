@import "Styles/colors.module.scss";

.annotationActions {
    position: absolute;
    display: flex;
    right: -8px;
    top: -16px;
    box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    background-color: white;
    padding: 0 4px;
    z-index: 100;
    .annotationAction {
        height: 32px;
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $findest-dark-gray;
        cursor: pointer;
        > svg {
            height: 14px;
        }
        &:hover {
            color: $main-text-color;
            background-color: $main-gray;
        }
    }
}