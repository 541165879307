@import "Styles/customScrollbar.module.scss";

.entitiesContainer {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    @include customScrollbar;
    
    .entities {
        padding: 0 48px;
    }

    .entitiesList {
        display: flex;
        flex-direction: column;
    }
}