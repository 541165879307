@import "Styles/colors.module.scss";

.magicLinkMessage {
    background-color: $loginbox-background-color;
    padding: 30px;
    color: $login-title-color;
    box-shadow: 0 1px 4px $loginbox-box-shadow-color;
    
    .title {
        font-size: 24px;
    }
}