@import "Styles/colors.module.scss";
@import "../../loginCommonStyles.module.scss";

.forgotYourPasswordContainer {
    .forgotYourPasswordButton {
        color: $findest-blue;
        background: none;
        border: none;

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
}

.emailInput {
    margin-bottom: 20px;
}

.loginBox {
    margin-bottom: 0;
}