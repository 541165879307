@import "Styles/colors.module.scss";

.card {
  box-shadow: 0 0 6px $general-shadow-color;
  border-radius: 8px;
  overflow: hidden;
  background-color: $white;
  transition: box-shadow 0.2s ease;

  .cardContent {
    display: flex;
    align-items: center;
    padding: 24px;
    text-align: justify;
    text-justify: inter-word;
  }

  .cardIcon {
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }

  .cardText {
    flex: 1;

    .cardTitle {
      margin: 0 0 12px 0;
      font-size: 1.25rem;
      color: $main-black;
    }
  }

  .cardActions {
    display: flex;
    justify-content: flex-start;
    padding: 12px 16px;

    button {
      margin-left: 8px;
      padding: 8px 16px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.2s ease;


      &:disabled {
        background-color: $main-gray;
        cursor: not-allowed;
      }
    }
  }
}
  