@import "Styles/colors.module.scss";

.toggleButtonContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 32px;
    border-radius: 4px;
    cursor: pointer;

    .toggleButton {
        width: 50%;
        height: 32px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $findest-dark-gray;
        user-select: none;
        border: 1px solid $findest-gray;

        &:first-of-type {
            border-radius: 4px 0 0 4px;
        }

        &:last-of-type {
            border-radius: 0 4px 4px 0;
        }

        &:hover {
            p, svg {
                color: $findest-text;
            }
        }

        &.active {
            border-color: $secondaryButtonColorHover;
            background-color: $secondaryButtonColorHover;
            p, svg {
                color: $toggleButtonBlue;
            }
        }

        svg + p {
            margin-left: 4px;
        }
    }
}
