.loginBox {
    background-color: $loginbox-background-color;
    color: $login-title-color;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 26px;
    box-shadow: 0 1px 4px $loginbox-box-shadow-color;
    max-width: 400px;
    padding: 30px;

    .title {
        text-align: left;
        padding-bottom: 30px;
        font-size: 26px;
        font-weight: 500;
    }

    .footer {
        padding-top: 30px;
        display: flex;
        flex-direction: row-reverse;
    
        .button {
            font-size: 16px;
            padding: 6px 8px;
            color: $white;
            box-shadow: none;
        }
    
        .nextButton {
            background-color: $login-next-button-background-color;
    
            &:hover {
                background-color: $login-next-button-background-hover-color !important;
            }
        }
    }
}

.emailInput {
    border: none;
    border-bottom: 1px solid  $login-title-color;
    background-color: transparent;
    font-size: 1rem;
    line-height: 1.5rem;
    outline: none;
    width: 100%;

    &:hover, &:focus {
        border-bottom: 1px solid $inputFocusBorderColor;
    }
}

.mb20 {
    margin-bottom: 20px;
}