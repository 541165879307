@import "./colors.module.scss";

table {
    border-collapse: collapse;
    margin-top: 8px;

    th {
        padding: 12px 0;
        text-align: left;
        background-color: $table-header;
        position: relative;
        min-width: 142px;
        word-break: break-word;
        p {
            margin-bottom: 0 !important;
            font-weight: 600;
        }
    }
    td {
        p {
            margin-bottom: 0 !important;
        }
        // to be able to show empty cells correctly in view mode
        &:empty {
            padding: 20px;
        }
    }

    td, th {
        border: 1px solid $findest-gray;
        padding: 12px;

        &:hover {
            background-color: $findest-background-03;

        }
    }

    entityreference, studyreference {
        margin: 0 !important;
    }
    highlightreference {
        margin: 0 !important;
    }
}

// table styling specific for in edit mode
div[class*="_isInEditMode__"] { 
    table {
        &:focus {
            border-color: blue;
        }
    }
    th {
        &:hover {
            background-color: $table-header-hover;
        }
    }
    td {
        &:hover {
            background-color: $findest-background-02;
        } 
    }
}