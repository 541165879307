@import "Styles/Legacy/colors.module.scss";
@import "Styles/Legacy/userFlow.module.scss";
@import "/src/Components/Shared/DivDropdown/divDropdown.module.scss";

.filterListItem {
  margin: 1.5rem 0;

  .filterListItemTitle {
    margin-bottom: 4px;
    margin-left: 8px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
      font-size: 14px;
      color: $findest-dark-gray;
    }

    h6 {
      color: $findest-dark-gray;
    }
  }

  .scienceDocumentTypeFilterContainer {
    .scienceDocumentTypeFilter {
      display: flex;
      align-items: center;
      cursor: pointer;

      input {
        margin: 0;
      }

      label {
        cursor: pointer;
        color: $main-black;
        &:last-of-type {
          margin-left: 8px;
        }
      }
    }
  }

  .dataListItem {
    margin-bottom: 8px;
    margin-left: 8px;

    .overrideDivDropdown {
      margin: 0;
      font-size: 14px;
      @extend .divDropdown;

      .divDropdownSelect {
        width: 100%;
      }

      .divDropdownList {
        width: auto;
      }
    }

    &.addDataListItem {
      input {
        font-family: "IBM Plex Sans", sans-serif;
        font-size: 14px;
        margin-bottom: 0;
      }
    }

    .rangeContainer {
      input {
        font-family: "IBM Plex Sans", sans-serif;
        font-size: 14px;
        width: 100%;
      }
    }
  }

  .addedDataListItemContainer {
    padding: 0 16px;
  }

  &.filterListItemPublicationYears {
    margin: 0;

    .publicationYearsTitle {
      font-weight: 600;
      line-height: 14px;
      letter-spacing: 0;
      margin: 14px 0;
    }
  }

  &.filterListItemHasCitationScore {
    margin: 0;
    .hasCitationScore {
      display: flex;
      align-items: center;
      font-size: 14px;
    }
  }
}
