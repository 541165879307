@use "Styles/colors.module.scss" as colors;

.technologySearchFields {
  .description {
    font-size: 0.875rem;
    color: colors.$findest-dark-gray;
    max-width: 330px;
    line-height: 18.2px;
    font-weight: 450;
  
    &.queryDescription {
      margin: 16px 0;
    }
  
    &.criteriaDescription {
      margin: 5px 0;
    }
  }
  
  .criteriaMatchingHeading {
    margin-top: 27px;
  }
  
  .textArea {
    max-width: 500px;
    min-height: 104px;
  } 

  .warningContainer {
    margin-top: 27px;
  }
}
