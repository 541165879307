@import 'Styles/Legacy/colors.module.scss';
@import 'Styles/Legacy/scrollbarCustom.module.scss';

.searchableDropdownContainer {
    .searchableDropdown {
        width: 200px;
        max-height: 400px;
        overflow-y: auto;
        overflow-x: hidden;
        position: fixed;
        background-color: white;
        margin-top: 0.25rem;
        border-radius: 4px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.26);
        padding: 4px 0;

        .dropdownSearchbar {
            display: flex;
            align-items: center;
            padding: 0 0.5rem;
            border-bottom: 1px solid $light-background-color;

            svg {
                font-size: 14px;
                color: $findest-dark-gray;
            }

            input {
                border: none;
                padding: 3px 0.25rem;
                font-family: 'IBM Plex Sans', sans-serif;
                font-size: 14px;
            }
        }

        .searchableDropdownOptions {
            .dropdownItem {
                padding: 0 0.5rem;
                font-size: 14px;
                cursor: pointer;
                transition: 0.3s ease-in-out;
                display: flex;
                height: 1.5rem;
                align-items: center;

                &:hover {
                    background-color: $light-background-color;
                }

                &.empty {
                    cursor: default;
                    pointer-events: none;
                }
            }
        }
    }

    .dropdownSelect {
        background-color: white;
        border-radius: 4px;
        padding: 3px 8px;
        border: 1px solid $light-background-color;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: 0.3s ease-in-out;
    
        :first-child {
            flex: 1;
        }

        span {
            margin-right: 0.25rem;
            font-size: 14px;
        }
    
        svg {
            font-size: 10px;
            color: $findest-dark-gray;
        }

        &:hover {
            border-color: $findest-dark-gray;
        }
    }
}