@import "Styles/colors.module.scss";

.objectPinElement {
  .pinnedPage {
    margin: 0 8px;
    padding: 8px;
    height: 40px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-bottom: 1px solid $light-gray;
    user-select: none;
  
    p,
    svg {
      font-size: 14px;
      line-height: normal;
    }
  
    button {
      margin-left: auto;
      text-transform: capitalize;
      padding: 4px 8px;
    }
  
    &.notPinned {
      p,
      svg {
        color: $findest-blue;
        cursor: pointer;
      }
    }
  
    &.unpinnedPage {
      border: none;
      padding-right: 0;
    }
  }
  
  .pinButton {
    justify-content: center;
    background-color: $secondaryButtonColorDefault;
    color: $main-text-color;
  
    svg[data-icon="thumbtack"] {
      transform: rotate(30deg);
    }
  
    &:hover {
      color: $findest-primary-purple;
      background-color: $light-pink;
    }
    &.pinned {
      color: $findest-primary-purple;
      background-color: $light-pink;
      &:hover {
        color: white;
        background-color: $findest-primary-purple;
      }
    }
  }
  
  .listElement {
    height: 2rem;
    width: 2rem;
    margin-right: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: transparent;
    cursor: pointer;

    svg {
      height: 12px;
      width: 12px;
    }

    &:hover {
      background-color: $notification-red-25;
      svg[data-icon="thumbtack"] {
        display: none;
      }
  
      svg[data-icon="xmark"] {
        display: block;
      }
    }
  
    svg[data-icon="thumbtack"] {
      transform: rotate(30deg);
    }
  
    svg[data-icon="xmark"] {
      display: none;
    }
  }
}