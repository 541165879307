@import 'Styles/colors.module.scss';
@import 'Styles/customScrollbar.module.scss';

.recentCompanyActivity {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    @include customScrollbar;
    padding-right: 16px;

    .recentActivityItem {
        display: flex;
        align-items: center;
        height: 40px;
        cursor: default;
        &:not(:last-child) {
            margin-bottom: 8px;
        }
        .activityNameContainer {
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
        }
        .activityName {
            font-weight: 500;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
        .activityDate {
            margin-left: auto;
            padding-left: 8px;
            white-space: nowrap;
        }
    }
}

.emptyContentContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}