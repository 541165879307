@import 'Styles/colors.module.scss';

.tableOfContentsItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.hasNoChildren {
    padding-left: 16px;
  }

  .leftContent {
    display: flex;
    align-items: center;
  }

  .rightIcon {
    font-size: 12px;
    font-weight: 500;
    padding: 13px;
  }
}
