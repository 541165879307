@import 'Styles/colors.module.scss';

.ratingStar {
    display: flex;
    padding: 4px 8px;
    border-radius: 4px;

    .ratingText {
        font-size: 18px;
        font-weight: 500;
        align-self: center;
        color: $main-text-color;
        margin-left: 4px;
    }
    &.clickable {
        cursor: pointer;
    }
    &:hover {
        background-color: $findest-background-03;
    }

    .ratingStarContainer {
        display: flex;
        position: relative;
        color: $light-gray-2;
        &.small {
            font-size: 20px;
        }
    
        &.medium {
            font-size: 24px;
        }

        &.large {
            font-size: 32px;
        }
    
        &.xlarge {
            font-size: 40px;
        }
    
        .ratingStarFilled {
            color: $findest-yellow;
            position: absolute;
        }
    }
    
    .ratingNeeded::after {
        font-family: "Font Awesome 6 Pro", sans-serif;
        content: "\f111";
        color: $notification-red;
        font-weight: 900;
        font-size: 12px;
        position: absolute;
        right: -0.2px;
        top: 4px;
    }
}