@use "Styles/Mixins/windowActionButton.module.scss" as mixins;

.findestWindowModalContainer {
    inset: 32px 89px !important;
    transform: unset !important;
    width: unset !important;
    padding: 0;

    .modalContent {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
}
.findestWindowModalOverlay {
    z-index: 1000;
    width: 100%;
    height: 100%;
    &.hidden {
        display: none;
    }
}
.windowActionButtons {
    position: absolute;
    display: flex;
    right: 64px;
    top: 24px;
    z-index: 1;

    &.graphWindow {
        right: 32px;
        top: 16px;
    }

    .windowActionButton {
        @include mixins.windowActionButton;
    }
}