@import "Styles/colors.module.scss";

.searchMetadataBubble {
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $labelBorderColor;
    background-color: $findest-background-01;
    border-radius: 14px;
    margin: 0 8px 4px 0;
    padding: 0 16px;
    user-select: none;
    max-width: 100%;

    .overflowing {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &.isSmall {
        display: inline-flex;
        height: 1.563rem;
        margin: 0;
    }
}
