.recoveryCodesView {
  padding: 0 0 32px 32px;
  height: 100%;
  width: 100%;

  .recoveryCodesSection {
    margin-top: 8px;
    .recoveryCodesHeader {
      margin-bottom: 4px;
    }
  }

  .acknowledgedSection {
    margin-top: 16px;
  }
}
