@import 'Styles/colors.module.scss';

.selectableTextList {
    display: flex;
    flex-direction: column;
    width: 192px;
    margin-right: 32px;
    &Item {
        height: 48px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        padding: 0 16px;
        color: $main-black;
        letter-spacing: 0.496px;
        cursor: pointer;
        &.selected {
            background-color: $light-blue;
            font-weight: 500;
            cursor: initial;
        }
    }
}