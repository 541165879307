@import "Styles/colors.module.scss";
@import "Styles/customScrollbar.module.scss";

.container {
  font-family: "IBM Plex Sans", sans-serif;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 418px;
  @include customScrollbar;

  .section {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 8px;

    .informationHeaderContainer {
      h6 {
        font-size: 14px;
        font-weight: 700;
        line-height: 18.2px;
        text-transform: none;
        letter-spacing: 0;
        height: fit-content;
      }

      p {
        font-size: 12px;
        letter-spacing: 0;
        width: 360px;
        margin-left: 12px;
      }
    }

    .informationInput {
      height: 32px;
      border: 1px solid $findest-gray;
      border-radius: 4px;
      background-color: $white;
      font-size: 14px;
      padding: 6px 8px;
      resize: none;
      @include customScrollbar;

      &:disabled {
        opacity: 0.5;
      }

      &:focus {
        outline: none;
      }

      &.error {
        border-color: $red-400;
      }
    }

    .deleteInformation {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      svg {
        font-size: 14px;
        color: $findest-gray;
      }

      &:hover {
        svg {
          color: $findest-red;
        }
      }
    }

    .addInformation {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background-color: $blue-100;
      border-radius: 4px;

      svg {
        font-size: 14px;
        color: $findest-blue;
      }
    }

    .informationButtonDisabled {
      pointer-events: none;
      opacity: 0.5;
    }

    .informationHeader {
      width: 128px;
    }

    .informationData {
      width: 316px;
      height: 80px;
      margin: 0 12px;
    }
  }

  .errorContainer {
    color: $red-400;
    display: flex;
    align-items: center;
    gap: 4px;

    svg {
      width: 13px;
    }

    p {
      letter-spacing: 0;
      font-size: 12px;
    }

    &.right {
      margin-left: 150px;
    }
  }
}
