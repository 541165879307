@import 'Styles/colors.module.scss';
@import 'Styles/customScrollbar.module.scss';

.tableOfContents {
  height: 100%;

  .tableOfContentsHeader {
    display: flex;
    height: 48px;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px 0 32px;
    border-bottom: 1px solid $findest-gray;
    white-space: nowrap;
    margin-bottom: 2px;
  }

  h4 {
    font-style: italic;
    padding: 1rem 2rem;
  }
  .contentContainer {
    display: flex;
    flex-direction: column;
    height: calc(100% - 52px);
  }

  .collapsibleListContainer {
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
    @include customScrollbar;
    padding: 0 16px;
  }
  .staticTOCItems {
    flex-shrink: 0;
    margin-top: auto;
    padding: 16px;
    &:before {
      // border top to separate from the rest of the content
      content: '';
      display: block;
      height: 0.5px;
      background-color: $findest-gray;
      margin-bottom: 16px;
    }
  }
}

.ratingProgressIcon {
  color: $red-400;
}

.unConfirmedIcon {
  color: $primaryButtonColorDefault;
}

.confirmedIcon{
  color: $main-light-green;
}
