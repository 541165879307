@import 'Styles/colors.module.scss';

.topbarContainer {
    border-bottom: 1px solid $findest-gray;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 4rem;
    min-height: 4rem;
    padding: 0 1rem;
    position: relative;
    background-color: white;

    .editorContainer {
        display: flex;
        .standaloneButtons {
            display: flex;
            align-items: center;
            margin-left: 14px;
        }
    }

    .actionsButton {
        margin: 0 0 0 0.8rem;

        > button {
            padding: 0 !important;
            background-color: white;

            svg {
                color: $moreIconColor;
                height: 17px;
            }
    
            &:hover {
                background-color: $light-gray;
            }
        }
    }
    .createdByAccountContainer {
        margin: 0;
    }

    .navigationButtonsContainer {
        display: flex;
        
        .navigationButton {
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            background-color: $light-gray;
            cursor: pointer;
            margin-right: 4px;

            svg[data-icon="thumbtack"] {
                transform: rotate(30deg);
            }

            &:hover {
                background-color: $findest-gray;
            }

            &.active {
                color: $toggleButtonBlue;
                background-color: $toggleButtonBlueShading;
            }
        }
    }
}

.navigationPopoverContainer {
    width: 500px;

    .pinnedSection {

        .borderBottom {
            border-bottom: 1px solid $light-gray;
        }

        .sectionBorder {
            padding-bottom: 8px;
            border-bottom: 1px solid $light-gray;
        }
    
        .pinnedSectionGroupName {
            font-weight: 700;
            padding: 4px 8px;
        }

        .pinnedSectionItem {
            display: inline-flex;
            align-items: center;
            width: 100%;
            
            &:hover {
                background-color: $light-gray;
            }

            
            a {
                width: calc(100% - 2.5rem);
                li {
                    padding-right: 0;
                }
            }
        }

        hr {
            display: block;
            height: 1px;
            width: calc(100% - 1rem);
            padding: 0 .5rem;
            margin: 0 auto;
            border: 0;
            border-top: 1px solid $light-gray;
        }
    }
}

.emptyNavigationPopoverContainer {
    width: 500px;

    &:not(.borderTop) {
        padding: 8px 16px;
    }
   

    &.borderTop {
        width: calc(100% - 16px);
        border-top: 1px solid $light-gray;
        padding: 8px;
        margin: 0 auto;
    }

    .emptyText {
        font-size: 14px;
        font-style: italic;
    }
}


.optionText {
    text-transform: uppercase;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 500;
    letter-spacing: 1.246px;
}