@use "Styles/colors.module.scss" as *;

.percentage {
  position: relative;
  display: inline-flex;

  svg {
    position: relative;
    width: 80px;
    height: 80px;
    transform: rotate(-90deg);

    circle {
      width: 100%;
      height: 100%;
      fill: none;
      stroke: $file-upload-track-color;
      stroke-width: 6;
      stroke-linecap: round;
      transition: .3s ease-in-out;

      &:last-of-type {
        stroke-dasharray: 188.5px;
        stroke-dashoffset: calc(188.5px - (188.5px * var(--percent, 0)) / 100);
        stroke: $file-upload-progress-color;
      }
    }
  }

  .number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $file-upload-progress-color;
    font-size: 0.875rem;
  }

  &.green {
    circle {
      stroke: white;
      stroke-width: 8;
      &:last-of-type { 
        stroke: $green-400;
      }
    }
    .number {
      color: black;
      font-weight: 600;
    }
  } 
}
