@import "Styles/colors.module.scss";
@import 'Styles/customScrollbar.module.scss';

.linksWindowContainer {
    display: flex;
    flex-direction: column;
    height: 100%;

    .linksWindowTopbar {
        display: flex;
        width: 100%;
        align-items: flex-end;
        justify-content: center;
        min-height: 64px;
        max-height: 64px;
        border-bottom: 1px solid $findest-gray;
        position: relative;

        .linksWindowSearchbar {
            position: absolute;
            left: 32px;
            top: 16px;
            max-width: 352px;
            z-index: 5;

            .objectSearchMainElement {
                + .searchPopupContentResults {
                    display: flex;
                    flex-direction: column;
                    background-color: white;
                    overflow-y: auto;
                    max-height: 70vh;
                    width: 150%;
                    border-radius: 4px;
                    margin-top: 4px;
                    box-shadow: -3px 3px 16px rgba(0,0,0,0.16);
                    @include customScrollbar;

                    h6 {
                        padding: 4px 0 4px 16px;
                        color: #757575;
                        user-select: none;
                    }
                }
            }

            .objectSearchInputElement {
                border-radius: 4px;
                width: 352px;
                max-width: 352px;
            }

            .searchPopupContentResults {
                display: none;
            }
        }

        .linksWindowTabsContainer {
            margin-bottom: -2px;
            &:after {
                border: none;
            }

            .linksWindowTab {
                padding: 0 16px 6px 16px;
            }
        }
    }

    .linksWindowContentContainer {
        display: flex;
        height: 100%;
        overflow: hidden;

        .linksWindowContentMenuTop {
            display: flex;
            flex-direction: column;
            padding: 0 32px 16px;
        }

        .currentPageContent {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            background-color: $light-gray;
            border-radius: 4px;                
            margin: 1rem 2rem;
            padding: 0 8px;

            &.fullWidth {
                margin: 1rem 0;
            }
            .currentPageIcon {
                width: 1rem;
                height: 1rem;
                background: $findest-purple;
                color: $white;
                border-radius: 50%;
                svg[data-icon="location-dot"] {
                    width: 7px;
                    height: 9px;
                    padding-left: 4.5px;
                    padding-bottom: 4px;
                    cursor: auto;
                }
            }

            .currentPageButton {
                background-color: $light-gray;
                color: $main-text-color;
                gap: 8px;
                width: 114px;
                padding: 0;
                letter-spacing: 0;
                text-transform: none;
            }
        }

        .linksWindowContentMenu {
            width: 224px;
            min-width: 224px;
            overflow: hidden;
            border-right: 1px solid $findest-gray;
            display: flex;
            flex-direction: column;

            &.absolute {
                border-right: none;
                position: absolute;
                z-index: 1;
            }

            .linksWindowTitle {
                margin: 16px 0 12px 0;
                color: $main-text-color;

                &.smallMarginBottom {
                    margin-bottom: 4px;
                }

                svg {
                    margin-right: 8px;
                }
            }

            .changeFocusContainer {
                color: $findest-dark-gray;
                margin-bottom: 16px;

                .focusButton {
                    padding: 0 10px;

                    svg {
                        margin-right: 6px;
                    }
                }
            }

            .removeLinksButton {
                background-color: $main-light-red;
                justify-content: center;
                width: 100%;
                text-align: center;
            }
            .recentActivityContainer {
                margin-top: 16px;
                flex: 1;
                overflow: hidden;
                padding: 0 0 16px 32px;

                .recentActiveObjects {
                    overflow-x: hidden;
                    overflow-y: auto;
                    @include customScrollbar;
                    height: calc(100% - 48px);
                    padding-right: 32px;

                    .recentActivityObjectItem {
                        width: 100%;
                        max-width: unset;
                        min-width: unset;
                        position: relative;
                        margin-right: 0;
                        cursor: default;
                        &:hover {
                            border-color: $findest-blue;
                        }
                    }
                }
            }
        }

        .linksWindowContent {
            height: 100%;
            width: calc(100% - 224px);
            &.fullWidth {
                width: 100%;
            }

            &.paddingLeft {
                padding-left: 224px;
            }
        }
    }
}

.linksWindowTextFilterContainer {
    margin-top: 12px;
}

.linksWindowOptionFilterContainer {
    margin-top: 16px;
}

.filtersContainer {
    flex-direction: column-reverse;
    align-items: flex-start;
}

.filteredItems {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.filteredItem {
    margin: 4px 0 0 0;
}