@import 'Styles/colors.module.scss';

.baseButton {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    cursor: pointer;
    line-height: 16px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1.246px;
    height: 32px;
    padding: 0 16px;
    outline: none;
    border: none;
    border-radius: 4px;
    transition: 0.3s ease-in-out;
    background-color: $primaryButtonColorDefault;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: $primaryButtonColorHover;
    }
}

.secondaryButton {
    @extend .baseButton;

    background-color: $main-gray;
    color: $findest-text;

    &:hover {
        background-color: $secondaryButtonColorHover;
    }
}

.whiteButton {
    @extend .baseButton;
    
    color: $findest-text;
    background-color: white;
    border: 1px solid $main-gray;

    &:hover {
        background-color: white;
        border-color: $findest-gray;
    }
}

.marginRight {
    margin-right: 1rem;
}