@import 'Styles/colors.module.scss';
@import 'Styles/customScrollbar.module.scss';

.searchBarContainer {
    max-width: 80%;
    width: 560px;
    position: relative;
    margin: 0 auto;
    transition: 0.3s ease-in-out;

    .globalSearchBarInput {
        background-color: white;
    }

    .searchBarPopup {
        position: absolute;
        z-index: 2;
        width: 100%;
        margin: 0;
        padding: 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        h6 {
            color: $findest-dark-gray;
            margin: 4px 0 4px 32px;
            user-select: none;
        }

        .recentActiveContainer {
            padding-bottom: 1.5rem;
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            @include customScrollbar;

            .recentActiveList {
                margin: 8px 0;

                &:last-of-type {
                    margin-bottom: 0;
                }
                
                .listItemContent {
                    padding: 0 1rem;
                }
            }
        }

        .searchResultsContainer {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            @include customScrollbar;

            .searchResultsList {
                margin: 8px 0;

                &:last-of-type {
                    margin-bottom: 1.5rem;
                }

                .listItemContent {
                    padding: 0 1rem;
                }
            }
        }

        .noResults {
            margin: 1rem 0 1.5rem 0;
            color: $findest-dark-gray;
            font-style: italic;
            padding: 0 2rem;
        }
    }
}