@import 'Styles/colors.module.scss';

.tabSettings {
    padding: 32px 0 0 64px;
    display: flex;
    width: 100%;
    height: calc(100% - 40px);
    .selectableTextListContainer {
        border-right: 1px solid $light-gray;
    }
    .tabContent {
        width: 100%;
        overflow: hidden;
    }
}