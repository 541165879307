@import 'Styles/colors.module.scss';
@import 'Styles/customScrollbar.module.scss';

.twoFactorAuthenticationSettingsTab {
  overflow-y: auto;
  overflow-x: hidden;
  @include customScrollbar;
  height: 100%;
  width: 100%;
  padding: 0 0 32px 32px;

  .twoFactorAuthenticationSettings {
    max-width: 641px;

    &.configurationScreen {
      max-width: 701px;
    }

    .header {
      padding: 16px 0;
    }

    .description {
      padding-bottom: 16px;
      color: $main-black;
    }

    .informationContent, .moreInformationSection {
      padding-bottom: 16px;
    }

    .moreInformation {
      padding-bottom: 16px;
      color: $findest-blue;

      .icon {
        margin-left: 8px;
        font-size: 0.75rem;
      }
    }

    .moreActions {
      margin-top: 16px;
      border-top: 1px solid $light-gray;
      padding: 16px 0;

      .actionButton {
        margin-bottom: 16px;
      }

      .disable2FAButton {
        background-color: $red-100;
        color: $red-500;

        &:hover {
          background-color: $red-500;
          color: $white;
        }
      }
    }
  }
}
