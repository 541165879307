@import 'Styles/colors.module.scss';
@import 'Styles/customScrollbar.module.scss';

.dataHeader {
    display: flex;
    padding: 14px 0;
    .filtersIcon {
        margin-left: 16px;
        color: $findest-dark-gray;
    }
    .dataSelectHeaderItem {
        margin-right: 48px;
    }
    .dataEmailHeaderItem {
        min-width: 320px;
        max-width: 320px;
        margin-right: 50px;
    }
    h4 {
        font-size: 1rem;
        font-weight: 500;
        display: inline-block;
    }
}
.sharedUsers {
    height: calc(100% - 48px);
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    @include customScrollbar;
    padding-right: 64px;
}
.sharedUser {
    display: flex;
    padding: 12px 0;
}

.userDetails {
    display: flex;
    align-items: center;
    min-width: 320px;
    max-width: 320px;
    margin-right: 50px;
    .userIcon {
        margin-left: 0;
    }
    .username {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.sharedObjectsContainer {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    .listItemsContainer {
        padding-left: 64px;
        height: 100%;
    }
}