@import "Styles/colors.module.scss";

.savedDocumentListContainer {
    padding: 0 48px;
}

.savedDocumentHeaderContainer {
    display: flex;
    align-items: flex-end;

    h1 {
        padding-right: 5px !important;
    }
}
