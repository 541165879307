@import "Styles/colors.module.scss";

.existingConnectionItem {
    border-radius: 16px;
    display: inline-flex;
    align-items: center;
    padding: 0 0 0 8px;
    user-select: none;
    color: $main-text-color;

    &:hover {
        .moreActionsDropdownButton {
            visibility: visible;
        }
    }

    &.hasPaddingRight {
        padding-right: 16px;
    }

    &.clickable {
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }

    .iconContainer {
        min-height: 32px;
        min-width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 16px;

        &.deleteIcon {
            color: $findest-dark-gray;
            visibility: hidden;
            height: 28px;
            width: 28px;
            margin: 0px 2px;
            cursor: pointer;
        }

        &.active {
            visibility: visible;
        }
    }

    &.entity {
        background-color: $entity-color-10;

        .objectIcon {
            svg {
                color: $entity-count-color;
            }
        } 

        .iconContainer {
            &.active {
                &:hover {
                    background-color: $entity-color-10;
                    color: $findest-text;
                }
            }
        }
    }

    &.study {
        background-color: $study-color-10;

        .objectIcon {
            svg {
                color: $study-count-color;
            }
        }  

        .iconContainer {
            &.active {
                &:hover {
                    background-color: $study-color-10;
                    color: $findest-text;
                }
            }
        }
    }
    .moreActionsDropdownButton {
        visibility: hidden;
        button {
            background-color: transparent !important;
            svg {
                &:hover {
                    color: $main-black;
                }
            }
        }
    }
}