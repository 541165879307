@import 'Styles/colors.module.scss';

.addImageModal {
    overflow-x: hidden;
    overflow-y: auto;
    .aspectRatioBox {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        margin: 2rem 0;
    
        .aspectRatioBoxContent {
            position: absolute;
            top: 0;
            left: 0%;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $main-gray;
            color: $findest-dark-gray;
    
            .image {
                object-fit: cover;
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
    
    .captionInput {
        border-color: $main-gray;
    
        &:hover, &:focus {
            border-color: $findest-gray;
        }
    }
    
    .addButton {
        margin-right: 16px;
    }
}