@import "Styles/colors.module.scss";

.documentSearchResultContainer {
    display: flex;
    padding: 16px 0;
    align-items: flex-start;

    .saveContainer {
        margin-right: 32px;
        position: relative;

        &:hover {
            .refContainer {
                &:after {
                    content: " ";
                    display: block;
                    position: absolute;
                    height: 64px;
                    width: 40px;
                    top: -16px;
                    right: -24px;
                }
            }
        }

        .objectSearchPopupContainer {
            width: 512px;
            padding: 0 0 4px 0;
            overflow: hidden;
            display: flex;
        }
    }

    .checkbox {
        margin-right: 10px;
    }

    .documentSearchResultContentContainer {
        width: calc(100% - 103px);
        
        button {
            h3 {
                margin-bottom: 4px;
                margin-top: 4px;
                cursor: pointer;
                display: flex;
                gap: 10px;
                color: #000;

                span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $light-gray;
                    font-size: 14px;
                    border-radius: 50%;
                    height: 24px !important;
                    min-width: 24px;

                    &.answerReference {
                        background-color: $white;
                    }
                }
    
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .isAlreadyRead {
            color: $findest-primary-purple;
        }

        .documentSearchResultsMetadata {
            display: flex;
            flex-flow: row wrap;
        }

        .connectedObjectsContainer {
            margin: 8px 0 0 0;
        }
    }
}

