@import 'Styles/colors.module.scss';
@import 'Styles/customScrollbar.module.scss';

.recentActivity {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  padding-right: 16px;
  @include customScrollbar;

  .objectItem {
    width: 100%;
    max-width: unset;
  }
}

.emptyContentContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
