@import "Styles/colors.module.scss";
@import 'Styles/customScrollbar.module.scss';

.objectSearchPopupContentContainer {
    width: 100%;
    max-height: 566px;
    display: flex;
    flex-direction: column;
    
    .objectSearchPopupContentSearchbar {
        border: none;
        border-bottom: 1px solid $main-gray;
        border-radius: 4px 4px 0 0;

        &::placeholder {
            font-style: italic;
        }

        &:hover, &:focus {
            border-color: $main-gray;
        }
    }
    
    .objectSearchPopupContentResults {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        @include customScrollbar;

        h6 {
            padding: 4px 0 4px 16px;
            color: $findest-dark-gray;
            user-select: none;
        }
    }

    .objectSearchPopupContentNoResults {
        letter-spacing: 0.144px;
        height: 40px;
        display: flex;
        align-items: center;
        padding-left: 16px;
    }

    .createNewButton {
        margin: 8px auto 4px 16px;
        line-height: 2;
    }
}

