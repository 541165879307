@import 'Styles/colors.module.scss';

.slashMenuContentContainer {
  &.hasContent {
    min-width: 200px;
  }

  .slashMenuAIToolsCommand {
    margin: 4px;
    padding: 4px;
    width: calc(100% - 8px);
  }

  .slashMenuSpacer {
    height: 1px;
    background-color: $light-gray;
    margin: 0 8px 8px;
  }

  .editorMenuGroupsContainer {
    padding-bottom: 4px;
  }
  .hasNoUpperContent {
    padding-top: 4px;
  }
}
