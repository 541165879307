@import 'Styles/customScrollbar.module.scss';

.entityLikeContainer {
    @include customScrollbar;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    .entityLikeItems {
        padding: 0 48px;
        &.archiveList {
            padding: 0 88px 0 48px;
        }
    }
    .entityLikeItemsList {
        display: flex;
        flex-direction: column;
    }
}