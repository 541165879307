@import "Styles/colors.module.scss";

.container {
  display: flex;
  align-items: center;
  margin-top: 8px;
  border-radius: 8px;
  padding: 8px 12px;
  background-color: $grey-300;
  cursor: pointer;

  .item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    h4 {
      margin: 0 0 4px;
      font-weight: 600;
    }

    p {
      letter-spacing: 0;
      font-size: 12px;
      line-height: 16px;
    }
  }

  &.selected {
    background-color: $main-black;
    color: $white;
  }

  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
    pointer-events: none;
  }

  &:hover:not(.selected):not(.disabled) {
    background-color: $secondaryButtonColorHover;
  }
}
