@import "Styles/colors.module.scss";
@import 'Styles/customScrollbar.module.scss';

.documentModal {
    width: 80%;
    max-width: 1200px;
    padding: 0;
    display: flex;
    transform: translate(-50%, -50%);
    top: 50%;

    .documentModalHeader {
        position: absolute;
        right: 16px;
        top: 16px;
        z-index: 1;
    }
}
