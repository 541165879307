@import 'Styles/colors.module.scss';

.wysiwygContent {
    word-break: break-word;
    display: block;

    div {
        outline: none;
    }

    p[data-placeholder] {
        position: relative;
        width: 100%;

        &:before {
            content: attr(data-placeholder);
            position: absolute;
            color: $findest-dark-gray;
            cursor: text;
            font-style: italic;
            font-size: 0.875rem;
            letter-spacing: 0.252px;
            line-height: 1.25rem;
            bottom: 0;
        }
    }
}

.wisiwygBottomContainer {
    display: flex;
    justify-content: center;
    position: sticky;
    bottom: 0;
    padding: 0.5rem 0;
    z-index: 2;
}