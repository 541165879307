@import 'Styles/colors.module.scss';

.stepsContainer {
    position: relative;

    .step {
        position: absolute;
    }

    .firstStep {
        width: 25%;
        height: 1.625rem;
        background-color: $maturityRadarStep-1;
        border-top-left-radius: .5rem;
        border-bottom-left-radius: .5rem;
        border-right: 1px solid white;
    }

    .secondStep {
        width: 50%;
        height: 1.625rem;
        background-color: $maturityRadarStep-2;
        border-top-left-radius: .5rem;
        border-bottom-left-radius: .5rem;
        border-right: 1px solid white;
    }

    .thirdStep {
        width: 75%;
        height: 1.625rem;
        background-color: $maturityRadarStep-3;
        border-top-left-radius: .5rem;
        border-bottom-left-radius: .5rem;
        border-right: 1px solid white;
    }

    .fourthStep {
        width: 100%;
        height: 1.625rem;
        background-color: $maturityRadarStep-4;
        border-radius: .5rem;
    }

    .actualStep {
        background-color: $findest-purple;
        position: absolute;
        height: 1rem;
        top: 5px;
        border-radius: 16px;
    }
}

.stepsTextsContainer {
    padding-top: 30px;
    font-size: 10px;
    width: 100%;
    display: flex;
    text-align: center;
    color: $main-text-color;
    div {
        width: 25%;
    }
}