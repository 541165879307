@import "Styles/colors.module.scss";

.userIconContainer {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 8px;
    color: $white;
    flex-shrink: 0;
    font-weight: 500;
    cursor: default;

    &.large {
        width: 32px;
        height: 32px;
        font-size: 20px;
        margin: 0;
    }

    &.medium {
        width: 24px;
        height: 24px;
        font-size: 14px;
    }
    &.small {
        width: 20px;
        height: 20px;
        font-size: 14px;
        margin: 0;
    }
}