@import 'Styles/Legacy/colors.module.scss';
@import 'Styles/colors.module.scss';

// Start Modal popup code
.userFlowMainContainer {
    display: flex;
    height: 100%;
}

.closeModalButton {
    position: absolute;
    width: 2rem;
    height: 2rem;
    margin: 0.75rem;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 20px;
    color: $findest-dark-gray;
    cursor: pointer;
    transition: 0.3s ease-in-out;

    &:hover {
        background-color: $light-background-color;
        color: $findest-text;
    }
}

.userFlowSidebar {
    display: block;
    max-height: 100%;
    min-width: 20%;
    max-width: 20%;
    overflow-y: auto;
    border-right: 1px solid #f2f2f2;
    padding: 0 1rem 1rem 2rem;

    .stickyTopContainer {
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        background-color: white;
        z-index: 1;
        padding-bottom: 0.5rem;
        padding-top: 1rem;
    }
}

// Stop Modal popup code

.userFlowQueryContainer {
    padding: 1rem 0rem;
    border-bottom: 1px solid $findest-background-03;
    position: absolute;
    width: 100%;
    max-height: 75%;
    background: white;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    &.uncollapsed {
        box-shadow: 0 12px 12px -10px rgba(0, 0, 0, 0.16);
        z-index: 1;
    }
}

.querySelectionContainer {
    display: flex;
    margin: 0 2rem 1rem 2rem;
    align-items: flex-start;
}

.selectedQueryContainer {
    display: flex;
    overflow-y: auto;
    margin: 1.5rem 0 1rem 0;
    padding: 0 2rem;

    &.collapsed {
        height: 0px;
        margin-top: 0.75rem;
        margin-bottom: 0rem;
        overflow: hidden;
    }
}

.selectedQuerySearchFieldsContainer {
    flex: 3 1;
    margin-left: 2rem;
}

.toggleVisibleQuery {
    display: flex;
    align-items: center;
    justify-content: center;

    div {
        color: $findest-purple;
        font-size: 12px;
        line-height: 15px;
        text-transform: uppercase;
        font-weight: 500;
        cursor: pointer;
        margin: 0rem 0 -0.5rem 0;

        svg {
            color: $findest-text;
            margin-left: 0.25rem;
        }

        &:hover {
            text-decoration: underline;
        }
    }
}

.userFlowContentContainer {
    max-height: 100%;
    width: 100%;
    margin-top: 124px;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .headerContainer {
        min-height: 3rem;
        display: flex;
        align-items: center;
        padding: 0 2rem;
        justify-content: space-between;

        .numberOfResults {
            font-size: 14px;
            color: $findest-dark-gray;
        }
    }

    .contentContainer {
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0 2rem;
    }
}

.userFlowDataList {
    width: 100%;

    .dataListTitle {
        display: flex;
        align-items: center;
        height: 2rem;
        cursor: default;
        font-weight: 500;
        line-height: 16px;

        &.sourceType {
            align-items: flex-end;
            height: 28px;
            margin-bottom: 4px;
            margin-left: 8px;
        }

        svg {
            width: 1rem;
            margin-right: 0.5rem;
        }
    }

    .dataListInputContainer {
        display: flex;
        align-items: center;
        padding: 0 0.75rem;
        height: 2.5rem;

        input {
            padding: 0.25rem 0;
        }
    }

    .dataListItem {
        display: flex;
        align-items: center;
        height: 2rem;

        label {
            cursor: pointer;
            font-size: 14px;
            display: flex;
            align-items: center;
        }

        input {
            margin-right: 0.5rem;
            cursor: text;
            margin-bottom: 3px;
        }

        .rangeContainer {
            height: 2rem;
            display: flex;
            align-items: center;
            cursor: default;

            input {
                font-size: 14px;
                width: 70px;
                margin: 0;
                text-align: center;
                cursor: text;
            }

            span {
                margin: 0 0.5rem;
            }
        }
    }

    .findestTextBox {
        width: 100%;
    }

    input {
        background-color: $white !important;
    }
}

.loadingIndicatorContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.duplicateQueryButton {
    margin-left: 0.5rem;
    color: $findest-dark-gray;
    background-color: white;
    border: 1px solid $findest-gray;
    padding: 0.25rem 1rem;
    border-radius: 0.25rem;
    font-size: 14px;
    line-height: 14px;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
    font-family: 'IBM Plex Sans', sans-serif;
    transition: 0.3s ease-in-out;
    outline: none;

    &:hover {
        color: $findest-text;
        box-shadow: -2px 2px 6px rgba(0, 0, 0, 0.05);
        border-color: $findest-dark-gray;
    }
}