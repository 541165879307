@import 'Styles/colors.module.scss';

h1 {
    font-size: 28px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.15px;
    margin: 0;
}

// Headline 4
h2 {
    font-size: 22px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.15px;
    margin: 0;
}

// Headline 6
h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.144px;
    margin: 0;
}

// Body 1
p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.496px;
    // letter-spacing: 0.144px -- Dense
    margin: 0;
}

// Body 2
h4:not([class*="heading4"]) {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.252px;
    // letter-spacing: 0.098px -- Dense
    margin: 0;
}

// Caption
h5 {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.396px;
    margin: 0;
}

// Overline
h6 {
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 1.5px;
    margin: 0;
    text-transform: uppercase;
}

ul, ol, li {
    margin: 0;
    padding: 0;
    list-style: none;
}

input, button {
    padding: 0;
}

button {
    text-align: unset;
    background: none;
    border: none;
    cursor: pointer;
}

svg {
    width: unset;
    height: unset;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
}