@import "Styles/colors.module.scss";

.savedDocumentItemContainer {
    border: 1px solid $main-gray;
    border-radius: 4px;
    margin-bottom: 16px;
    cursor: pointer;
    position: relative;

    &:last-of-type {
        margin-bottom: 0px;
    }

    &.selected {
        background-color: $main-gray;
        border-color: $main-gray;
        &.linkedDoc, &.unlinkedDoc {
            background-color: $primaryButtonColorDisabled;
            border-color: $primaryButtonColorDisabled;
        }
    }

    &.linkedDoc {
        background-color: $highlightBackgroundHoverColor;
        border-color: $highlightBackgroundHoverColor;
    }

    &:not(.selected):hover {
        border-color: $findest-gray;
        .savedDocumentItemActions {
            display: flex;
        }
    }

    .savedDocumentItem {
        position: relative;
        display: flex;
        padding: 16px 24px 16px 16px;
        color: $main-text-color;
    
        .savedDocumentType {
            margin: 0 32px 0 16px;
            max-width: 70px;
            min-width: 70px;
            line-height: 32px;
        }

        .savedDocumentDetails {
            width: calc(100% - 274px);
            display: flex;
            flex-direction: column;

            .savedDocumentTitle {
                display: flex;
                align-items: center;
                max-width: 100%;
                color: $main-text-color;
                text-decoration: inherit;
                font-size: 1rem;
                font-weight: 500;
                line-height: 2;
                letter-spacing: 0.144px;
                margin-right: auto;
    
                .savedDocumentTitleText {
                    max-width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
    
                .openUrlIcon {
                    display: none;
                    padding: 4px;
                    margin-left: 8px;
                    color: $findest-gray;
                    border: 1px solid white;
                    border-radius: 4px;
                    cursor: pointer;
                }

                .openUrlIconDisabled {
                    cursor: not-allowed;
                }
            }
        }

        .creationInformation {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-left: auto;

            .dateAdded {
                font-size: 14px;
                padding-left: 16px;
                text-align: right;
                color: $main-text-color;
                flex-shrink: 0;
                margin-left: auto;
                margin-top: 2px;
            }
            .createdByAccountContainer {
                padding-left: 10px;
            }
        }

        .savedDocumentCountsContainer {
            display: flex;
        }
    }

    &:hover {
        .openUrlIcon {
            &:not(.openUrlIconDisabled):hover {
                color: $main-black !important;
                background-color: $main-gray;
            }
            display: block !important;
        }
    }

    .savedDocumentItemActions {
        position: absolute;
        right: -38px;
        display: none;
        flex-direction: column;
        top: 0;
        justify-content: flex-end;

        &:before {
            content: " ";
            display: block;
            position: absolute;
            top: 0;
            left: -6px;
            right: 30px;
        }

        > svg {
            border-radius: 4px;
            height: 12px;
            color: $white;
            cursor: pointer;
            margin-left: 12px;
            &:first-of-type {
                margin-bottom: 12px;
            }
        }

        .trashIcon {
            padding: 7px 8px;
            background-color: $main-light-red;
            &:hover {
                background-color: $main-dark-red;
            }
        }
    
        .linkIcon {
            padding: 7px 5px;
            background-color: $main-purple;
            &:hover {
                background-color: $main-purple-hover;
            }
        }

        .unlinkIcon {
            padding: 7px 5px;
            background-color: $main-light-red;
            &:hover {
                background-color: $main-dark-red;
            }
        }
    }
}