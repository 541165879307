@import 'Styles/colors.module.scss';
@import 'Styles/customScrollbar.module.scss';

.tableContainer {
    margin-top: 10px;
    overflow-x: auto;
    overflow-y: auto;
    @include customScrollbar;

    &.viewOnly {
        th {
            &:hover {
                background-color: $table-header;
            }
            .headerContent {
                .columnTitle {
                    display: block;
                    line-height: 2rem;
                }
                .columnActionsContainer {
                    display: none;
                }
            }
        }
    }

    table {
        max-width: unset;
        td {
            position: relative;
            max-width: 785px;
            button[class*="editModeButton"] {
                display: none;
            }
            &.useAbsolutePositioningForActions {
                button[class*="editModeButton"] {
                    position: absolute;
                    right: 50px;
                    outline: 4px solid white;
                }

                .cellActionsContainer {
                    position: absolute;
                    right: 12px;
                }
            }

            &.disabled {
                .cellContent {
                    opacity: .3;
                }
            }
            &:hover {
                background-color: unset;
            }
            .cellActionsContainer {
                min-width: 32px;
                height: 32px;
                margin-left: auto;
            }
        }

        th {
            padding: 8px 12px 8px 16px;
            height: 48px;
            max-width: 500px;
            min-width: 180px;
            .tableHeaderCellRefreshContainer {
                &:not(.focused) {
                    display: none;
                }
                background-color: white;
                &.firstButton {
                    margin: 0 4px;
                }
            }
            &:hover {
                .tableHeaderCellRefreshContainer {
                    display: flex;
                }
                .tableHeaderCellRatingContainer {
                    display: none !important;
                }
            }
        }
        .tableHeaderCell {
            .headerContent {
                display: flex;
                justify-content: space-between;
                height: 32px;

                .tableHeaderCellRatingContainer {
                    display: flex;
                    align-items: center;
                }
            }
            .columnActionsContainer {
                width: 68px;
                display: flex;
                justify-content: flex-end;
            }
        }

        .tableHeaderCellRefreshContainer {
            width: 2rem;
            height: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            background-color: $light-gray;
            color: $findest-dark-gray;
            margin-left: auto;
            cursor: pointer;
        }

        .ratingStarContainer {
            padding: 4px 2px;
        }
    }

    .selectionBox {
        border: 2px solid $findest-blue;
        display: none;
        position: absolute;
        pointer-events: none;
        top: 0;
        left: 0;
        z-index: 1;
    }

    .columnTitle {
        font-size: 1rem;
        font-weight: 500;
        border: none;
        background: transparent;
        outline: none;
        display: flex;
        align-items: center;
        letter-spacing: 0.031rem;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &::placeholder {
            font-style: italic;
            font-weight: 400;
        }
    }

    td.refreshable {
        &:hover {
            .ratingStarContainer {
                display: none;
            }
            .tableHeaderCellRefreshContainer {
                display: flex;
            }
            &.useAbsolutePositioningForActions {
                button[class*="editModeButton"] {
                    display: flex;
                }
                .tableHeaderCellRefreshContainer {
                    outline: 4px solid white;
                }
            }
        }
    }
    .cellContent {
        display: flex;
        align-items: center;
        .ratingStarContainer {
            margin-left: auto;
        }
        .tableHeaderCellRefreshContainer {
            display: none;
        }
        .objectChip {
            margin-right: 22px;
            margin-left: 4px;
        }
        .fiveRatingStarsContainer {
            margin-left: auto;
        }
        .maturityLevelContainer {
            margin-left: auto;
        }
    }

    .actionsBar {
        position: absolute;
        bottom: -40px;
        z-index: 1;
        white-space: nowrap;
        border-radius: 4px;
        display: flex;
        box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.16);
        height: 2rem;
        background-color: white;
        padding: 0 8px;
        align-items: center;

        &.hidden {
            display: none;
        }

        .action {
            padding: 0 8px 0 12px;
            cursor: pointer;
            height: 100%;
            display: flex;
            align-items: center;
            svg {
                padding-right: 8px;
            }
            &:hover {
                background-color: $light-gray;
            }
        }
    }
}