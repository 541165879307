@import 'Styles/colors.module.scss';

.hitsCountContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 24px;
    font-size: 14px;
    line-height: 14px;
    border-radius: 4px;
    color: $main-black;
    cursor: default;
    user-select: none;
    min-width: 44px;
    width: 44px;

    &.disabled {
        visibility: hidden;
    }
}