@import 'Styles/colors.module.scss';
@import "Styles/customScrollbar.module.scss";

.radarContainer {
    overflow-y: auto;
    overflow-x: hidden;
    @include customScrollbar;
    min-width: 100%;
    height: -webkit-fill-available;
    margin: 60px 0 30px 0;
}

.maturityRadarContainer {
    padding: 0 4rem;
    margin-top: 56px;
    .maturityRadarHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .maturityRadarTitle {
            font-size: 1.75rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0 16px 0 0;
            margin: 0;
            color: $main-text-color;
            font-weight: 500;
            letter-spacing: 0.013rem;
        }
        .maturityRadarEditingDetails {
            display: flex;
            align-items: center;
            min-height: 2.5rem;
            button:nth-of-type(2) {
                margin-left: 16px;
            }
            .lastUpdatedDate {
                font-size: 0.875rem;
                line-height: 1.5rem;
                letter-spacing: 0.016rem;
                color: $main-text-color;
                display: flex;
                margin-left: 16px;
                white-space: nowrap;
                .dateAdded {
                    margin-left: 4px;
                }
            }

            .maturityScaleContainer {
                width: 256px;
                min-width: 256px;
                height: 26px;
                .maturityScaleActualStep {
                    display: none;
                }
            }

            .maturityLevelScaleStepsTextsContainer {
                padding-top: 2px;
            }
        }
    }
}