@import "Styles/colors.module.scss";
@import "Styles/variables.module.scss";

.container {
  display: flex;
  flex-direction: row;

  .gripButton,
  .insertAfterButton {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    outline: none;
    border: none;
    border-radius: 4px;
    margin: 0;
    cursor: pointer;

    &:hover {
      background-color: $blue-100;
    }
  }
}

.leftFloatingMenuOptionPopover {
  padding: 4px 8px;
  .popoverButton {
    width: 100%;
    padding: 4px 8px;
    margin-bottom: 8px;
    &:hover {
      background-color: $light-gray;
    }
  }
  .spacer {
    width: 100%;
    height: 1px;
    margin: 8px 0;
    background-color: $light-gray;
  }
  .deleteButton {
    margin: 0;
    color: $notification-red;
  }
}

.tooltip {
  .line {
    font-family: $font-primary;
    font-size: $font-size-s;
    font-weight: $font-weight-450;
    line-height: 15.6px;
    text-align: center;
    .action {
      font-weight: $font-weight-700;
      margin-right: $spacing-4;
    }
  }
}
