@import 'Styles/colors.module.scss';

.collapsibleListWithLeftBorder {
    margin-left: 15px;
    border-left: 1px solid $navy-blue;
}

.listItem {
    margin: .25rem 1rem .25rem 0;
    .itemText {
        font-size: 0.875rem;
    }
}

.listItemIconContainer {
    margin-left: 0 !important;
}

.chevronIconContainer, .onlyChildIconContainer {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px 4px;
    border-radius: 4px;

    .chevronIcon {
        font-size: .75rem;
    }
    .onlyChildIcon {
        font-size: 6px;
    }
}
.chevronIconContainer {
    cursor: pointer;
    &:hover {
        background-color: $white-25;
    }
}