@import "Styles/customScrollbar.module.scss";
@import "Styles/colors.module.scss";

.sidebarContainer {
  background-color: $main-background-color;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid transparent;
  z-index: 5;

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px 32px 0;
    color: $white;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      border-radius: 1px;
      top: -2px;
      width: 100%;
    }

    .accountBoxContainer {
      margin-bottom: 16px;
      display: flex;
      width: 100%;
      height: 3rem;
      align-items: center;
      .footerLogout {
        color: white;
        border-radius: 4px;
        font-size: 14px;
      }
    }

    .footerItemContainer {
      padding: 0;
      width: 100%;
      margin: 0px 0 8px;
    }
  }
}

.sidebarContainerMainTop {
  flex-direction: column;
  background-color: $main-background-color;
  display: flex;
  min-width: 288px;
  max-width: 380px;
  width: 20vw;
  z-index: 5;
  justify-content: flex-start;

  .sidebarContainerMain {
    display: flex;
    height: calc(100% - 67px);

    .sidebarContainer {
      margin-top: 32px;
      transition: all 0.2s ease-out;
      overflow-y: auto;
      overflow-x: hidden;
      @include customScrollbar;
    }
  }

  .universeLogo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    > img {
      width: 100px;
      cursor: pointer;
    }
  }

  &.doubleSidebar {
    .sidebarContainer {
      width: 40px;
      border-right: 1px solid $findest-gray;
    }
    .footer {
      margin: 0;
      &:before {
        display: none;
      }
      .footerLogout {
        justify-content: center;
      }
      .goBackContentContainer {
        width: 24px;
        height: 24px;
      }
    }
  }
  &:not(.doubleSidebar) {
    .sidebarContainer {
      width: 100%;
    }
  }
}

.profilePopover {
  margin-left: 20px;
  margin-top: -10px;
  padding: 0;
  border-radius: 8px;
}

.tenantPopover {
  padding: 0;
  border-radius: 8px;
}
