@import "Styles/colors.module.scss";

.queryItemContainer {
    padding: 12px 32px 24px 16px;
    position: relative;
    border: 1px solid $light-gray;
    border-radius: 4px;
    margin-bottom: 16px;
    cursor: pointer;

    &.selected {
        background-color: $main-gray;
        border-color: $main-gray;
    }

    &:not(.selected):hover {
        border-color: $findest-gray;

        .queryItemActions {
            display: none;
        }
    }

    &:last-of-type {
        margin-bottom: 0px;
    }

    .queryItem {
        display: flex;
        align-items: center;

        .queryName {
            padding: 0;
        }

        &:hover {
            .queryName {
                text-decoration: underline;
            }
        } 
    
        .creationInformation {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-left: auto;
    
            .dateAdded {
                font-size: 0.875rem;
                padding-left: 16px;
                text-align: right;
                color: $main-text-color;
                flex-shrink: 0;
                margin-left: auto;
                margin-top: 2px;
            }
    
            .createdByAccountContainer {
                padding-left: 10px;
            }
        }
    }

    .queryItemConnectedObjects {
        margin: 16px 0 0 48px;
    }

    .checkboxContainer {
        margin-right: 16px;
        align-self: start;
    }

    .queryItemActions {
        position: absolute;
        flex-direction: row;
        right: 115px;
        display: none;
        top: 12px;
        justify-content: flex-end;

        button {
            margin-left: 16px;
            position: relative;

            &:before {
                content: " ";
                display: block;
                position: absolute;
                width: 32px;
                height: 32px;
                top: 0;
                left: -31px;
            }
        }
    }

    .objectSearchPopupContainer {
        width: 512px;
        padding: 0 0 4px 0;
        overflow: hidden;
        display: flex;
    }
}

.previewPopover {
    padding: 16px 32px 24px;
    .searchTermsAndFiltersContainer {
        display: flex;

        .searchTermType, .filterType {
            height: 32px;
            display: flex;
            align-items: center;
            color: $findest-dark-gray;
            font-size: 0.625rem;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 1.5px;
            text-transform: uppercase;
            margin-bottom: 8px;
        }

        .searchTermsContainer {
            margin-right: 64px;
            .searchTermTypeContainer {
                margin-bottom: 8px;

                .searchTermContainer {
                    height: 32px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 12px 0 16px;
                    border-radius: 4px;
                    min-width: 250px;
                    &:nth-child(even) {
                        background-color: $findest-background-03;
                    }
                    .searchTerm {
                        color: $main-text-color;
                        font-size: 1rem;
                    }
                    .searchTermCriteria {
                        font-size: 0.625rem;
                        margin-left: 70px;
                        color: $findest-blue;
                        &.shouldMatch {
                            color: $findest-yellow;
                        }
                    }
                }
            }
        }

        .filtersContainer {
            .filterTypesContainer {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                gap: 8px;

                .filterItem {
                    height: 32px;
                    display: flex;
                    align-items: center;
                    color: $main-text-color;
                    border-radius: 16px;
                    font-size: 1rem;
                    border: 1px solid $findest-gray;
                    padding: 0 16px;
                }
            }
        }
    }

    .buttonsContainer {
        display: flex;
        margin-top: 24px;
        > button:first-of-type {
            margin: 0;
        }
    }
}