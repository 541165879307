@import 'Styles/colors.module.scss';

.connectedQueriesContainer {
    display: flex;
    flex-direction: column;

    h1 {
        margin-top: 56px;
        user-select: none;
    }

    .connectedQueriesListContainer {
        margin-top: 8px;

        .queryItemContainer {
            padding-left: 32px;
            margin-bottom: 16px;

            .queryItemConnectedObjects {
                margin: 16px 0 0 0;
            }
        }
    }

    .addQueryButtonContainer {
        margin-right: auto;

        .addQueryButtonStyle {
            color: $main-text-color;
        }

        .addQueryOptionContainer {
            right: initial;
        }

        .addQueryOptionButtonText {
            font-family: 'IBM Plex Sans', sans-serif;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 1.246px;
        }
    }
}