.popover {
  padding: 0 !important;
  overflow: visible !important;
  max-width: unset !important;
  z-index: 10000 !important;

  &::before,
  &::after {
    content: " ";
    display: block;
    position: absolute;
  }

  &:not(.horizontal) {
    &::before {
      width: 100%;
      height: 8px;
      top: -8px;
      left: 0;
    }

    &::after {
      width: 100%;
      height: 8px;
      bottom: -8px;
      left: 0;
    }
  }

  &.horizontal {
    &::before {
      width: 8px;
      height: 100%;
      top: 0;
      left: -8px;
    }

    &::after {
      width: 8px;
      height: 100%;
      top: 0;
      right: -8px;
    }
  }
}
