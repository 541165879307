@import 'Styles/colors.module.scss';
@import 'Styles/customScrollbar.module.scss';

.overviewTableModal {
    display: flex;
    height: 100%;
    overflow: hidden;
    padding: 0;
    .header {
        position: absolute;
        right: 21px;
        top: 11px;
    }
    .tableOptionsContainer {
        padding: 48px 64px;
        width: 266px;
        min-width: 266px;
        border-right: 1px solid $findest-gray;
        overflow-y: auto;
        overflow-x: hidden;

        .optionTitle {
            margin-bottom: 4px;
        }
        .numberOfLayersContainer {
            margin-top: 8px;
        }
        .insertButtonContainer {
            margin-top: 48px;
        }
    }
    .tableContainer {
        padding: 48px 64px 48px 48px;
        overflow-x: auto;
        overflow-y: auto;
        @include customScrollbar;

        table {
            max-width: unset;
            td {
                position: relative;
                &.disabled {
                    .cellContent {
                        opacity: .3;
                    }
                }
            }
            .tableHeaderCell {
                .headerContent {
                    display: flex;
                    justify-content: space-between;

                    .tableHeaderCellRatingContainer {
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }

        .selectionBox {
            border: 2px solid $findest-blue;
            display: none;
            position: absolute;
            pointer-events: none;
            top: 0;
            left: 0;
            z-index: 1;
        }

        .columnTitle {
            font-size: 1rem;
            font-weight: 500;
            border: none;
            background: transparent;
            outline: none;
        }
        .cellContent {
            display: flex;
            align-items: center;
            .ratingStarContainer {
                margin-left: auto;
            }
        }

        .actionsBar {
            position: absolute;
            bottom: -40px;
            z-index: 1;
            white-space: nowrap;
            border-radius: 4px;
            display: flex;
            box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.16);
            height: 2rem;
            background-color: white;
            padding: 0 8px;
            align-items: center;

            &.hidden {
                display: none;
            }

            .action {
                padding: 0 8px 0 12px;
                cursor: pointer;
                height: 100%;
                display: flex;
                align-items: center;
                svg {
                    padding-right: 8px;
                }
                &:hover {
                    background-color: $light-gray;
                }
            }
        }
    }
}
