@use "Styles/customScrollbar.module.scss" as customScrollbarMixin;

.inboxContainer {
  @include customScrollbarMixin.customScrollbar;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

