@import 'Styles/colors.module.scss';

.iconnedTabs {
    display: flex;
    margin: 0 0.1rem;

    .tab {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #F2F4F8;
        width: 46px;
        height: 32px;
        text-align: center;
        line-height: 24px;
        color: $findest-dark-gray;
        cursor: pointer;

        &:first-of-type {
            border-top-left-radius: 4px;
        }
        &:last-of-type {
            border-top-right-radius: 4px;
        }

        &.selected {
            background-color: #FFFFFF;
            color: $main-black;
            box-shadow: 0px 6px 0 white, 0 0 6px $general-shadow-color;
            position: relative;
        }
        &.disabled {
            background-color: #FCFCFD;
            color: $findest-gray;
        }
        .tabIcon {
            height: 15px;
        }
    }
}