@import 'Styles/colors.module.scss';

div {
    .fileUploadProgressIndicatorModal {
        max-width: 13.75rem;
        padding: 1rem 2rem;
    }
}

.fileUploadProgressIndicatorModal {
    .header {
        margin: 0 1rem 0.75rem;
    }

    .body {
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .processingText {
            padding: 0 1rem;
            font-size: 0.875rem;
            line-height: 1.2;
            margin-top: 0.5rem;
        }
    }
}