@import "Styles/colors.module.scss";

.publicationsPerYearContainer {
    padding: 12px 64px 20px 20px;
    border-radius: 0.5rem;

    .publicationsPerYearHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;

        .publicationsPerYearTitle {
            font-size: 1rem;
            font-weight: 600;
            margin-bottom: 2px;
        }
        
        .enlargePublicationsPerYearIcon {
            padding: 5px;
            border-radius: 4px;
            visibility: hidden;

            &:hover {
                background-color: $light-gray-3;
                cursor: pointer;
            }
        }
    }

    &:hover {
        .enlargePublicationsPerYearIcon {
            visibility: visible;
        }
    }

    .publicationsPerYearContent {
        padding: 10px;
        border: 1px solid $findest-gray;
        border-radius: 4px;
        width: 100%;
        height: 100%;
    }
}