@import 'Styles/colors.module.scss';

.dropdownButton {
    margin: 0;
    .objectUnlockButton {
        background-color: $secondaryButtonColorHover;
        color: $findest-blue;
        min-width: 46px;
        padding: 0 8px 0 10px !important;
        justify-content: space-between;
        &.dropdownButtonHover {
            color: white;
            background-color: $findest-blue;
            .rightIcon {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
        .buttonIconContainer {
            width: unset;
        }
        .rightIcon {
            svg {
                width: 8px;
            }
        }
    }
    &:not(.viewMode) {
        .objectUnlockButton {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
        }
    }
    &.viewMode {
        margin: 0 1.5rem 0 1rem;
    }
}