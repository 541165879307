@import "Styles/colors.module.scss";
@import "Styles/customScrollbar.module.scss";

.affiliationsContainer {
  padding: 0 64px 0 20px;
  border-radius: 4px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  flex: 1;

  h3 {
    font-size: 1rem;
    font-weight: 600;
    font-style: medium;
    margin-bottom: 2px;
  }

  .affiliationsContent {
    padding-left: 8px;
    padding-bottom: 8px;
    border: 1px solid $findest-gray;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-height: 45vh;

    .affiliationsList {
      padding: 4px 0;
      overflow-y: auto;     
      @include customScrollbar;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    .affiliationItem {
      padding: 2px;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1rem;
      flex-wrap: wrap;
      padding-right:8px;

      .affiliationName {
        flex: 1;
        max-width: calc(100% - 60px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 1.2em;
        position: relative;
      }
    }

    .showAllAffiliations {
      color: $findest-blue;
      cursor: pointer;
      font-size: 0.825rem;
      font-weight: 600;
      text-transform: uppercase;
      padding-top: 0.5rem;
      flex-shrink: 0;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
