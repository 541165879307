
.settings {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .tabsContainer {
        padding: 0;
        margin: 0 64px;

        .tab {
            flex: none;
            padding: 0 16px 6px 16px;
        }
    }
}