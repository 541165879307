@import "Styles/colors.module.scss";

.ratingProgressPopover {
    padding: 16px 32px;
    color: $main-text-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 196px;
    min-width: 196px !important;
    overflow: visible !important;
    z-index: 6;

    &::before,
    &::after {
        content: " ";
        display: block;
        position: absolute;
    }
    &::before {
        height: 100%;
        width: 8px;
        top: 0;
        left: -8px;
    }
    &::after {
        width: 8px;
        height: 100%;
        top: 0;
        right: -8px;
    }

    h6 {
        color: $findest-dark-gray;
    }
    .ratingStarContainer {
        font-size: 28px;
        margin-bottom: 4px;
        &:hover {
            background-color: unset;
        }
    }
}