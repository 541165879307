@import 'Styles/colors.module.scss';

.rightPanel {
    width: 48px;
    max-height: 104px;
    position: absolute;
    left: -49px;
    top: 64px;
    border: 1px solid $findest-gray;
    border-right: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px 0 0 4px;
    padding: 16px 8px;
    background-color: $white;
    .referencesButton {
        margin-top: 8px;
    }
}
