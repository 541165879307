@import 'Styles/colors.module.scss';

.positionedPopup {
    background-color: $white;
    border-radius: 4px;
    font-size: 14px;
    position: absolute;
    z-index: 2;
    min-width: 100%;

    .inner {
        overflow-y: auto;
        height: 100%;
    }
}