@import "Styles/colors.module.scss";
@import "Styles/objectIcon.module.scss";

.entityLikeItemContainer {
    position: relative;
    border: 1px solid $main-gray;
    border-radius: 4px;
    margin-bottom: 16px;
    cursor: pointer;

    &.selected {
        background-color: $main-gray;
        border-color: $main-gray;
    }

    &:not(.selected):hover {
        border-color: $findest-gray;

        .entityLikeItemActions {
            display: flex;
        }
    }

    .entityLikeItem {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 12px 16px 16px 16px;

        &.hasMoreActions {
            padding-right: 8px;
        }

        .checkboxContainer {
            margin-right: 16px;
            align-self: start;
        }

        .entityLikeProperties {
            display: flex;
            flex-direction: column;
            min-width: calc(65% - 200px);
            max-width: calc(65% - 200px);

            .entityLikeTypeContainer {
                display: flex;
                margin-bottom: 4px;

                .objectIcon {
                    @include objectIcon;
                    font-size: 1rem;
                    margin-right: 8px;
                }

                .entityLikeType {
                    color: $main-text-color;
                    font-size: 10px;
                    font-weight: 500;
                    line-height: 16px;
                    letter-spacing: 1.5px;
                    text-transform: uppercase;
                }
            }

            .entityLikeTitle {
                font-size: 20px;
                font-weight: 500;
                line-height: 24px;
                color: $main-text-color;
                letter-spacing: 0.15px;
                margin-bottom: 2px;
                word-break: break-word;
            }

            .entityLikeDescription {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                font-size: 16px;
                line-height: 24px;
                color: $main-text-color;
                letter-spacing: 0.496px;
                margin-bottom: 8px;
                text-align: justify;
                max-height: 104px;

                h1, h2, h3 {
                    font-size: 16px !important;
                    font-weight: 400 !important;
                }
          
                table, figure, blockquote, div[class*="node-intakeSheet"] {
                    display: none;
                }
            }

            .entityLikeItemCountsContainer {
                margin-top: auto;
                display: flex;
            }
        }

        .entityLikeImageContainer {
            position: relative;
            text-align: center;
            min-width: calc(35% - 64px);
            max-width: calc(35% - 64px);
            padding-left: 2rem;
            margin-top: 4px;

            .entityLikeImageAspectRatioBox {
                height: 0;
                overflow: hidden;
                padding-bottom: 56.25%;
                position: relative;
                width: 100%;

                .entityLikeImageAspectRatioBoxContent {
                    display: flex;
                    height: 100%;
                    justify-content: center;
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 100%;

                    img {
                        max-height: 100%;
                        max-width: 100%;
                        object-fit: contain;
                    }
                }
            }

            .entityLikeImageCaption {
                margin-top: 0.5rem;
            }
        }

        .conclusion {
            box-shadow: -1px 1px 6px $buttonShadowColor;
            border-radius: 4px;
            padding: 12px 16px 16px;
            margin: 12px 0;
            color: $main-text-color;
            text-align: justify;

            .conclusionTitle {
                font-weight: 500;
                letter-spacing: 0.144px;
            }
        }

        .creationInformation {
            display: flex;
            justify-content: space-between;
            height: 32px;
            align-items: center;
            margin-left: auto;

            .dateAdded {
                font-size: 14px;
                padding-left: 16px;
                text-align: right;
                color: $main-text-color;
                flex-shrink: 0;
                margin-left: auto;
                margin-top: 2px;
            }
            .createdByAccountContainer {
                padding-left: 10px;
            }
            .moreActionsDropdownButton {
                margin-left: 8px;
            }
        }
    }
    .entityLikeItemActions {
        position: absolute;
        right: -38px;
        display: none;
        flex-direction: column;
        top: 0;
        justify-content: flex-end;

        
        &:before {
            content: " ";
            display: block;
            position: absolute;
            height: 80px;
            top: 0;
            left: -6px;
            right: 30px;
        }

        > svg {
            border-radius: 4px;
            height: 12px;
            color: $white;
            cursor: pointer;
            margin-left: 12px;
            &:first-of-type {
                margin-bottom: 12px;
            }
        }

        .entityLikeLinkIcon {
            padding: 7px 5px;
            background-color: $main-purple;

            &:hover {
                background-color: $main-purple-hover;
            }
        }

        .entityLikeTrashIcon {
            padding: 7px 8px;
            background-color: $main-light-red;

            &:hover {
                background-color: $main-dark-red;
            }
        }
    }
}