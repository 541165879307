@import "Styles/colors.module.scss";
@import 'Styles/customScrollbar.module.scss';

.listItem {
    display: flex;
    border-radius: 4px;
    align-items: center;
    cursor: pointer;
    color: inherit;
    text-decoration: none; 
}

.listItemContent {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    cursor: pointer;
    padding-right: 8px;

    // TRANSPARENT THEME STYLES
    &.transparent {
        border-radius: 4px;
        .moreActionsButton {
            display: none;
        }
        &.selected {
            background-color: $white;
            .itemText {
                color: $main-text-color;
                font-weight: 500;
            }
            &:hover, &.moreActionsDropdownOpen {
                .moreActionsButton {
                    display: block;
                    background-color: transparent !important;
                    &:hover, &.moreActionsDropdownButtonHover {
                        background-color: $light-gray !important;
                    }
                }
            }
        }
        &:not(.selected) {
            .itemText {
                color: $white;
            }
            &:hover, &.moreActionsDropdownOpen {
                background-color: $white-25;
                .moreActionsButton {
                    display: block;
                    svg {
                        color: white !important;
                    }
                    background-color: transparent !important;
                    &:hover, &.moreActionsDropdownButtonHover {
                        background-color: $white-25 !important;
                    }
                }
            }
        }
    }

    &.draggable {
        cursor: move;
    }

    &:hover {
        background-color: $main-gray;
        .rightContent {
            &.hasItemActions {
                .itemActionsContainer {
                    visibility: visible;
                }
                .itemState {
                    visibility: hidden;
                } 
            }
            .moreActionsDropdownButton {
                .moreActionsButton:not(.moreActionsDropdownButtonHover) {
                    background-color: $main-gray;
                }
            }
        }
    }

    .leftContent {
        display: flex;
        flex: 1;
        width: calc(70% - 32px);
        overflow: hidden;

        .itemContent {
            flex: 1;
            display: flex;
            justify-content: center;
            flex-direction: column;
            overflow: hidden;
        
            .createdByYou {
                font-size: 10px;
                line-height: 10px;
                color: $findest-dark-gray;
            }
        }
    }

    .listItemIconContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 32px;
        height: 40px;
        margin-left: 8px;
        user-select: none;

        svg {
            font-size: 16px;
        }

        svg[data-icon="thumbtack"] {
            transform: rotate(30deg);
        }
        
        &.colored {
            svg[data-icon="dice-d6"] {
                color: $entity-count-color;
            }
            svg[data-icon="book-open-reader"] {
                color: $study-count-color;
            }
            svg[data-icon="file"] {
                color: $document-count-color;
            }
            svg[data-icon="magnifying-glass"] {
                color: $query-count-color;
            }
        }
    }

    .itemText {
        letter-spacing: 0.144px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        color: $main-text-color;
    }

    .rightContent {
        position: relative;
        height: 32px;
        display: flex;
        align-items: center;
        margin-left: 8px;

        .smallMoreActionsButton {
            width: 24px;
            height: 24px;
        }

        .date {
            color: $findest-dark-gray;
            font-size: 12px;
        }

        .moreActionsDropdownButton {
            .moreActionsDropdownButtonHover {
                background-color: $findest-gray;
            }
            &:hover {
                .moreActionsButton:not(.moreActionsDropdownButtonHover) { 
                    background-color: $findest-gray;
                }
            }
        }

        .itemState {
            display: flex;
            height: 28px;
            align-items: center;
            letter-spacing: 0.098px;
            background-color: $findest-background-01;
            border: 1px solid $labelBorderColor;
            border-radius: 14px;
            padding: 0 16px;
            white-space: nowrap;
            margin-right: 8px;
            margin-left: 8px;
            max-width: 50%;
            overflow: hidden;
            min-width: fit-content;
            h4 {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .itemActionsContainer {
            position: absolute;
            visibility: hidden;
            top: 0;
            right: 0;
            display: flex;
            column-gap: 4px;
            cursor: pointer;
        }
    }    
}