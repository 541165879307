@import 'Styles/colors.module.scss';

.shareCodePage {
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-image: url('../../Assets/Images/background_blurred.png');

    @media screen and (max-width: 1190px) {
        background-size: cover;
    }
}

.shareCodeModal {
    width: 21.25rem;
    padding: 12px 28px 24px;
    text-align: center;

    .header {
        justify-content: center;
        margin-bottom: 32px;
    }
    .body {
        .bodyText { 
            font-size: 1.25rem;
            line-height: 1.5rem;
            letter-spacing: 0.15px;
            font-weight: 500;
            margin-bottom: 24px;
        }
    }

    .textBoxContainer {
        max-width: 9.813rem;
        margin: auto;
        input {
            text-align: center;
            font-size: 1.5rem;
            line-height: 1;
            letter-spacing: 0.18px;
        }
        .invalidCode {
            color: $findest-red;
            visibility: hidden;
            margin: 4px 0 7px;
            &.visible {
                visibility: visible;
            }
        }
    }

    .footerText {
        p {
            display: inline;
            font-size: 0.875rem;
            line-height: 1.25rem;
            letter-spacing: 0.252px;
            &.link {
                color: $findest-blue;
                font-weight: 500;
                margin-left: 4px;
                cursor: pointer;
            }
        }
    }
}