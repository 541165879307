@import "Styles/colors.module.scss";
@import 'Styles/customScrollbar.module.scss';

.linkGraphContainer {
    height: 450px;
    margin: 0 4rem;
    
    .controls {
        .vertical {
            transform: rotate(-45deg);
        }
        .horizontal {
            transform: rotate(45deg);
        }
    }

    &.fullscreen {
        height: 100%;
        width: 100%;
        margin: 0;
    }
}

.linkGraphTitle {
    padding: 0 4rem;
    color: $main-text-color;
    margin-bottom: 16px;
    margin-top: 56px;
}

.lowerZindex {
    z-index: 25;
}

.noFocusScrollContainer {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    @include customScrollbar;
    padding: 24px 64px;

    .noFocusContainer {
        width: 300px;
        margin: auto;

        h6 {
            margin-bottom: 1rem;
        }

        .noFocusRecentActive {
            margin-right: 0;
            width: 100%;
        }
    }
}