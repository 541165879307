.horizontalLoader {
  height: 8px;
  width: 100%;
  --c: no-repeat linear-gradient(#007AFF 0 0);
  background: var(--c), var(--c), #F2F4F8;
  background-size: 40% 100%;

  &.medium {
    animation: anim 2.5s linear infinite;
  }

  &.fast {
    animation: anim 1.5s linear infinite;
  }
}

@keyframes anim {
  0% {
    background-position: -150% 0, -150% 0;
  }

  100% {
    background-position: 250% 0, 250% 0;
  }
}
