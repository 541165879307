@import "Styles/colors.module.scss";
@import "Styles/customScrollbar.module.scss";
@import "../loginCommonStyles.module.scss";

.loginPage {
  display: flex;
  height: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: center;

  .loginLeftSide {
    min-height: 100%;
    width: 60%;
    background-color: $main-background-color;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
  }

  .loginRightSide {
    height: 100%;
    min-height: 100%;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .findestUniverseLogoContainer {
      padding: 30px 0 10px;
      .findestUniverseLogo {
        max-width: 160px;
      }
    }

    .loginRightSideContent {
      @include customScrollbar;
      width: 100%;
      display: flex;
      margin: auto;
      overflow-y: auto;
      padding: 0 30px 10px;
      flex-direction: column;
      align-items: center;

      .maintenanceContainer {
        display: flex;
        width: 62%;
      }
    }

    form {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;

      .loginWithEmailorPasswordContainer {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    }

    .loginErrorMessage {
      color: $main-dark-red;
      margin: 30px 0;
      max-width: 400px;
    }
  }

  .goBackButton {
    > div {
      cursor: default;
      &:hover {
        background-color: unset;
      }
    }
    svg {
      cursor: pointer;
    }
  }

  @media screen and (max-width: 900px) {
    .loginLeftSide {
      display: none;
    }
    .loginRightSide {
      width: 100%;
    }
  }
}
