@import 'Styles/customScrollbar.module.scss';
@import 'Styles/colors.module.scss';

.tenantDetailsContainer {
    padding: 32px 64px;
    width: 100%;
    height: 100%;
    overflow: auto;
    @include customScrollbar;
    color: $main-text-color;

    .tenantName {
        border-bottom: 1px solid $main-gray;
        padding-bottom: 16px;
        margin-bottom: 16px;
    }
    .tenantDetailsSectionTitle, .tenantDetailsSection {
        margin-bottom: 16px;
    }
}