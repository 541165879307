@use "Styles/customScrollbar.module.scss" as customScrollbarMixin;
@import "Styles/colors.module.scss";

.queryDetailsPageContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    
    .queryDetailsPageHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 16px 64px;
        min-height: 48px;
        height: 48px;

        .queryDetailsPageHeaderPart {
            display: flex;
            align-items: center;

            &:first-of-type {
                flex-grow: 1;
                margin-right: 16px;
                column-gap: 32px;
                input {
                    flex-grow: 1;
                }
            }

            &:last-of-type {
                margin-right: 96px;
            }

            .goBackToQueriesIcon {
                cursor: pointer;
                margin-right: 10px;
            }

            h1 {
                font-size: 2.125rem;
            }

            .titleInput {
                margin: 0;
                padding: 0;
            }

            .queryOptionsButton {
                margin-right: 16px;
                button {
                    background-color: $secondaryButtonColorDefault;
                    color: $main-black;
                    &:hover {
                        background-color: $secondaryButtonColorHover;
                    }
                }
            }
        }
    }

    .queryDetailsContainer {
        display: none;
        padding: 0 0 16px 0;
        
        &.active {
            display: flex;
            flex-direction: column;
            height: calc(100% - 80px);
            width: 100%;
        }

        .technologySearchFieldsContainer {
            padding: 0 64px 16px 64px;
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            @include customScrollbarMixin.customScrollbar;
        }

        .tabsContainer {
            padding: 0;
            margin: 0 64px 28px;
    
            .tab {
                flex: none;
                padding: 0 16px 6px 16px;
            }
        }
    }

    .connectedObjectsContainer {
        margin: 0 64px 16px 64px;
    }

    .objectSearchPopupContainer {
        width: 512px;
        padding: 0 0 4px 0;
        overflow: hidden;
        display: flex;
    }

    .actionButtons {
        display: flex;
        column-gap: 16px;
        margin: 16px 0 8px 0;
        padding: 0 64px;

        .loadingIndicatorContainer {
            width: auto;
            padding-top: 0;
            column-gap: 16px;
        }
    }
}

.loadingIndicatorContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 4rem;
}