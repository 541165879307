@import "/src/Components/Shared/DivDropdown/divDropdown.module.scss";
@import "Styles/Legacy/colors.module.scss";

.overrideDivDropdown {
    width: auto;
    margin: 0;
    @extend .divDropdown;
    
    .divDropdownSelect {
        width: auto;
        padding: 0 0.5rem;
        height: 22px;
        display: flex;
        align-items: center;

        .comparisonIcon {
            svg {
                margin: 0 0.25rem 0 0;
                font-size: 14px;
                color: $findest-text;
            }
        }

        .comparisonChoice {
            display: none;
        }
    }

    .divDropdownList {
        width: auto;
        margin-top: 0.25rem;
        z-index: 1;
        position: fixed;

        div {
            div {
                width: auto;
                padding: 0 0.5rem 0 0;
                align-items: center;
                cursor: pointer;

                .comparisonIcon {
                    width: 1.5rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    svg {
                        margin: 0;
                        font-size: 14px;
                        color: $findest-text;
                    }
                }
            }
        }
    }
}

.comparisonChoice {
    display: block;
    font-size: 14px;
}