@import "Styles/colors.module.scss";
@import 'Styles/customScrollbar.module.scss';

.deletedArchiveContainer {
    display: flex;
    flex-direction: column;
    height: calc(100% - 40px);

    .tabsContainer {
        padding: 0;
        margin: 0 64px;

        .tab {
            flex: none;
            padding: 0 16px 6px 16px;
        }
    }

    .deletedArchiveListContainer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;
        @include customScrollbar;
    }
}

.restoreButton {
    display: none;
    padding: 9px 10px;
    border-radius: 4px;
    height: 14px;
    background-color: $primaryButtonColorDefault;
    color: $white;
    cursor: pointer;
    &:hover {
        background-color: $primaryButtonColorHover;
    }
}

.listItemsContainer {
    padding: 0 64px;
}