@import 'Styles/colors.module.scss';
@import "Styles/objectIcon.module.scss";

.commonObjectReference {
    display: flex;
    border: .5px solid white;
    border-left-width: 1px;
    position: relative;
    &:not(.hover) { 
    border-left-color: $light-gray;
    }

    .addLinkContainer {
        min-width: 24px;
        height: 24px;
        justify-content: space-evenly;
        align-items: center;
        display: flex;
        flex-direction: column;
        position: relative;
        cursor: pointer;
        margin: auto 4px;
        border-radius: 50%;
        color: $findest-dark-gray;
    
        .addReference {
            width: 24px;
            height: 100%;
            justify-content: center;
            align-items: center;
            display: flex;
            font-size: 14px;
            color: $findest-blue;
            cursor: pointer;
    
            &:hover {
                background-color: $secondaryButtonColorHoverLight;
            }
        }
    
        svg {
            width: 12px;
            font-size: 14px;
        }

        &:hover {
            background-color: $purple-4;
            color: $findest-purple;
        }

        &.isLinked {
            background-color: $findest-purple;
            color: $white;

            &:hover {
                background-color: $violet-1;
            }
        }
    }

    .referenceObjectDetails {
        padding: 16px 4px 16px 0;
        width: 100%;
        color: $main-text-color;
        cursor: pointer;

        &:hover {
            .objectName {
                text-decoration: underline;
            }
        }

        .referenceObjectTypeDetails {
            display: flex;
            .referenceObjectTypeIcon {
                margin-right: 6px;
                font-size: 0.875rem;
                @include objectIcon;
            }
        }
        
        .objectType {
            font-size: 10px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 1.5px;
            text-transform: uppercase;
            margin-bottom: 2px;
        }
        .objectName {
            font-size: 1rem;
            font-weight: 500;
            line-height: 1.5;
            letter-spacing: 0.496px;
            word-break: break-word;
            display: -webkit-box;
            line-clamp: 3;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
    &.hover {
        border-color: $findest-blue;   
    }

    &:not(.isUsed).hover {
        border-left-color: white;
    }

    &.isUsed {
        background-color: $light-gray;
        &.hover {
            border-left-color: $light-gray;
        }
    }
    &:not(.hover).isUsed {
        border-color: $light-gray;
    }
}
