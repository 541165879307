@import "Styles/colors.module.scss";

.isDraggedOver {
    background-color: $findest-light-blue;
    border-radius: 4px;
}

.linksListItemContainer {
    display: flex;
    flex-direction: row;
    margin-right: auto;

    .linksListItemActionsContainer {
        width: 73px;
        display: flex;
        align-items: center;
        max-height: 50px;
        padding-right: 9px;
        justify-content: flex-end;

        .actionContainer{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            color: $findest-dark-gray;
            border-radius: 4px;
            font-size: 14px;
            cursor: pointer;

            &:hover {
                background-color: $light-gray;
                color: $main-black;
            }

            &.dragIconContainer {
                cursor: grab;
            }
        }
        .onlyChildObjectIcon {
            width: 6px;
        }
    }

    .linkListItemContentContainer {
        display: flex;
        flex-direction: column;
        width: calc(100% - 73px);

        .draggableObjectItem {
            margin-right: auto;
            padding: 3px;
        }
    }
}