@import "Styles/colors.module.scss";

.connectionsSectionContainer {
    .connectionsTitle {
        color: $findest-dark-gray;
        margin-bottom: 4px;
        user-select: none;
    }

    .connectionsContainer {
        display: flex;
        flex-wrap: wrap;
        row-gap: 12px;
        column-gap: 16px;
    }

    .connectToNewObject {
        font-style: italic;
        font-size: 1rem;
        color: $findest-dark-gray;
        display: flex;
        align-items: center;
        padding: 0 12px;
        height: 32px;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
            background-color: $secondaryButtonColorDefault;
            color: $findest-text;
        }
    }
}