@import "Styles/customScrollbar.module.scss";

.queryOptions {
  gap: 16px;
  display: flex;
  flex-direction: column;
  overflow: hidden auto;
  @include customScrollbar;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
      * {
        pointer-events: none;
      }
  
    }

  .queryFieldsContainer {
    display: flex;
    flex-direction: column;
    width: calc(100% - 8rem);
    margin: 0 4rem;
    background-color: $findest-background-03;
    border-radius: 8px;
    padding: 8px 16px 8px 8px;

    .queryFieldsTitle {
      display: flex;

      .querySearchTermsTitle {
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: 0;
        padding-top: 4px;
      }
    }

    .queryFieldOptions {
      display: flex;
      flex-direction: row;
      width: 100%;
      padding-left: 2rem;
      gap: 1rem;

      &.displayNone {
        display: none;
      }

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
        * {
          pointer-events: none;
        }
      }

      textarea {
        flex: 2 1;
        margin-top: 10px;
        resize: none;
      }

      .querySearchTermsContainer {
        flex: 2;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .querySearchTerms {
          overflow-y: auto;
          overflow-x: hidden;
          @include customScrollbar;
        }

        .searchPriorityStyleSelection {
          margin-top: 4px;
          width: 80px;
          height: 24px;
          div {
            height: 24px;
          }
        }
      }

      .queryFiltersContainer {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-top: -2rem;

        .querySearchFilters {
          overflow-y: auto;
          overflow-x: hidden;
          @include customScrollbar;
        }

        .querySearchTermsTitle {
          font-size: 1rem;
          font-weight: 600;
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .queryFieldsContainer {
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    @include customScrollbar;
    .querySearchTermsContainer,
    .queryFiltersContainer {
      width: 100%;
      .querySearchTerms,
      .querySearchFilters {
        overflow-y: hidden;
      }
    }
  }
}
