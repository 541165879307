.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .icon {
        margin-left: 5px;
    }

    .apiHealthCheckContainer, .hubHealthCheckContainer {
        margin: 5px;
    }

    .healthCheckResult {
        margin-top: 10px;
    }
}