@import 'Styles/colors.module.scss';

.fiveRatingStars {
    display: flex;
    align-items: center;
    .ratingStarContainer {
        padding: 0;
        &:hover {
            background-color: unset;
        }
    }
    .ratingStars {
        display: flex;
        align-items: center;
        &:after {
            font-family: "Font Awesome 6 Pro", sans-serif;
            content: "\f111";
            color: $notification-red;
            font-weight: 900;
            height: inherit;
            line-height: 12px;
            font-size: 12px;
            visibility: hidden;
            align-self: flex-start;
        }
        &.isRatingNeeded::after {
            visibility: visible;
        }
    }
}