@import "Styles/colors.module.scss";
@import 'Styles/customScrollbar.module.scss';

.divDropdown {
    position: relative;
    .divDropdownSelect {
        width: 100%;
        height: 2rem;
        border-radius: 6px;
        padding: 6px 16px;
        border: 1px solid $findest-gray;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        background-color: white;

        &:hover {
            border-color: $findest-dark-gray;
        }

        &.isReadOnly {
            cursor: default;
            &:hover {
                background-color: $light-background-color;
            }
        }
    
        :first-child {
            flex: 1;
        }
    
        svg {
            color: black;
            margin-left: 0.5rem;
            font-size: 12px;
        }

        .placeholder {
            color: $findest-dark-gray;
        }

        &.disabled {
            background-color: $findest-gray;
        }
        &.gray {
            background-color: $secondaryButtonColorDefault;
            border: none;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 500;
            letter-spacing: 1.246px;
        
            &:hover {
                background-color: $secondaryButtonColorHover;
            }
        }
    }
    
    .divDropdownList {
        @include customScrollbar;
        max-height: 296px;
        overflow-y: auto;
        overflow-x: hidden;
        position: fixed;
        background-color: white;
        margin-top: 0.25rem;
        border-radius: 4px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.26);
        padding: 4px 0;
        z-index: 2;
    
        div {
            div {
                padding: 6px 33px;
                overflow: hidden;
                display: flex;
                align-items: center;
                cursor: pointer;
            }
            .option {
                font-size: 16px;

                 &.selected, &:hover {
                    background-color: $light-background-color;
                }
            }
        }

        .groupTitle {
            color: $light-text-color;
            font-size: 10px;
            text-transform: uppercase;
            cursor: initial;
        }
    }
}