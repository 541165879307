@import "Styles/colors.module.scss";
@import 'Styles/customScrollbar.module.scss';

.sharingModal {
    display: flex;
    flex-direction: column;
    padding: 12px 32px 24px;
    .header {
        margin-bottom: 16px;
    }
    .emailInputContainer {
        position: relative;
        margin-bottom: 8px;

        &.step1 {
            margin-bottom: 16px;
        }

        form {
            display: flex;

            .addEmailContainer {
                display: flex;
                width: 100%;
            }

            .emailInput {
                border: none;
                background-color: $light-gray;
                height: 2.5rem;
            }

            .addEmailButton {
                margin-left: 1rem;
                height: 2.5rem;
            }
        }

        .suggestedEmailsPopup {
            overflow-y: auto;
            overflow-x: hidden;
            @include customScrollbar;

            .suggestedEmail {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 16px;
                .email {
                    font-size: 1rem;
                    line-height: 1.5;
                    letter-spacing: 0.144px;
                }
                .type {
                    color: $findest-dark-gray;
                    font-size: 0.75rem;
                    line-height: 1rem;
                    letter-spacing: 0.396px;
                }
                &:hover {
                    background-color: $highlightBackgroundHoverColor;
                    cursor: pointer;
                }
            }
        }
    }

    .users {
        display: flex;
        flex-direction: column;
        padding: 0 0 24px;
    }

    .peopleWithAccess {
        margin-bottom: 16px;
        h3 {
            margin-top: 16px;
            margin-bottom: 14px;
        }
    }
    .messageBox {
        border: none;
        background-color: $light-gray;
        min-height: 7rem;
        padding: 10px 16px;
        font-size: 1rem;
        line-height: 1.5rem;
        letter-spacing: 0.496px;
        margin-bottom: 24px;
        &::placeholder {
            font-style: normal;
        }
    }

    .sharingSettings {
        padding: 16px 0;
        color: $main-text-color;
        border-top: 1px solid $main-gray;
        h3 {
            margin-bottom: 6px;
        }
    }

    .footer {
        display: flex;
        > button {
            margin-right: 16px;
        }
    }

    .sharedWithUniverseAccounts {
        color: $main-black;
        margin-bottom: 32px;
        .topParagraph {
            margin-bottom: 10px;
        }
        .bottomParagraph {
            margin-top: 26px;
        }
    }
}