@import 'Styles/colors.module.scss';
@import 'Styles/customScrollbar.module.scss';

.referenceSidebarContainer {
    position: relative;
    display: flex;
    transition: 0.3s ease-in-out;
    overflow: hidden;
    height: 100%;
    flex-direction: column;

    &.isInReferenceModal {
        .filters {
            padding: 2px 16px 16px;
        }
    }

    .titleContainer {
        display: flex;
        align-items: center;
        min-height: 3rem;
        padding: 0 1rem;
        cursor: default;
        justify-content: space-between;
    }

    .searchBar {
        padding: 8px 16px;
    }

    .filters {
        padding: 4px 16px 16px 16px;

        .filtersContainer {
            gap: 8px;
            flex-wrap: wrap;

            .addFilterButtonContainer {
                .addFilterButton {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 1.25rem;
                    font-size: 0.75rem;
                    padding: 0 8px 0 6.3px;
                    width: 44px;
                    color: $primaryButtonColorDefault;
                    svg:first-of-type {
                        margin: 0 6.3px 0 0;
                        height: 12px;
                    }
                    svg:last-of-type {
                        width: 8px;
                        margin: 0 !important;
                    }
                }
                .groupedList:not(:first-of-type) {
                    margin-top: 12px;
                }
                .groupedListOption {
                    height: 32px;
                    &.selected { cursor: default; }
                }
                .groupTitle {
                    height: 20px;
                    padding: 3px 12px 3px;
                }
            }

            .filteredItem {
                height: 1.25rem;
                font-size: 0.75rem;
                text-transform: none;
                line-height: 1rem;
                padding: 0 8px;
                align-items: center;
                svg {
                    margin-left: 8px;
                }
            }
        }
    }

    .tabsContainer {
        display: flex;
        align-items: center;
        .linkIcon {
            color: $findest-dark-gray;
            padding: 0 10px;
            width: 12px;
        }
    }

    .referencesContainer {
        @include customScrollbar;
        overflow-y: auto;
        overflow-x: hidden;
        box-shadow: 6px 2px 6px $general-shadow-color;
        flex: 1;

        .isScrollingElement {
            position: sticky;
            top: -1px;
            width: 100%;
            height: 0px;
            transition: box-shadow 0.3s ease-in-out;
    
            &.isScrolling {
                box-shadow: 1px 1px 10px 4px #00000010;
            }
        }

        .documentReferenceContainer {
            margin-top: 1.5rem;
            position: relative;

            &:first-of-type {
                margin: 0;
            }

            &.isUsed {
                background-color: $light-gray;

                .documentReferenceTitle {
                    border-color: $light-gray;
                    &:hover, &.hover {
                        border-left-color: $light-gray;
                    }
                }
            }

            .documentReferenceTitle {
                cursor: default;
                display: flex;
                border: .5px solid white;
                border-left-width: 1px;
                position: relative;
                &:not(.hover) { 
                    border-left-color: $light-gray;
                }
                &:hover, &.hover {
                    border-color: $findest-blue;
                    border-left-color: white;

                    .addDocumenbtReference {
                        svg {
                            visibility: visible;
                        }
                    }
                    .referenceAction {
                        display: block;
                    }
                }
                
                .addDocumentReference {
                    min-width: 24px;
                    height: 24px;
                    justify-content: space-evenly;
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    cursor: pointer;
                    margin: auto 4px;
                    border-radius: 50%;
                    color: $findest-dark-gray;

                    svg {
                        width: 12px;
                        font-size: 14px;
                    }

                    &:hover {
                        background-color: $purple-4;
                        color: $findest-purple;
                    }

                    &.isLinked {
                        background-color: $findest-purple;
                        color: $white;

                        &:hover {
                            background-color: $violet-1;
                        }
                    }
                }

                p {
                    padding: 8px 1rem 4px 0;
                    display: -webkit-box;
                    line-clamp: 3;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    letter-spacing: 0.144px;
                    font-weight: 500;
                    text-align: justify;
                    word-break: break-word;
                    cursor: pointer;
                    
                    &:hover {
                        text-decoration: underline;
                    }
                }

                .referenceAction {
                    display: none;
                    cursor: pointer;
                    position: absolute;
                    right: 4px;
                    bottom: 2px;
                    box-shadow: -1px 1px 4px $actions-box-shadow-color;
                    border-radius: 4px;
                    background-color: white;
                    padding: 2px 6px;
                    svg {
                        width: 10px;
                        }
                }
            }

            .documentReferenceReferences {

                .reference {
                    display: flex;
                    padding: 0 16px 0 0;
                    cursor: default;
                    border: .5px solid white;
                    border-left-width: 1px;
                    position: relative;

                    &.isUsed {
                        background-color: $light-gray;

                        .addLinkContainer:after {
                            border-right: 2px solid $findest-dark-gray;
                        }
                    
                        &.hover {
                            border-left-color: $light-gray;
                        }
                    }
                    &:not(.hover).isUsed { 
                        border-color: $light-gray;
                    }
                    &:not(.hover) { 
                        border-left-color: $light-gray;
                    }
                    &.hover {
                        border-color: $findest-blue;   
                    }
                    &:not(.isUsed).hover {
                        border-left-color: white;
                    }

                    .addLinkContainer {
                        min-width: 32px;
                        justify-content: space-evenly;
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        margin-right: 8px;
                        position: relative;

                        .addReference {
                            width: 24px;
                            height: 100%;
                            justify-content: center;
                            align-items: center;
                            display: flex;
                            font-size: 14px;
                            color: $findest-blue;
                            cursor: pointer;

                            &:hover {
                                background-color: $secondaryButtonColorHoverLight;
                            }
                        }

                        svg {
                            width: 12px;
                            font-size: 14px;
                            color: $findest-dark-gray;
                            cursor: pointer;
                        }
                        &.isLinked {
                            svg:not(:hover) {
                                color: $main-purple;
                            }
                        }
                        &:after {
                            content: "";
                            border-right: 2px solid $findest-gray;
                            position: absolute;
                            right: 0;
                            top: 10px;
                            height: calc(100% - 20px);
                        }
                    }

                    .referenceHighlight {
                        padding: 12px 0;
                        width: 100%;
                        font-size: 0.875rem;

                        h4 {
                            display: -webkit-box;
                            line-clamp: 5;
                            -webkit-line-clamp: 5;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-align: justify;
                            margin-top: 4px;
                            word-break: break-word;
                        }
                        .commentsCountContainer {
                            float: right;
                            margin-top: 4px;
                            display: inline-flex;
                        }
                    }

                    .referenceImage {
                        padding: 12px 0;
                        width: 100%;
                        text-align: center;

                        img {
                            max-width: 100%;
                            object-fit: cover;
                        }
                        h5 {
                            display: -webkit-box;
                            line-clamp: 3;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-align: justify;
                            margin-top: 4px;
                        }
                    }
                }
            }
        }
    }
    
}
