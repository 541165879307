@import 'Styles/colors.module.scss';

// TODO (Gizem): delete this after sharing branch is merged.
.cancelButton {
    color: $primaryButtonColorDefault;
    background-color: white;
    margin-left: 16px;
    &:hover {
        background-color: $primaryButtonColorDisabled;
    }
}