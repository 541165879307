@import "Styles/colors.module.scss";

.chipContainer {
    display: flex;
    align-items: center;
    height: 32px;
    background-color: $secondaryButtonColorDefault;
    color: $main-text-color;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.246px;
    border-radius: 4px;
    text-transform: uppercase;
    white-space: nowrap;
    outline: 0;
    border: 0;
    margin-right: 1rem;
    cursor: pointer;

    &:hover {
        background-color: $secondaryButtonColorHover;
    }

    .title {
        margin: 0 16px 0 16px;
    }

    &.checked {
        background-color: $secondaryButtonColorSelected;
        color: white;

        .title {
            margin: 0 4px 0 16px;
        }
    }

    .iconContainer {
        height: 16px;
        width: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 6px 0 0;
    }
}