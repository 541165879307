@import "Styles/colors.module.scss";

.goBackContainer {
    display: flex;
    width: 100%;
    height: 3rem;
    align-items: center;
    color: $login-go-back-color;

    .goBackContentContainer {
        height: 40px;
        width: 100%;
        border-radius: 4px;
        display: flex;
        align-items: center;
        font-size: 1em;
        cursor: pointer;

        &:hover {
            background-color: $sidebar-hover-background-color;
        }
    }

    .iconContainer {
        height: 100%;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    > svg {
        font-size: 1rem;
        color: $login-go-back-arrow-color;
        cursor: pointer;
    }
}