@import "Styles/colors.module.scss";

.navigationWindowsContainer {
    display: flex;
    column-gap: 4px;

    .searchWindowPreviewsPopover {
        width: 500px;

        .searchWindowPreviewItem {
            height: 32px;
            padding: 0 16px;
            cursor: pointer;
            display: flex;
            align-items: center;

            &:hover {
                background-color: $light-gray;
            }
        }
    }
}