@import 'Styles/colors.module.scss';
@import 'Styles/customScrollbar.module.scss';

.editingWisiwygBottomContainer {
    padding-bottom: 0;
}

.editingButtonContainer {
    display: flex;
    align-items: flex-end;
    gap: 8px;

    .button {
        border-radius: 4px;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 1.246px;
        height: 32px;
        padding: 0 16px;
        border: none;
        text-transform: uppercase;
        cursor: pointer;
    }
    .save {
        background-color: $primaryButtonColorDefault;
        color: white;
        margin-right: 16px;
        &:hover {
            background-color: $primaryButtonColorHover;
        }
    }
    .cancel {
        color: $primaryButtonColorDefault;
        background-color: white;
        &:hover {
            background-color: $primaryButtonColorDisabled;
        }
    }
}

.proseMirrorEditor {
    box-shadow: -1px 1px 12px $buttonShadowColor;
    padding: 10px 10px 0;
    border-radius: 4px;
    margin-bottom: 16px;
}

.wysiwygContent {
    @include customScrollbar;
    padding: 16px;
    max-height: 140px;
    overflow: auto;
}

.wysiwygContentBordered {
    @include customScrollbar;
    padding: 12px 16px;
    min-height: 98px;
    max-height: 140px;
    overflow: auto;
    border-radius: 4px;
    border: 1px solid $findest-gray;
}

.isViewMode {
    display: flex;
    align-items: center;
    margin-right: 2px;
    .editModeButton {
        min-width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background-color: $light-gray;
        color: $findest-dark-gray;
        margin-left: auto;
        cursor: pointer;
        border: none;
    }
}
