@import 'Styles/colors.module.scss';
@import "Styles/variables.module.scss";

.editorMenu {
  font-family: $font-primary, $font-secondary, $font-tertiary;
  display: flex;
  height: 32px;
  padding: 0 6px;
  border: 1px solid $light-gray;
  border-radius: 8px;
  background-color: white;
  align-items: center;
}

.editorSpacer {
  background-color: $light-gray;
  height: 18px;
  margin: 5px 8px;
  width: 1px;
}

.editorMenuCommands {
  display: flex;
  align-items: center;
}

.editorMenuCommand {
  font-family: $font-primary, $font-secondary, $font-tertiary;
  padding: $spacing-6;
  border-radius: $spacing-4;
  border: none;
  color: $main-text-color;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  background-color: white;
  text-align: left;
  gap: 9px;
  display: flex;
  align-items: center;
  text-wrap: nowrap;

  &:hover {
    background: $blue-100-50;
  }

  &.selected {
    background: $blue-100-50;
  }

  &.danger {
    color: $red-600;

    svg {
      color: $red-400;
    }
  }

  .iconContainer {
    width: 12px;
    height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      font-size: .75rem;
    }
  }

  .chevronIcon {
    width: 8px;
  }

  .rightSide {
    margin-left: auto;

    .shortcut {
      font-family: $font-primary, $font-secondary, $font-tertiary;
      font-size: $font-size-s;
      font-weight: $font-weight-700;
      color: $findest-gray;
      padding-left: $spacing-8;
      line-height: 15.6px;
      letter-spacing: 0.14em;
      margin-left: auto;
    }

    .checkmark {
      color: $main-text-color;
    }
  }

  &.isActive {
    background: $blue-100;

    &.isFirstCommand {
      border-radius: $border-radius-4 0 0 $border-radius-4;
    }

    &.isLastCommand {
      border-radius: 0 $border-radius-4 $border-radius-4 0;
    }

    &.isCommandBetweenActiveCommands {
      border-radius: 0;
    }
  }

  .customIconAsHtmlDiv {
    font-family: $font-quaternary;

    span:nth-child(1) {
      font-size: $font-size-m;
      font-weight: $font-weight-500;
      line-height: 18.2px;
    }

    span:nth-child(2) {
      font-size: $font-size-xs;
      font-weight: $font-weight-700;
      line-height: 13px;
    }
  }

  .editorMenuLinkCommand {
    width: 12px;
  }

  &.advancedCommand {
    padding: 4px 6px;
    .iconContainer {
      width: 16px;
      height: 16px;

      svg {
        font-size: 1rem;
      }
    }
  }
}

.editorMenuGroupSeperator {
  height: 1px;
  background-color: $light-gray;
  margin: 8px;
}

.editorMenuGroup {
  padding: 0 8px;
  display: flex;
  flex-direction: column;

  .editorMenuGroupLabel {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: $findest-dark-gray;
    margin-bottom: 8px;
  }
}

.editorMenuGroupCommand {
  width: 100%;
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 450;
  line-height: 18.2px;

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }

  &.isActive {
    background: white;
  }

  &:hover {
    background: $blue-100-50;
  }
}



.editorMenuFormatCommand {
  margin-right: 12px;
  padding: 3px 6px;
}


.editorMenuAiToolsCommand {
  color: $purple-2;
  padding: 3px 6px;
  .askIgorAiButton {
    display: flex;

    sup {
      font-size: 10px;
    }
  }
}

.editorMenuTableCommand {
  padding: 5px 6px;
}

.editorMenuItemPanel {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: white;
  justify-content: space-between;
  min-width: 250px;

  .line {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $light-gray;
    margin-top: 16px;
    padding-top: 16px;
  }
}

.bubbleMenuContainer {
  max-width: unset !important;
  border-radius: 8px;
  border: 1px solid $light-gray;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.051);
  overflow: hidden;
  background-color: white;
}