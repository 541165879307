@import 'Styles/colors.module.scss';

.editorMenu {
    padding: 0 0 0 0.25rem;
    background-color: $light-gray;
    border-radius: 0.25rem;
    position: relative;
    display: flex;

    &.disabled {
        display: none;
    }

    &.paddingLeftRight {
        padding: 0 0.25rem;
    }

    .menuButton {
        border: none;
        font-size: 14px;
        font-weight: 500;
        height: 32px;
        width: 32px;
        color: $findest-dark-gray;
        transition: 0.3s ease-in-out;
        cursor: pointer;

        &:not(.headerFormatOption) {
            background-color: $light-gray;
        }

        &:hover {
            background-color: $findest-background-05;
            color: $findest-text;
        }

        &.active {
            background-color: $secondaryButtonColorHover;
            color: $findest-blue;
        }

        &.disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }

        span {
            font-size: 10px;
            font-weight: 600;
            margin-left: 1px;
        }

        &.headerFormatButton {
            display: flex;
            align-items: center;
            width: auto;
            padding: 0 8px;

            span {
                font-size: 14px;
                font-weight: 500;
                height: 13px;
            }
        }

        &.headerFormatOption {
            padding: 8px 12px;
            height: auto;
            color: $findest-text;
            width: auto;

            &.active {
                color: white;
                background-color: $findest-light-blue;
            }
        }

        &.headerOne {
            font-size: 28px;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: 0.15px;
        }

        &.headerTwo {
            font-size: 22px;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: 0.15px; 
        }

        &.headerThree {
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0.144px;
        }
        .fileInputButtonText {
            display: none;
        }
    }

    .editorSpacer {
        width: 1px;
        background-color: $findest-gray;
        height: 20px;
        margin: 5px 4px;
    }

    .popoverButtonContainer {
        position: relative;
    }

    .popoverOptionMenu {
        top: 34px;
        right: 0;
        min-width: 80px !important;
        margin: 0;
        overflow: visible;
        
        &:before {
            content: " ";
            display: block;
            position: absolute;
            height: 4px;
            top: -4px;
            left: 0;
            right: 0;
        }

        &:after {
            content: " ";
            display: block;
            position: absolute;
            height: 4px;
            bottom: -4px;
            left: 0;
            right: 0;
        }

        .editorSpacerHorizontal {
            height: 1px;
            background-color: $light-gray;
            width: calc(100% - 12px);
            margin: 2px 6px;
        }
        
        &Item {
            padding: 0.5rem 1rem 0.5rem 0.4rem;
            display: flex;
            align-items: center;
            cursor: pointer;
            &:hover {
                background-color: $findest-background-05;
            }
            &.active {
                background-color: $findest-light-blue;
                color: white;
            }
    
            &.disabled {
                cursor: not-allowed;
                opacity: 0.5;
            }
            &Icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 26px;
            }

            &.deleteTableOption {
                color: $main-light-red;
            }
        }

        .tablePropertiesMenu {
            padding: 16px;
            .xMark, .text {
                padding: 0 6px;
            }
            .footer {
                margin-top: 20px;
            }
        }
    }
}