@import "Styles/colors.module.scss";

.highlightContainer {
  border: 1px solid $main-gray;
  border-radius: 0.25rem;
  margin-bottom: 0.75rem;
  position: relative;

  &:last-of-type {
    margin-bottom: 0;
  }

  .highlight {
    padding: 4px 16px;
    .highlightTextContainer {
      .highlightText {
        color: $main-text-color;
        line-height: 24px;
        letter-spacing: 0.496px;
        display: -webkit-box;
        line-clamp: 3;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;

        &.unclampText {
          line-clamp: unset;
          -webkit-line-clamp: unset;
          overflow: visible;
        }
      }
      .readMore {
        color: $findest-blue;
        cursor: pointer;
        font-size: 14px;
        display: inline;
        margin-right: 6px;
        svg {
          margin-left: 4px;
          font-size: 12px;
        }
      }

      .editButton {
        display: none;
        visibility: hidden;
      }

      &:hover {
        .editButton {
          visibility: visible;
        }
      }

      > a {
        font-size: 14px;
      }

      .buttonSection {
        display: flex;
        justify-content: space-between;
        padding: 4px;
      }
    }

    .highlightTypeSelectionDropdown {
      width: max-content;
      .classNameSelect {
        padding: unset;
        border: none;
        height: unset;
        color: $findest-dark-gray;
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 1.5px;
        line-height: 16px;
        text-transform: uppercase;
        svg {
          width: 8px;
          color: $findest-dark-gray;
        }
      }
    }

    .highlightAnnotationActions {
      display: none;
    }

    &:hover {
      .highlightAnnotationActions {
        display: flex;
      }
    }
  }
}
