@import "Styles/colors.module.scss";

.dropdownButton {
    position: relative;
    margin-right: 1rem;
    flex-shrink: 0;

    .iconButton {
        border-radius: 4px;
        height: 2rem;
        margin: 0;
        border: none;
        display: flex;
        align-items: center;
        padding: 0 12px;
        cursor: pointer;
        color: white;

        &.disabled {
            opacity: .15;
            cursor: not-allowed;
        }

        &.iconLeft {
            padding: 0 12px 0 4px;
        }

        &.iconRight {
            padding: 0 4px 0 12px;
        }
    }

    .buttonText {
        margin: 0 4px;
        font-family: 'IBM Plex Sans', sans-serif;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 1.246px;
    }

    .fileInputButtonContainer {
        padding: 0;
        .fileInputButton {
            background-color: white;
            width: 100%;
            justify-content: flex-start;
            padding: 0 16px 0 8px;
            transition: none;
            white-space: nowrap;
            &:hover {
                background-color: $main-gray;
            }
        }
    }

    .optionsContainer {
        position: absolute;
        box-shadow: -1px 1px 10px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        background: white;
        padding: 4px 0;
        margin-top: 4px;
        z-index: 4;
        min-width: 100%;
        right: 0;

        &.hidden {
            display: none;
        }

        &:before {
            content: " ";
            display: block;
            position: absolute;
            height: 4px;
            top: -4px;
            left: 0;
            right: 0;
        }

        > div {
            height: 32px;
            display: flex;
            align-items: center;
            font-family: 'IBM Plex Sans', sans-serif;
            font-size: 16px;
            letter-spacing: 0.144px;
            cursor: pointer;
            white-space: nowrap;

            .iconContainer {
                margin-right: 4px;
            }

            &:not(.fileInputButtonContainer) {
                padding: 0 16px 0 8px;
            }

            &.hasOnlyText {
                padding: 0 16px;
            }
            
            &:hover {
                background-color: $main-gray;
            }
        }
    }
}

.iconContainer {
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

.hidden {
    display: none;
}

.optionsPopover {
    z-index: 10000 !important;
    max-width: unset !important;
    overflow: visible !important;

    &:before {
        content: " ";
        display: block;
        position: absolute;
        height: 4px;
        top: -4px;
        left: 0;
        right: 0;
    }

    .optionsContainer {
         background: white;
         min-width: 100%;
         right: 0;
 
        > div {
            height: 32px;
            display: flex;
            align-items: center;
            font-family: 'IBM Plex Sans', sans-serif;
            font-size: 16px;
            letter-spacing: 0.144px;
            cursor: pointer;
            white-space: nowrap;
 
            .iconContainer {
                margin-right: 4px;
            }

            &:not(.fileInputButtonContainer) {
                padding: 0 16px 0 8px;
            }

            &.hasOnlyText {
                padding: 0 16px;
            }
             
            &:hover {
                background-color: $main-gray;
            }
        }
     }

}

.deleteOption {
    color: $main-light-red;
    margin-top: 8px;
    position: relative;
    &:before {
        content: " ";
        display: block;
        position: absolute;
        border-top: 1px solid $light-gray;
        height: 4px;
        top: -4px;
        left: 0;
        right: 0;
    }
}