@import 'Styles/customScrollbar.module.scss';

.tenants {
    padding: 32px 64px;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    overflow: auto;

    .tenantsTitleHeader {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;

        .tenantCreation {
            display: flex;
        }
    }

    .tenantsTableContainer {
        overflow: auto;
        flex: 1;
        @include customScrollbar;
        table {
            margin: 0;
            thead {
                tr {
                    position: sticky;
                    top: 0;
                    z-index: 1;
                }
            }
            tbody {
                tr {
                    cursor: pointer;
                }
            }

            th, td {
                max-width: 700px;
                overflow-wrap: break-word;
            }

        }
    }
}