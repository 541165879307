@import './table.module.scss';
@import './colors.module.scss';

.markdownContainer {
    &:not([class*="editorContent"]) {
        * {
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    [class*="empty-node"] {
        width: 100%;
        height: 72px;
        margin-bottom: 0;
    }

    [class*="loading-indicator"] {
        z-index: 2147483647;
        background-color: rgba(242, 244, 248, 0.9);
        width: 100%;
        height: 100%;
        position: absolute;
    }

    // override ol, ul and li elements global styles set in typography.module.scss to make lists working in the wysiwyg editor
    // overriden with google chrome user agent stylesheet
    ol, ul {
        display: block;
        list-style-type: decimal;
        list-style-position: outside;
        list-style-image: none;
        margin-block-start: 16px;
        margin-block-end: 24px;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 32px;

        ol ,ul {
            margin-block-start: 0px;
            margin-block-end: 0px;
        }

        li {
            list-style-position: outside;
            list-style-image: none;
            display: list-item;
            text-align: -webkit-match-parent;
            margin-top: 4px;
        }

        p {
            margin: 0;
        }
    }

    ol {
        list-style-type: decimal;

        li {
            list-style-type: decimal;
        }
    }

    ul {
        list-style: disc;

        li {
            list-style-type: disc;
        }
    }

    p {
        margin: 8px 0 16px 0;
    }

    h1 {
        font-size: 28px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0.15px;
        margin: 56px 0 0;
        padding: 0;
        text-align: initial;
    }

    h2 {
        font-size: 22px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.15px;
        margin: 32px 0 0;
        padding: 0;
        text-align: initial;
    }

    h3 {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.144px;
        margin: 28px 0 0 0 !important;
        padding: 0;
        text-align: initial;
    }
}