@import "Styles/colors.module.scss";
@import "Styles/customScrollbar.module.scss";

.ratingsPopover {
    background-color: white;
    z-index: 1;
    &:not(.inPopover) {
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 316px;
        @include customScrollbar;
    }

    .ratingsPopoverTop {
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $findest-background-03;
        .averageRatingCount {
            margin-top: 8px;
        }
    }

    .ratingContainer {
        position: relative;
        .rating {
            display: flex;
            padding: 8px 16px;
            &.isCurrentUser {
                padding: 16px;
                height: 79px;
                border-bottom: .5px solid $light-gray-4;
            }
            .userDetailsContainer {
                display: flex;
                flex-direction: column;
                margin-right: 8px;
                align-items: center;
            }
        }
        .clearRatingContainer {
            display: inline-block;
            position: absolute;
            bottom: 1px;
            right: 50px;
            font-size: 12px;
            font-weight: 500;
            height: 18px;
            border-radius: 4px;
            padding: 0 8px;
            line-height: 18px;
            letter-spacing: 0.396px;
            color: $main-text-color;
            border: none;
            background-color: white;
            cursor: pointer;
            svg {
                margin-right: 4.8px;
            }
            &:hover {
                background-color: $findest-background-03;
            }
        }
    }
}