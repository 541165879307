@import 'Styles/customScrollbar.module.scss';
@import 'Styles/colors.module.scss';

.explorerMenu {
    .explorerMenuHeaderContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 16px 18px 0;
        .menuHeader {
            margin-left: 32px;
        }

        .menuActions {
            display: flex;
            align-items: center;
            .listActionButton {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: transparent;
                border-radius: 4px;
                border: none;
                width: 24px;
                height: 24px;
                cursor: pointer;
                color: $white;
                &:hover {
                    background-color: $white-25;
                }
                &:not(:last-of-type) {
                    margin-right: 4px;
                }
                &.disabled {
                    cursor: default;
                    .menuHeaderIcon {
                        opacity: 0.25;
                    }
                }
            }
        }
    }

    width: calc(100% - 40px);
    color: white;
    margin-top: 32px;

    .scrollableParent {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: calc(100% - 42px);
        @include customScrollbar;
    }
}
