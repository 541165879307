@import "Styles/colors.module.scss";

.maintenanceBanner {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: $findest-warning-background-yellow;
  color: $main-black;
  font-weight: 700;
  font-size: 0.825rem;
  line-height: 20px;
  padding: 8px 16px;
  text-align: left;
  align-items: center;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $findest-warning-yellow;

  .iconWrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    color: $findest-warning-yellow;
    background-color: $findest-warning-yellow;
  }

  .maintenanceBannerInformationSection {
    display: flex;
    align-items: center;

    .maintenanceText {
      font-size: 0.825rem;
      line-height: 20px;
      color: $main-black;
    }
  }

  .bannerRight {
    display: flex;
    align-items: center;
    flex-direction: row;

    justify-content: space-between;

    .moreInformation {
      color: $findest-blue;
      font-size: 0.825rem;
      font-weight: 700;
      line-height: 20px;
      cursor: pointer;
      text-decoration: underline;
      padding: 16px;
    }

    .dismissButton {
      background-color: unset;
      width: 32px;
      height: 32px;
      color: $main-black;
    }
  }

  .maintenanceBannerInformationSection > * {
    margin-right: 10px;
  }
}
