@import 'Styles/colors.module.scss';

.referencedBy {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    padding: 0 4rem 1rem;
    .referencedByCounter {
        cursor: pointer;
        text-transform: uppercase;
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1rem;
        letter-spacing: 1.246px;
        color: $findest-dark-gray;
        svg {
            width: 0.656rem;
            margin-right: 8px;
        }
        &.isReferencedByListOpen {
           padding-bottom: .5rem;
        }
    }
    .referencedByItems {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        
        .referencedByItem {
            cursor: pointer;
            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }
    }
}
