@import 'Styles/colors.module.scss';

.configureAuthenticator {
  color: $main-black;
  .configureAuthenticatorAppHeader {
    padding: 16px 0;
    font-size: 1.25rem;
  }
  .backButton {
    margin: 8px 0;
  }

  .link {
    color: $findest-blue;
  }

  ol {
    list-style-type: decimal;
    margin-left: 20px;

    li {
      list-style-type: decimal;
      margin-top: 24px;
    }

    ul {
      li {
        margin-left: 20px;
        list-style-type: disc;
        margin-top: 0;
      }
    }
  }

  .codeSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px 0 4px;
    max-height: 182px;
    margin-top: 24px;
    gap: 16px;

    .formattedKeySection {
      color: $main-black;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      background-color: $light-gray;
      padding: 4px;
      border-radius: 4px;
      text-align: center;
    }
  }

  .verificationCodeSection {
    margin-top: 24px;

    .verificationCodeInput {
      max-width: 226px;
      height: 40px;
      margin: 16px 0;
    }
  }
}
