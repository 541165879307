@import 'Styles/customScrollbar.module.scss';

.imageModal {
    inset: 40px;
    overflow: auto;
    padding: 1rem;
    transform: none;
    max-width: unset;
    max-height: unset;
    width: unset;
    @include customScrollbar;

    .body {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: calc(100% - 41px);
        width: 100%;

        .entityLikeImageAspectRatioBox {
            height: 0;
            overflow: hidden;
            padding-bottom: 56.25%;
            position: relative;
            width: 100%;

            .entityLikeImageAspectRatioBoxContent {
                display: flex;
                height: 100%;
                justify-content: center;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;

                .image {
                    max-height: 100%;
                    max-width: 100%;
                    object-fit: contain;
                }
            }
        }

        .information {
            margin: 16px 0;
            h5 {
                margin-bottom: 8px;
            }
        }
    }
}