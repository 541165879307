@import 'Styles/customScrollbar.module.scss';

.fileInput {
  display: none;
} 

.ratingPopover {
  .innerContent {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 316px;
    min-width: 219px;
    @include customScrollbar;
  }
}

.referenceCard {
  max-height: 282px !important;
  min-width: 219px !important;
}
