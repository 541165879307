@import "Styles/colors.module.scss";

.editableMarkdownContainer {
    width: 100%;
    cursor: text;
    word-break: break-word;
    text-align: justify;

    &:hover {
        .changeContentButton {
            opacity: 1;
        }
    }

    &.isInEditMode {
        min-height: 4.5rem;
        .changeContentButton {
            opacity: 1;
        }
    }

    .changeContentButton {
        float: right;
        border: none;
        background-color: transparent;
        line-height: 22px;
        border-radius: 0.25rem;
        color: #757575;
        cursor: pointer;
        opacity: 1;
        position: relative;
        padding: 1px 6px;
        margin-left: 0.25rem;
    
        &:hover {
            background-color: $light-background-color;
    
            &.edit {
                color: $findest-purple;
            }
        
            &.save {
                color: $findest-purple;
            }
        }

        .slash {
            position: absolute;
            top: 4px;
            left: 4px;
        }

        .slashBottom {
            position: absolute;
            top: 6px;
            left: 4px;
            color: white;
        }
    }
}