@import 'Styles/colors.module.scss';

.numericStepper {
    display: flex;
    .box {
        min-width: 32px;
        height: 32px;
        align-items: center;
        justify-content: center;
        display: flex;
        border: 1px solid $findest-gray;
        border-radius: 4px;
        &.left {
            border-right: none;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            color: $findest-dark-gray;
        }
        &.middle {
            border-radius: 0;
            background-color: $findest-light-blue;
            color: white;
            font-weight: 500;
        }
        &.right {
            border-left: none;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            color: $findest-dark-gray;
        }
        &.clickable {
            cursor: pointer;
        }
    }
}