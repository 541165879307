@import 'Styles/Legacy/colors.module.scss';

.searchPrioritySelect {
    outline: none;
    border-radius: 0.25rem;
    border: 1px solid $findest-gray;
    cursor: pointer;
    font-size: 12px;
    height: 24px;

    &.must {
        border-color: $findest-blue;
    }

    &.should {
        border-color: $findest-yellow;
    }

    &.excluded {
        border-color: $findest-red;
    }

    &.disabled {
        visibility: hidden;
    }
}

.searchPrioritySelectContainer {
    display: flex;
    flex-direction: row;
    column-gap: 2px;
    cursor: pointer;

    .searchPrioritySelectButton {
        height: 24px;
        width: 24px;
        border-radius: 12px;
        color: white;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;

        &.must {
            background-color: $findest-blue;
        }
    
        &.should {
            background-color: $findest-yellow;
        }
    
        &.excluded {
            background-color: $findest-red;
        }

        &.inActive {
            background-color: $light-background-color;
            color: $findest-gray;

            &:hover {
                color: $findest-text;
            }
        }
    }
}