@import 'Styles/colors.module.scss';

.optionContainer {
    margin-top: 32px;
    .switchButtonsContainer {
        display: flex;
        height: 32px;
        width: 96px;
        button {
            transition: background-color .2s linear;
            text-transform: none;
            width: 47px;
            border: 1px solid $findest-gray;
            justify-content: center;
        }
        .yesButton {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: none;
        }
        .noButton {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: none;
        }
        .active {
            cursor: default;
            &:hover {
                background-color: $findest-blue;
            }
        }
        .inactive {
            background-color: white;
            color: $findest-dark-gray;
        }
    }
    .optionTitle {
        margin-bottom: 4px;
    }
}