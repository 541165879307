@import 'Styles/colors.module.scss';

.tabs {
    display: flex;
    padding: 0 32px;
    height: 40px;
    position: relative;

    .tab {
        display: flex;
        position: relative;
        flex: 1 1 auto;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 6px;
        z-index: 1;
        height: 40px;
        color: $findest-text;
        cursor: pointer;

        .tabIcon {
            font-size: 12px;
            height: 20px;
            display: flex;
            align-items: center;
            margin-right: 6px;
        }

        &:hover {
            &:after {
                content: "";
                width: 100%;
                border-bottom: 2px solid $findest-gray;
                left: 0;
                position: absolute;
                bottom: 0.5px;
            }
        }

        &.selected {
            h4 {
                font-weight: 500;
            }
            
            &:after {
                content: "";
                width: 100%;
                border-bottom: 2px solid $navy-blue;
                left: 0;
                position: absolute;
                bottom: 0.5px;
            }
        }

        &.disabled {
            color: $findest-gray;
            cursor: not-allowed;
            
            h4 {
                font-weight: 400;
                white-space: nowrap;
            }

            &:after {
                border-bottom: none;
            }
        }
    }
    &:after {
        content: "";
        width: 100%;
        border-bottom: 1px solid $light-gray-4;
        left: 0;
        position: absolute;
        bottom: 0.5px;
    }

    // THEMES
    &.compact {
        padding: 0;

        .tab {
            flex: none;
            padding: 0 16px 6px 16px;
        }
    }

    &.blue {
        padding: 0;
        justify-content: flex-start;
        border-bottom: 1px solid $findest-gray;

        .tab {
            flex: none;
            padding: 8px 12px;

            h4 {
                font-weight: 400;
                margin-bottom: 4px;
            }

            &::after {
                border: 2px solid $findest-blue;
            }

            &.selected {
                background-color: $blue-100;
                h4 {
                    font-weight: 600;
                }
            }
        }
    }
}