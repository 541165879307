@import "Styles/colors.module.scss";
@import 'Styles/customScrollbar.module.scss';

.createButton {
    > button {
        background-color: $primaryButtonColorDefault;

        &:hover {
            background-color: $primaryButtonColorHover;
        }
    }
}

.objectsContainer {
    height: 44px;
    position: relative;

    .objectsSecondContainer {
        position: absolute;
        background-color: $light-gray;
        border-radius: 4px;
        width: 100%;
        z-index: 2;
        height: 40px;
        overflow: hidden;
        @include customScrollbar;
        &:hover {
            max-height: 250px;
            height: auto;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
    .objectItem {
        cursor: default;
        &:not(:first-child) {
            border-top: 0.6px solid $findest-gray;
        }
    }
}

.tabsContainer {
  margin: 24px 0 32px 0;
  padding: 0 !important;
  .tab[aria-pressed="true"] {
    background-color: $light-gray;
  }
}

.dropdownGroupTitle {
    display: none !important;
}

.saveButton {
    margin-right: 16px;
}

.otherSelectedObjectsCount {
    margin-left: auto;
    font-size: 12px;
    border-radius: 50%;
    text-align: center;
    color: $main-text-color;
    font-weight: 500;
    padding: 0 4px;
    flex-shrink: 0;
    
    svg {
        margin-left: 4px;
    }
}
