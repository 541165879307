@import 'Styles/colors.module.scss';

.suggestingTextbox {
    width: 100%;
    position: relative;
    .suggestionPopup {
        position: absolute;
        width: 284px;
        padding: .5rem 0;
        background: white;
        max-height: 30vh;
        overflow-y: auto;
        z-index: 2;
    
        .suggestionTitle {
            color: $light-text-color;
            font-size: 10px;
            text-transform: uppercase;
            line-height: 16px;
            letter-spacing: 1.5px;
            padding: 8px 32px 2px;
        }
    }

    .suggestedSuggestion {
        display: flex;
        align-items: center;
        padding: 10px 32px;
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;

        &:not(.selectedOption):hover {
            background-color: $main-gray;
        }
        &.selectedOption {
            border: 1px solid $findest-gray;
            border-radius: 4px;
            padding: 0 8px 0 0;
            cursor: text;
        }
    }
    .hidden {
        width: 0;
        height: 0;
        opacity:0;
    }
}