@import "Styles/colors.module.scss";

.windowBubble {
    position: relative;

    &.disabled {
        cursor: not-allowed;
        
        .iconBubble {
            opacity: 40%;
            pointer-events: none;
        }
    }

    &.active {
        .iconBubble {
            color: $toggleButtonBlue;
            background-color: $toggleButtonBlueShading;

            &:hover {
                background-color: $toggleButtonBlueShading;
            }
        }
    }

    ::before {
        content: "";
        position: absolute;
        left: -0.5rem;
        width: 0.5rem;
        height: 100%;
    }

    .iconBubble {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background-color: $light-gray;
        cursor: pointer;

        &:hover {
            background-color: $findest-gray;
        }
    }

    .windowCount {
        position: absolute;
        top: -6px;
        right: -6px;
        background-color: $findest-blue;
        width: 16px;
        height: 16px;
        color: white;
        border-radius: 10px;
        text-align: center;
        line-height: 16px;
        font-size: 12px;
        font-weight: 500;
        pointer-events: none;
    }
}