@use "Styles/colors.module.scss" as colors;

.warningContainer {
  display: flex;
  gap: 8px;
  max-width: 500px;
  padding: 8px 12px;
  background-color: colors.$blue-100-50;
  font-size: .875rem;
  line-height: 1.138rem;
  font-weight: 450;
  color: colors.$grey-600;
  border-radius: 4px;
  svg {
    margin-top: 4.5px;
  }
}
