@import 'Styles/colors.module.scss';
@import 'Styles/customScrollbar.module.scss';
@import "Styles/objectIcon.module.scss";
@import 'Styles/typography.module.scss';

.optionsContainer {
    width: 198px;
}

.optionsButton {
    > button {
        background-color: $primaryButtonColorDefault;

        &:hover {
            background-color: $primaryButtonColorHover;
        }
    }
    .optionText {
        text-transform: uppercase;
        font-size: 0.875rem;
        line-height: 1.5rem;
        font-weight: 500;
        letter-spacing: 1.246px;
    }

    .optionTextActions {
        letter-spacing: normal;
        font-weight: 400;
        text-transform: none;
        &::first-letter {
            text-transform: uppercase;
        }
    }
}

.entityLikeCard {
    @include customScrollbar;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    &.isInModal {
        padding: 16px 0 32px;
        .entityLikeCardHeaderContainer,
        .connectedObjectsContainer,
        .entityLikeCardContentContainer,
        .tabsContainer {
            max-width: unset;
        }
    }

    .isScrollingElement {
        position: sticky;
        top: -1px;
        width: 100%;
        height: 0px;
        transition: box-shadow 0.3s ease-in-out;

        &.isScrolling {
            box-shadow: 1px 1px 10px 4px #00000010;
        }
    }

    .entityLikeCardHeaderContainer {
        width: 100%;
        max-width: 1024px;
        margin: 0 auto;
        padding: 1rem 4rem;
        display: flex;
        flex-direction: column;

        &.hasBottomContent {
            padding: 1rem 4rem .5rem;
        }

        &TopContent {
            display: flex;
            align-items: center;

            .gotoUrlButton {
                color: $findest-text;
                background-color: $secondaryButtonColorDefault;

                &:hover {
                    background-color: $secondaryButtonColorHover;
                }
            }

            &Right {
                display: flex;
                margin-left: auto;
            }

            .objectStatusDropdown {
                height: 1.5rem;
                color: $main-text-color;
                padding: 8px;
                text-transform: none;
                .objectStatusIcon {
                    margin: 0 8px 0 0;
                    &.new {
                        color: $navy-blue;
                    }
                    &.active {
                        color: $cyan;
                    }
                    &.closed {
                        color: $main-light-green;
                    }
                }
        
                svg {
                    font-size: 8px;
                }
            }

            .objectTypeDropdown {
                color: $main-text-color;
                padding: 0 16px 0 0;
                margin-left: -8px;
                background-color: white;
                white-space: nowrap;
                .objectTypeIcon {
                    margin-right: 12px;
                    font-size: 24px;
                    @include objectIcon;
                }
                &:hover {
                    background-color: $light-background-color;
                }
            }
        }

        .entityLikeTitleContainer {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .ratingStarContainer {
                margin-left: 24px;
                position: relative;
                &:after {
                    content: " ";
                    display: block;
                    position: absolute;
                    height: 8px;
                    bottom: -8px;
                    left: 0;
                    right: 0;
                }
            }
            .maturityLevelContainer {
                margin-left: auto;
            }
        }
    }

    .connectedObjectsContainer {
        max-width: 1024px;
        width: 100%;
        margin: 0 auto;
        padding: 0 4rem;
        & + .objectSearchPopupContainer {
            width: 512px;
            padding: 0 0 4px 0;
            overflow: hidden;
            display: flex;
        }
    }

    .tabsContainer {
        width: 100%;
        max-width: 1024px;
        margin: 0 auto;
        padding: 8px 64px 0;
    }

    .entityLikeCardContentContainer {
        width: 100%;
        max-width: 1024px;
        margin: 1rem auto;
        display: flex;
        flex-direction: column;
        max-height: 100%;

        .informationContainer {
            display: flex;
            column-gap: 64px;
            align-items: flex-start;
            padding: 0 3rem;
            margin: 0 1rem 1rem;
    
            h3 {
                margin-bottom: 4px;
                user-select: none;
            }

            .leftContentContainer {
                display: flex;
                flex: 3 1;
                flex-direction: column;
            }
    
            .rightContentContainer {
                display: flex;
                flex: 1;
                flex-direction: column;

                > div:not(:last-of-type) {
                    margin-bottom: 16px;
                }
            }
        }

        .listContainer {
            display: flex;
            flex-direction: column;
            padding: 0 3rem;
            margin: 0 1rem;
        }

        .quickStartButtonsSection {
            padding: 0 4rem;
            margin-bottom: 12px;

            .quickStartDismissButton {
              visibility: hidden;
              position: absolute;
              width: 30px;
              height: 30px;
              top: 0;
              left: -30px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 4px;
              padding: 4px 6px;
              &:hover {
                background-color: $blue-100;
              }
              &.quickStartDismissButtonVisible {
                visibility: visible;
              }
            }

            .quickStartButtonsContainer {
              position: relative;
              display: inline-flex;
              flex-direction: column;
              padding: 4px;
              border-radius: 4px;
              border: 1px solid transparent;
              &:hover {
                border: 1px solid $light-gray;
              }
            }

            .line { 
                display: flex;
                gap: 12px;
                margin-bottom: 12px;
            }
            .askIgorButton {
                background-color: $lavender-100;
                color: $lavender-600;
                &:hover {
                    background-color: #E6E6FA;
                }
            }
        }
        
        .entityLikeCardColumnContainer {
            width: 50%;

            &.leftColumn {
                margin-right: 0.5rem;
            }
            &.rightColumn {
                margin-left: 1.5rem;
            }
            &.fullWidth {
                width: 100%;
                margin-right: 0;
                margin-left: 0;
            }
            &.hidden {
                display: none;
            }
        }

        .attachments {
            margin-bottom: 48px;
        }

        .entityLikeCardInformationContainer {
            display: flex;
            padding: 0 3rem;

            &.center {
                justify-content: center;
            }

            .conclusion {
                box-shadow: -1px 1px 6px $buttonShadowColor;
                border-radius: 4px;
                padding: 12px 16px 16px;
                margin: 2px 16px;
                text-align: justify;
                color: $main-text-color;
                width: 100%;
                .conclusionTitle {
                    margin-bottom: 2px;
                }
            }
            &.showEditorLoader {
                position: relative;
                justify-content: center;
                .editorLoader {
                    z-index: 1;
                    position: absolute;
                    top: 30%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                    max-width: 600px;
                    padding: 0 4rem;
                    color: $main-text-color;
                }
                &.loaderWithText {
                    .editorLoader {
                        top: -16px;
                    }
                }
                .entityDescriptionContainer {
                    pointer-events: none;
                    opacity: .2;
                }
            }
        }

        .entityDescriptionContainer {
            padding: 0 1rem;
            width: 100%;
    
            h3 {
                margin-bottom: 4px;
            }
        }

        .resultsTitle {
            padding: 0 3rem;
            margin-top: 56px;
            user-select: none;
        }

        .entityLikeCardEntityItemsContainer {
            padding: 0 3rem;
        }

        .generateDescriptionButton {
            background-color: white;
            color: $main-purple;
            margin: 0 4rem;
            border: 1px dashed $new-badge-background-color;
            position: relative;
            margin-bottom: 16px;
            align-self: flex-start;

            .newBadge {
                position: absolute;
                font-size: 0.75rem;
                background-color: $new-badge-background-color;
                color: white;
                left: -20px;
                top: -13px;
                border-radius: 4px;
                padding: 2px 4px;
            }

            &:hover {
                background-color: $main-purple;
                color: white;
            }
        }

        .entityLikeCardConnectedDocumentsContainer {
            padding: 0 1rem;
        }

        .entityLikeCardConnectedQueriesContainer, .entityLikeCardPageCommentsContainer {
            padding: 0 64px;
        }
    }
}

.entityLikeImagesContainer {
    display: flex;
    flex-direction: column;

    .entityLikeImageContentContainer {
        display: flex;
        width: 100%;

        .entityLikeImageContainer {
            position: relative;
            text-align: center;
            width: 100%;

            &:hover {
                .actions {
                    display: flex;
                }
            }

            .actions {
                display: none;
                position: absolute;
                right: -8px;
                top: -16px;
                box-shadow: -1px 1px 4px $actions-box-shadow-color;
                border-radius: 4px;
                background-color: white;
                padding: 0 4px;
                .action {
                    height: 32px;
                    width: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $findest-dark-gray;
                    cursor: pointer;
                    > svg {
                        height: 14px;
                    }
                    &:hover {
                        color: $main-text-color;
                        background-color: $main-gray;
                    }
                }
            }

            .entityLikeImageAspectRatioBox {
                height: 0;
                overflow: hidden;
                padding-bottom: 56.25%;
                position: relative;
                width: 100%;

                .entityLikeImageAspectRatioBoxContent {
                    display: flex;
                    height: 100%;
                    justify-content: center;
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 100%;

                    img {
                        max-height: 100%;
                        max-width: 100%;
                        object-fit: contain;
                    }
                }
            }
            .entityLikeImageCaption {
                margin-top: 0.5rem;
            }
        }
    }

    .entityLikeImageThumbnailsContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 1.5rem 0 1rem;
        
        .entityLikeImageThumbnailContainer {
            align-items: center;
            border: 1px solid #f2f2f2;
            border-radius: 4px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            margin: 0 0.5rem 1rem;
            max-height: 45px;
            max-width: 80px;
            min-height: 45px;
            min-width: 80px;
            position: relative;
            transition: .3s ease-in-out;

            &.selected, &:hover {
                box-shadow: -3px 3px 12px rgba(0,0,0,0.29);
                border-color: transparent;
            }
            
            .entityLikeImageThumbnail {
                max-height: 100%;
                max-width: 100%;
            }
            
            .entityLikeImageThumbnailVideoIcon {
                align-items: center;
                background: #fff;
                border-radius: 50%;
                display: flex;
                height: 1rem;
                justify-content: center;
                position: absolute;
                width: 1rem;

                svg {
                    color: $findest-red;
                    font-size: 1.5rem;
                }
            }
        }
    }
}
