@import "Styles/colors.module.scss";

.rightContentItem {
    &:not(:last-of-type) {
        margin-bottom: 16px;
    }
    &Title {
        padding-left: 16px;
        margin-bottom: 8px;
    }
}


.documentMetadataContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 1rem;
    border-radius: 4px;
    border: 1px solid $main-gray;

    .documentMetadata {
        margin-top: 24px;

        &:first-of-type {
            margin: 0;
        }

        h6 {
            color: $findest-dark-gray;
        }

        .authorship {
            margin-top: 16px;

            &:first-of-type {
                margin: 0;
            }
        }
    }
}