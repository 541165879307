@import "Styles/colors.module.scss";
@import 'Styles/customScrollbar.module.scss';

.sidebarListItem {
    display: flex;
    align-items: center;
    padding: 4px 32px;
    font-size: 14px;
    position: relative;
    flex-direction: column;


    &:not(.isCollapsed) {
        .listItem {
            height: 40px;
        }
    }

    .listItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $white;
        border-radius: 4px;
        width: 100%;
        height: 100%;
        cursor: pointer;
        text-decoration: none;

        &:hover:after {
            content: " ";
            display: block;
            position: absolute;
            height: 40px;
            width: 40px;
            top: 0px;
            right: 0;
        }

        &.active {
            background-color: $white;
            color: $main-text-color;
            font-weight: 500;
        }

        &:not(.active):hover, &:not(.active).hover {
            background-color: $sidebar-hover-background-color;
        }

        .listItemLeft {
            display: flex;
            align-items: center;
            overflow: hidden;
            .itemTitle {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .listItemRight {
            padding: 13px;
            font-size: 12px;
            font-weight: 500;
        }
    }

    .subItemsContainer {
        width: 100%;
        margin-top: 4px;
        .sidebarListItem {
            margin: 0;
            padding-right: 0;
        }
    }
    .sidebarListItemIconContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.5rem;
        height: 2.5rem;
        flex-shrink: 0;
        svg[data-icon="thumbtack"] {
            transform: rotate(30deg);
        }
    }

    &.isCollapsed {
        padding: 4px 0;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 48px;

        .listItem {
            width: 24px;
            height: 24px;
            .listItemLeft, .sidebarListItemIconContainer {
                width: 100%;
                height: 100%;
            }
            .sidebarListItemIconContainer {
                position: relative;
                .notificationIndicator {
                    border-radius: 50%;
                    position: absolute;
                    height: 8px;
                    width: 8px;
                    right: 2px;
                    top: 2px;
                    background-color: $notification-red;
                }
            }
        }
    }
}

.subMenuPopup {
    right: -162px;
    top: 0;
    color: $main-text-color;
    min-width: 0;
    width: 232px;
    overflow-y: auto;
    max-height: 460px !important;
    @include customScrollbar;

    &.wide {
        width: 320px;
    }
    
    .sidebarListItemIconContainer.colored {
        svg[data-icon="dice-d6"] {
            color: $entity-count-color;
        }
        svg[data-icon="book-open-reader"] {
            color: $study-count-color;
        }
        svg[data-icon="file"] {
            color: $document-count-color;
        }
    }

    .emptySubItemsContainer {
        padding: 1rem 2rem;
        font-style: italic;
        color: $main-text-color;
        line-height: 1.4;
    }

    .listSubItem {
        padding: 0;
    }
}

.badge {
    &.notification {
        background-color: $findest-blue;
        font-size: 0.75rem;
        color: white;
        border-radius: 4px;
        padding: 2px 4px;
        margin: 13px;
        font-weight: 500;
    }
}
