@import "Styles/colors.module.scss";

.maturityLevelsPopover {
    padding: 16px 32px 32px !important;
    .popoverTitle {
        margin-bottom: 8px;
        color: $main-text-color;
    }
    .maturityLevelsPopoverItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:not(:last-child) {
            margin-bottom: 10px;
        }
        .maturityLevel {
            margin-right: 16px;
        }
    }
}