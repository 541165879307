@import "Styles/Legacy/colors.module.scss";

.deletableTextbox {
    display: flex;
    justify-content: space-between;
    border: 1px solid $findest-gray;
    border-radius: 0.25rem;
    margin: 0.25rem 0;
    transition: 0.3s ease-in-out;
    font-size: 1rem;
    align-items: center;

    &:hover {
        border-color: $findest-dark-gray;
    }

    .inputField {
        width: 100%;
        border: 0;
        padding: 7px 8px;
        outline: none;
        background-color: transparent;
    }

    .deleteIcon {
        padding: 0.25rem 0.5rem;
        cursor: pointer;
        font-size: 14px;
        color: $findest-gray;

        &:hover {
            color: $findest-text;
        }
    }
}