@import "Styles/colors.module.scss";
@import 'Styles/customScrollbar.module.scss';

.linkedListContainer {
    overflow-y: auto;
    overflow-x: auto;
    height: 100%;
    padding: 24px 61px 24px 64px;
    @include customScrollbar;

    ul, .linkedListContent {
        display: flex;
        flex-direction: column;
    }

    .linkedListContent {
        padding-left: 3px;

        .focusedObjectItem {
            padding: 3px 0;

            .objectItem {
                cursor: default;
            }
        }
    }

    .noFocusContainer {
        width: 300px;
        margin: 0 auto;

        h6 {
            margin-bottom: 1rem;
        }

        .noFocusRecentActive {
            margin-right: 0;
            width: 100%;
        }
    }
}

.isDraggedOver {
    background-color: $findest-light-blue;
    border-radius: 4px;
}