@import 'Styles/colors.module.scss';

.addReferencePopover {
    min-width: 33px !important;
    max-width: 33px !important;
    box-shadow: none;
    padding: 0;
    border: .5px solid $findest-blue;
    border-radius: 4px 0 0 4px;
    border-right: none;
    z-index: 99999;
}

.addReference {
    height: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 14px;
    color: $findest-blue;
    cursor: pointer;
    &.onlyReference {
        height: 100%;
    }

    &:hover {
        background-color: $secondaryButtonColorHoverLight;
    }
}
