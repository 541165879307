@import "Styles/colors.module.scss";

.createCommentReply {
    position: relative;
    padding-top: 16px;
    margin-top: 16px;
    &:before {
        position: absolute;
        content: '';
        left: -16px;
        top: 0;
        border-top: 1px solid $light-background-color;
        width: calc(100% + 32px);
    }

    .replyTextarea {
        margin-bottom: 16px;
    }

    &Actions {
        display: flex;
        .button {
            border-radius: 4px;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 1.246px;
            height: 32px;
            padding: 0 16px;
            border: none;
            text-transform: uppercase;
            cursor: pointer;
        }
        .save {
            background-color: $primaryButtonColorDefault;
            color: white;
            box-shadow: -1px 3px 6px $buttonShadowColor;
            margin-right: 16px;
            &:hover {
                background-color: $primaryButtonColorHover;
            }
        }
        .cancel {
            color: $primaryButtonColorDefault;
            background-color: white;
            &:hover {
                background-color: $primaryButtonColorDisabled;
            }
        }
    }
}