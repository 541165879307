@import 'Styles/colors.module.scss';

.informationBox {
    padding: 16px;
    display: flex;
    font-size: 14px;
    color: $main-black;
    line-height: 1.4;

    .iconContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-right: 16px;
        min-width: 32px;
        height: 32px;
        background-color: $white;
    }

    .leftIcon {
        font-size: 1.2rem;
        width: 16px;
        align-self: center;
    }
    .title {
        font-weight: 500;
    }
    .actionContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 6px;
    }
    
    &.information {
        max-width: 560px;
        background-color: $light-gray;
        border-bottom: 1px solid $navy-blue;
        color: $navy-blue;
    }

    &.success {
        background-color: $green-100;
        border-bottom: 1px solid $green-500;
        .iconContainer {
            color: $green-400;
        }
    }

    &.warning {
        background-color: $blue-400;
        border-bottom: 1px solid $findest-blue;
        .iconContainer {
            background-color: $white;
            color: $main-black;
        }
        .contentContainer {
            color: $main-text-color;
        }
    }

    &.error {
        background-color: $main-gray-transparent;
        border: 1px solid $main-dark-red;
        color: $main-dark-red;
    }
}