@use "Styles/colors.module.scss" as colors;
@use "Styles/Mixins/windowActionButton.module.scss" as mixins;

.createQueryWizardModal {
  inset: 32px 89px !important;
  transform: unset !important;
  width: unset !important;
  padding-top: 24px !important;

  .closeButton {
    @include mixins.windowActionButton;
  }

  .modalContent {
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    align-items: center;
    justify-content: center;
    .modalBodyContent {
      max-width: 500px;
    }
    .userIcon {
      position: absolute;
      right: 88px;
      top: 24px;
    }
    .header {
      margin: 24px 0;
    }
    .queryTypeDropdownContainer {
      width: fit-content;
      .queryTypeDropdown {
        border-color: transparent;
        background-color: colors.$blue-100;
        &:hover {
          border-color: colors.$findest-blue;
        }
      }
    }

    .queryNameInput {
      margin: 24px 0;
    }
    .footer {
      display: flex;
      gap: 10px;
    }
  }

}
