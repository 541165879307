.highlightsGroup {
    margin-bottom: 2rem;

    &:last-of-type {
        margin-bottom: 0;
    }
    
    &Title {
        margin-bottom: .25rem;
        margin-left: 1rem;
    }
}