@import 'Styles/colors.module.scss';
@import 'Styles/customScrollbar.module.scss';

.users {
    padding: 16px 0 16px 64px;
    height: calc(100% - 40px);
    .usersHead {
        display: flex;
        padding: 16px 6px;
        .trashIcon {
            margin: 0 16px 0 8px;
            visibility: hidden;
            background-color: $main-light-red;
            border-radius: 4px;
            color: #fff;
            cursor: pointer;
            height: 14px;
            padding: 9px 10px;
            &.isVisible {
                visibility: visible;
            }
            &:hover {
                background-color: $main-dark-red;
            }
        }
        .isDisabled {
            opacity: .5;
            pointer-events: none;
        }
    }
}

.createUserModalContainer {
    width: 70%;
    max-width: 70%;
    max-height: 95%;
    min-height: 348px;
    overflow-x: hidden;
    overflow-y: auto;
    @include customScrollbar;

    .modalBody {
        min-height: 224px;
    }
    .userDetailsTableContainer {
        min-height: 192px;
        overflow: visible;
    }
    
    .addPeopleFormContainer {
        display: flex;
        .addPeopleContainer {
            display: flex;
            .emailInput {
                border: none;
                background-color: $light-gray;
                width: 320px;
            }
            .addEmailButton {
                margin-left: 16px;
            }
        }
    }

    .usersContainer {
        min-height: 144px;
    }
    .dataItem {
        padding-left: 0;
    }

    .modalFooter {
        display: flex;
        margin-top: 32px;
        .cancelButton {
            margin-left: 16px;
        }
    }
}