@import "Styles/colors.module.scss";

.shouldCenter {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinner {
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-left-color: $findest-blue;
    animation: loader-spin 1.2s infinite linear;
    position: relative;
    border-radius: 50%;
}

.done {
    background-color: white;
    color: $main-light-green;
}

.failed {
    background-color: white;
    color: $main-dark-red;
}

@keyframes loader-spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}