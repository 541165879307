.loadingIndicatorContainer {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;

    .description {
        font-size: 0.875rem;
        text-align: center;
    }

    .horizontalLoader {
        margin: 16px 0 4px;
    }

    .smallDescription {
        font-size: 0.75rem;
        font-style: italic;
    }

    .cancelSearchButton {
        margin-top: 16px;
    }
}