@import "Styles/colors.module.scss";

.nodeControls {
    align-self: flex-start;
    z-index: 1;
    color: white;

    &:before {
        content: " ";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        left: -4px;
        width: 4px;
        height: 100%;
    }
    ul {
        font-size: 0;
    }
    li {
        background-color: $primaryButtonColorDefault;
        padding: 0.18rem 0.25rem;
        font-size: 0.75rem;
        line-height: 1;
        display: inline-flex;
        cursor: pointer;

        svg {
            width: 0.656rem;
        }

        &:first-child {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }
        &:last-child {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;   
        }
        span {
            max-width: 0;
            transition: all .5s;
            -webkit-transition: all .5s;
            white-space: nowrap;
            overflow: hidden;
        }
        &:hover {
            background-color: $primaryButtonColorHover;
            span {
                max-width: 15.625rem;
                margin-left: 4px;
            }
        }
    }
}