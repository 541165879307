@import "Styles/colors.module.scss";

.commentsCount {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    color: $findest-dark-gray;
    cursor: pointer;
    svg {
        margin-right: 4px;
    }
    &:hover {
        color: $findest-text;
        background-color: $light-gray;
    }
}