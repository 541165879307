@import "Styles/colors.module.scss";
@import "Styles/customScrollbar.module.scss";

.groupedResultContainer{
    display: flex;
    flex-direction: column;
    padding : 1rem 0;
    overflow-y : auto;
    overflow-x : hidden;
    height: auto;
    max-height: calc(100% - 4rem);
    @include customScrollbar;
    ul {
        .groupedResultItem {
            margin-bottom: 10px;
            display: flex;            
            align-items: center;
            
            .groupedResultName {
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: 0.14px;
                text-align: left;
                font-size: 1.125rem;
                font-weight: bold;
                
                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
        .groupedResultCountContainer {
            min-width: 88px;

            .groupedResultCount {
                font-size: 0.875rem;
                border-radius: 16px;
                border: 1px solid $findest-gray;
                padding: 4px 16px;
                margin-right: 14px;
                line-height: 1;
            }
        }
    }
}
