@import 'Styles/colors.module.scss';
@import 'Styles/customScrollbar.module.scss';

.userDetailsTableContainer {
    height: calc(100% - 64px);
    overflow-x: hidden;
    overflow-y: auto;
    @include customScrollbar;
    .userDetailsTable {
        max-width: 100%;
        table-layout: fixed;
        white-space: nowrap;
        margin: 0 !important;
        border-collapse: separate;
        border-spacing: 0 2px;
    
        /* reset the default styles for the table - start */
        th {
            background-color: unset !important;
            border: none !important;
            min-width: unset !important;
        }
    
        td {
            border: none !important;
            &:hover {
                background-color: unset !important;
            }
        }
        /* reset the default styles for the table - end */
    
        &Head {
            height: 40px;
            h4 {
                font-size: 1rem;
                font-weight: 500;
                line-height: 1.5;
                display: inline-block;
            }
    
            tr {
                position: sticky;
                top: 2px;
                z-index: 1;
                background-color: $white;
                th {
                    padding: 14px 0;
                    &:first-child, &:last-child {
                        padding-left: 8px !important;
                    }
                }
            }
            .selectHeader {
                min-width: 52px;
                max-width: 52px;
                width: 52px;
            }
            .userEmailHeader {
                min-width: 320px;
                max-width: 320px;
                width: 320px;
            }
            .userRoleHeader {
                min-width: 174px;
                max-width: 174px;
                width: 174px;
            }
        }
    
        tbody {
            .dataItem {
                &.selected {
                    td {
                        background-color: $light-gray !important;
                    }
                }

                &:hover:not(.selected) {
                    box-shadow: 0px 0px 0px 1px $findest-gray inset;
                    border-radius: 4px;
                }
    
                td {
                    padding: 12px 0;
                    &:first-child {
                        padding-left: 8px !important;
                    }
                    &:last-child {
                        padding-right: 8px !important;
                    }
                }
            }
    
            .userDetails {
                display: flex;
                align-items: center;
                .username {
                    max-width: 300px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .userIcon {
                    margin-left: 0;
                }
            }
    
            td {
                height: 100%;
            }
        }
    
        .dataItemCheckbox {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
    
        .toggleContainer {
            width: auto;
            input {
                margin: 0 auto;
            }
        }
    
        .dropdownContainer {
            text-transform: uppercase;
            font-size: 0.875rem;
            letter-spacing: 1.246px;
            .dropdownSelect {
                padding: 6px 10px 6px 16px;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .dataItemCheckbox {
        margin-right: 48px;
    }
    .userDetails {
        margin-right: 100px;
    }
    .dropdownContainer {
        margin-right: 48px; 
        .dropdownSelect {
            min-width: 174px;
        }
    }
}

@media screen and (max-width: 1190px) {
    .userDetails {
        margin-right: 48px;
        .username {
            max-width: 200px !important;
        }
    }
}