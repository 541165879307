@import "Styles/colors.module.scss";

.container {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 36px;
    height: 32px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .text {
        font-size: 1rem;
    }

    .buttonContainer {
        position: absolute;
        top: 0;
        left: 0;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        /* Hide the browser's default radio button */
        input {
            display: none;
        }

        /* Create a custom radio button */
        .checkmark {
            border: 1px solid $main-black;
            height: 16px;
            width: 16px;
            border-radius: 50%;
            cursor: pointer;

            /* Style the indicator (dot/circle) */
            &:after {
                content: "";
                position: absolute;
                display: none;
                top: calc(50% - 3px);
                left: calc(50% - 3px);
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: $main-black;
            }
        }

        /* Show the indicator (dot/circle) when checked */
        &.isChecked {
            .checkmark {
                background-color: $findest-blue;
                border: 1px solid $findest-blue;
            }
            .checkmark:after {
                display: block;
                background: $white;
            }
        }
    }

    &:hover {
        .buttonContainer {
            background-color: $light-gray;
        }
    }
}