@use 'Styles/colors.module.scss' as *;

.technologySearchWarningModal {
  @media screen and (min-width: 690px) {
    min-width: 680px;
  }

  @media screen and (max-width: 690px) {
    .footer {
      flex-direction: column;
      display: inline-flex;
      gap: 4px;
      button {
        justify-content: center;
      }
    }
  }

  .paragraph {
    margin-bottom: 24px;
    color: $grey-600;

    .underlinedText {
      text-decoration: underline;
    }

    .minimizeIcon {
      font-weight: 700;
    }
  }

  .topbarOptionsImage {
    height: 64px;
    margin-bottom: 24px;
  }

  .footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
