@import "Styles/colors.module.scss";
@import "Styles/variables.module.scss";

.compactEditorContent {
  .tiptap.ProseMirror > * {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.tiptap {
  * {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.ProseMirror {
    p:first-child:last-child {
      &.is-editor-empty {
        position: relative;
        min-height: 100px;
      }
    }

    &[contenteditable="true"] {
      .custom-image {
        &:not(.has-focus):hover {
          .custom-image-caption {
            &:not(:has(a)):has(br) {
              &::before {
                content: "Type a caption";
                color: $findest-dark-gray;
                font-size: $font-size-m;
                line-height: 20px;
                letter-spacing: 0.252px;
                display: inline;
                margin-right: $spacing-4 !important;
              }
            }
          }
        }
      }
    }

    > * {
      margin-left: 4rem !important;
      margin-right: 4rem !important;
    }

    &.resize-cursor {
      cursor: ew-resize;
      cursor: col-resize;
    }

    p.is-editor-empty:first-child::before {
      color: $findest-dark-gray;
      content: attr(data-placeholder);
      pointer-events: none;
      position: absolute;
    }

    p.is-empty::before {
      color: $findest-dark-gray;
      content: attr(data-placeholder);
      pointer-events: none;
      position: absolute;
    }

    &:focus {
      outline: none;
    }

    .entity-reference-link,
    .study-reference-link,
    .file-reference-link {
        display: inline-flex;
        border: 1px solid;
        align-items: center;
        padding: 0 16px 0 8px;
        border-radius: 16px;
        text-decoration: none;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        cursor: pointer;
        color: $main-text-color;

        &::before {
            display: flex;
            height: 32px;
            width: 32px;
            min-width: 32px;
            align-items: center;
            justify-content: center;
        }
    }

    .file-reference-link {
      background-color: $attachment-color-10;
      border-color: $attachment-color-10;

      &::before {
        font: var(--fa-font-solid);
        content: "\f0c6";
        color: $attachment-count-color;
        text-decoration: underline;
        text-decoration-color: $attachment-color-10;
      }
    }

    .entity-reference-link {
        background-color: $entity-color-10;
        border-color: $entity-color-10;

        &::before {
            font: var(--fa-font-solid);
            content: "\f6d1";
            color: $entity-count-color;
            text-decoration: underline;
            text-decoration-color: $entity-color-10;
        }
    }

    .study-reference-link {
        background-color: $study-color-10;
        border-color: $study-color-10;

        &::before {
            font: var(--fa-font-solid);
            content: "\f5da";
            color: $study-count-color;
            text-decoration: underline;
            text-decoration-color: $study-color-10;
        }
    }
  }

  .custom-image {
    align-items: center;
    border-radius: 4px;
    cursor: default;
    display: flex;
    flex-direction: column;
    margin: 2rem auto;
    transition: .3s ease-in-out;
    border: 1px solid transparent;

    &:hover {
      border: 1px solid #ccc;
    }

    &-img {
      max-width: 75%;
      object-fit: cover;
    }
    &-caption {
      margin: 0;
      font-size: $font-size-m;
      line-height: 20px;
      letter-spacing: 0.252px;
      display: inline;
      margin-right: $spacing-4 !important;
      color: $findest-dark-gray;
      min-width: 1px; // To be able to see the cursor when it is focused

      #text {
        margin: 0;
        font-size: $font-size-m;
        line-height: 20px;
        letter-spacing: 0.252px;
        display: inline;
        margin-right: 4px !important;
        color: $findest-dark-gray;
      }

      a {
        font-size: $font-size-m;
        line-height: 20px;
        letter-spacing: 0.252px;
        color: $findest-blue;
        text-decoration: none;
        word-break: normal;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      &:hover {
        cursor: text;
      }
    }
  }

  a {
    cursor: pointer;
  }

  blockquote {
    background-color: $light-gray;
    padding: 8px 16px;
    border-radius: 4px;
    border: 1px solid transparent;
    margin: 8px 0 16px;
    transition: 0.3s ease-in-out;
    display: block;
    cursor: default;

    &.has-focus {
      border: 1px solid $findest-blue;
    }

    &:hover {
      border: 1px solid $findest-gray;
    }

    p {
      display: inline;
      margin-right: 4px !important;
    }

    a {
      color: $findest-blue;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .tableWrapper {
    td {
      position: relative;
      p:has(.rating) {
        margin-right: 190px !important;
      }
    }
  }

  .ratingsWrapper {
    align-items: center;
    display: inline-flex;
    float: right;
    height: 36px;
    justify-content: flex-end;
    min-width: 174px;
    position: absolute;
    right: 12px;
    top: calc(50% - 18px);

    &:after {
      font-family: "Font Awesome 6 Pro", "Roboto", sans-serif;
      content: "\f111";
      color: $notification-red;
      font-weight: 900;
      height: inherit;
      line-height: 12px;
      font-size: 12px;
      visibility: hidden;
    }
    &.rating-needed::after {
      visibility: visible;
    }
  }

  .rating {
    width: auto;
    display: inline-flex;
    vertical-align: baseline;
    font-weight: 400;
    font-size: 16px;


    @for $i from 0 through 50 {
      &[rating^="#{0.1 * $i}"] {
          --percent: #{($i * 2) + '%'};
      }
    }

    &::before {
      font-family: "Font Awesome 6 Pro", "Roboto", sans-serif;
      content: '\f005 \f005 \f005 \f005 \f005';
      color: $light-gray-2;
      background: linear-gradient(90deg, $findest-yellow var(--percent), $light-gray-2 var(--percent));
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 900;
      font-size: 25px;
    }
  }

  // override ol, ul and li elements global styles set in typography.module.scss to make lists working in the wysiwyg editor
  // overriden with google chrome user agent stylesheet
  ol,
  ul {
    display: block;
    list-style-position: outside;
    list-style-image: none;
    margin-block-start: 16px;
    margin-block-end: 24px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 32px;

    ol,
    ul {
      margin-block-start: 0px;
      margin-block-end: 0px;
    }

    li {
      list-style-position: outside;
      list-style-image: none;
      display: list-item;
      text-align: -webkit-match-parent;
      margin-top: 4px;
    }

    p {
      margin: 0;
    }
  }

  ol > li {
    list-style-type: decimal;
  }

  ul > li {
    list-style-type: disc;
  }

  p {
    margin: 8px 0 16px 0;
  }

  h1 {
    font-size: 28px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.15px;
    margin: 56px 0 0;
    padding: 0;
    text-align: initial;
  }

  h2 {
    font-size: 22px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.15px;
    margin: 32px 0 0;
    padding: 0;
    text-align: initial;
  }

  h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.144px;
    margin: 28px 0 0 0 !important;
    padding: 0;
    text-align: initial;
  }

  .selectedCell {
    border-style: double;
    border-color: $findest-blue;
    background-color: $blue-100;
  }

  :not(.ProseMirror-selectednode) {
    .entity-reference-link,
    .study-reference-link,
    .file-reference-link {

      &:hover {
        text-decoration: underline;

        &:not(.has-focus) {
          border: 1px solid #ccc;
        }
      }

      &.has-focus {
        border: 1px solid $findest-blue;
      }

      &:has(span.has-focus) {
        border: 1px solid $findest-blue;
      }
    }
  }

  .custom-image:not(.ProseMirror-selectednode) {
      &.has-focus {
        border: 1px solid $findest-blue;
      }
    }

  blockquote:not(.ProseMirror-selectednode) {
    &.has-focus {
      border: 1px solid $findest-blue;
    }
  }

  .ProseMirror-selectednode {
    position: relative;

    &::before {
      position: absolute;
      background-color: #b4d7ff;
      border-radius: .25rem;
      content: '';
      z-index: -1;
      inset: -0.25rem;
    }
  }
}

.editorEditOn {
  .custom-image {
    &:not(.has-focus):hover {
      .custom-image-caption {
        &:not(:has(a)):has(br) {
          &::before {
            content: "Type a caption";
            color: $findest-dark-gray;
            font-size: $font-size-m;
            line-height: 20px;
            letter-spacing: 0.252px;
            display: inline;
            margin-right: $spacing-4 !important;
          }
        }
      }
    }
  }
}

.rightSidebarCollapsed {
  .tiptap.ProseMirror {
    p:first-child:last-child {
      &.is-editor-empty {
        @media (max-width: 1024px) {
          min-height: 180px;
        }
        @media (max-width: 768px) {
          min-height: 200px;
        }
      }
    }
  }
}

.rightSidebarUncollapsed {
  .tiptap.ProseMirror {
    p:first-child:last-child {
      &.is-editor-empty {
        @media (max-width: 1340px) {
          min-height: 146px;
        }
        @media (max-width: 1166px) {
          min-height: 190px;
        }
        @media (max-width: 1066px) {
          min-height: 220px;
        }
        @media (max-width: 1024px) {
          min-height: 240px;
        }
      }
    }
  }
}
