@import "Styles/colors.module.scss";
@import "../../loginCommonStyles.module.scss";
    
.magicLinkButton {
    background-color: $login-magiclink-button-background-color;

    &:hover {
        background-color: $login-magiclink-button-background-hover-color !important;
    }
}
