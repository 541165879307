@import 'Styles/colors.module.scss';

.actionObjectsContainer {
    width: 100%;
    margin-bottom: 1rem;

    .inputField {
        margin-bottom: 8px;
        padding-right: 64px;
    }

    .title {
        display: flex;
        align-items: center;
        height: 2rem;
        cursor: default;
        font-weight: 500;
        line-height: 16px;
    }

    .addKeywordContainer {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-bottom: 8px;

        p {
            font-size: 14px;
            color: $findest-dark-gray;
        }

        .inputField {
            width: 248px;
            margin: 0;
            padding: 0;

            input {
                width: 100%;
                font-size: 14px;
                background-color: $white;
            }
        }

        button {
            background-color: $blue-100;
            border-radius: 4px;
            width: 24px;
            height: 24px;
            text-align: center;

            svg {
                color: $findest-blue;
                height: 12px;
                width: 12px;
            }
        }
    }
}