@import 'Styles/Legacy/userFlow.module.scss';
@import "Styles/Legacy/colors.module.scss";

.specificationUnitContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 2rem;
    padding: 0 0.75rem;

    .specificationUnit {
        display: flex;
        align-items: center;

        &.disabled {
            color: $findest-dark-gray;

            .unitValueInputField { 
                color: $findest-dark-gray; 
            }

            div {
                pointer-events: none;
            }

            svg { 
                color: $findest-dark-gray !important; 
            }
        }

        input[type="checkbox"] {
            margin-right: 0.5rem;
            cursor: pointer;
        }

        .unitValueInputContainer {
            margin: 0;

            .unitValueInputField {
                padding: 0 !important;
                border: none;
                min-width: 2.5rem;
                max-width: 2.5rem;
                text-align: center;
            }
        }

        .searchableDropdownContainer {
            .dropdownSelect {
                padding: 0.25rem 0;
                border: none;
                span { margin: 0; }
                svg { display: none; }
            }
        }

        .addUnitSearchableDropdown {
            div {
                &:first-of-type {
                    border: none;
                    padding: 0.25rem 0;
                    
                    span {
                        margin: 0;
                    }

                    svg {
                        display: none;
                    }
                }
            }
        }
    }

    .searchPriorityContainer {
        display: flex;
        margin-left: 0.5rem;
        align-items: center;

        svg {
            color: #CCCCCC;
            cursor: pointer;
            padding-left: 0.5rem;
            font-size: 0.8rem;
            transition: 0.3s ease-in-out;

            &:hover {
                color: $findest-red;
            }
        }
    }
}