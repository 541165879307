@import 'Styles/Legacy/colors.module.scss';
@import 'Styles/colors.module.scss';

.addSynonymContainer {
    display: flex;
    color: $navy-blue;
    border-radius: 0.25rem;
    font-size: 12px;
    line-height: 14px;
    padding: 2px 0;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    position: relative;

    &.addSynonyms {
        color: $findest-blue;
    }

    &:hover {
        text-decoration: underline;
    }
}