@import "Styles/colors.module.scss";

.userItem {
    display: flex;
    align-items: center;
    height: 2rem;
    margin: .5rem 0;

    .userIconContainer {
        margin: 0 16px 0 0;
        width: 1.75rem;
        height: 1.75rem;
    }

    .email {
        color: $main-black;
        font-size: 1rem;
        line-height: 1.5;
        letter-spacing: 0.496px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100% - 77px);
    }

    .deleteIcon {
        margin-left: auto;
        height: 0.75rem;
        padding: 10px;
        cursor: pointer;
        color: $main-black;
    }
}