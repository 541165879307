@import "Styles/colors.module.scss";

.customNodeContainer {
    display: flex;
    cursor: pointer;


    &.entitynode {
        .customNode {
            background-color: $entity-color-10;
        }
    }

    &.studynode {
        .customNode {
            background-color: $study-color-10;
        }
    }

    &.sourceHandleAvailable {
        .customNode:hover {
            .targetHandle {
                width: 6px;
                height: 6px;
                &:after {
                    display: none;
                }
            }
        }
    }

    .nodeControls {
        display: none;
        position: absolute;
        left: 184px;
    }

    &.targetHandleAvailable {
        .customNode:hover {
            .sourceHandle {
                width: 6px;
                height: 6px;

                &:after {
                    display: none;
                }
            }
        }
    }

    &.selected {
        .customNode {
            border: 1px solid $findest-blue;
        }
        .nodeControls {
            display: block;
        }
    }

    &.vertical:not(.targetHandleAvailable) {
        .customNode:hover {
            .sourceHandle {
                bottom: -12px;
            }
        }
    }

    &.vertical:not(.sourceHandleAvailable) {
        .customNode:hover {
            .targetHandle {
                top: -12px;
            }
        }
    }

    &.horizontal:not(.targetHandleAvailable) {
        .customNode:hover {
            .sourceHandle {
                right: -12px;
            }
        }
    }

    &.horizontal:not(.sourceHandleAvailable) {
        .customNode:hover {
            .targetHandle {
                left: -12px;
            }
        }
    }

    .loadMoreIndicator {
        font-size: 0.625rem;
        color: $main-text-color;
        text-align: right;
        position: absolute;
        bottom: -22px;
        left: 0;
        padding: 4px 6px;
        border-radius: 4px;
        box-shadow: -1px 1px 10px $buttonShadowColor;
        display: flex;
        background-color: white;
        align-items: center;

        &:hover {
            color: $findest-blue;
            .loadMoreDescription { 
                max-width: 15.625rem;
                margin-left: 4px;
            }
        }

        .loadMoreDescription {
            display: flex;
            align-items: center;
            max-width: 0;
            -webkit-transition: all .5s;
            transition: all .5s;
            white-space: nowrap;
            overflow: hidden;
            
            .loadMoreIndicatorIcons {
                margin-left: 4px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                svg {
                    height: 5px;
                }
            }
        }
    }

    .customNode {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid transparent;
        border-radius: 4px;
        width: 178px;
        padding: 5px 12px 2px;
    
        .typeIndicator {
            display: flex;
            align-items: center;
            line-height: 14px;
            border-radius: 4px;
            text-align: center;
            width: max-content;
            max-width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            .objectTypeIcon {
                margin-right: 6px;
                height: 12px;
            }

            &.entitynode {
                .objectTypeIcon {
                    color:  $entity-count-color;
                }
            }
            &.studynode {
                .objectTypeIcon {
                    color:  $study-count-color;
                }
            }
            &.groupnode {
                .objectTypeIcon {
                    color:  $group-chip-color;
                }
            }
            h6 {
                color: $findest-dark-gray;
                text-overflow: ellipsis;
                width: calc(100% - 16px);
                overflow: hidden;
            }

        }
        .nodeTextContainer {
            display: -webkit-box;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            word-break: break-word;
            line-height: 1.2;
            margin-top: 2px;

            .nodeText {
                border: none;
                background: transparent;
                font-size: 18px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0.144px;
                margin: 0;
                text-overflow: ellipsis;
                width: 100%;
                outline: none;

                &:hover {
                    text-decoration: underline;
                }
            }
            &.small {
                font-size: 0.75rem;
            }
        }

        .collapsedNodeIndicator {
            align-self: flex-end;
            color: $findest-blue;
            font-weight: 500;
            font-size: .625rem;
            &:hover {
                text-decoration: underline;
            }
        }

        .sourceHandle, .targetHandle {
            // NOTE: changing transition to "all" breaks handle positions while switching the direction of graph
            transition: width 0.2s ease-out, height 0.2s ease-out, bottom 0.2s ease-out, right 0.2s ease-out;
            background-color: $findest-blue;
            color: white;
            cursor: pointer;
        }

        &:hover {
            .sourceHandle, .targetHandle {
                width: 24px;
                height: 24px;
                &:after {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath id='plus-solid' d='M22.923,48.923a.923.923,0,1,0-1.846,0v4.154H16.923a.923.923,0,1,0,0,1.846h4.154v4.154a.923.923,0,0,0,1.846,0V54.923h4.154a.923.923,0,1,0,0-1.846H22.923Z' transform='translate(-16 -48)' fill='%23fff'/%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    content: "";
                    height: 12px;
                    width: 12px;
                    top: calc(50% - 6px);
                    right: calc(50% - 6px);
                    position: absolute;
                }
            }
        }
    }
}

.customNodeMoreActionsDropdownButton {
    .moreActionsButtonText {
        max-width: 0;
        transition: all .5s;
        -webkit-transition: all .5s;
        white-space: nowrap;
        overflow: hidden;
        text-transform: capitalize;
        margin: 0;
        font-size: 12px;
    }
    .moreActionsDropdownButton {
        background: transparent !important;
        color: white;
        height: unset;

        .moreActionsButtonText {
            max-width: 0;
            font-size: 0.75rem;
            line-height: 1;
            font-weight: 400;
            letter-spacing: normal;
        }

        .moreActionsButtonIconContainer {
            height: unset;
            width: unset;
            svg {
                color: white;
                height: 1em;
            }
        }
    }
    &.isActive, &:hover {
        .moreActionsButtonText {
            max-width: 15.625rem;
            margin-left: 4px;
        }
    }
}

.moreActionsOptionsPopover {
    margin-left: -6px;
}
