@import "Styles/colors.module.scss";
@import 'Styles/customScrollbar.module.scss';

.universeOverviewDetails {
    width: 100%;
    padding: 16px 48px;

    .header {
        display: flex;
        align-items: center;
        padding-bottom: 16px;
        .goBackButton {
            background-color: white;

            &:hover {
                text-decoration: underline;
            }
        }

        button {
            margin-right: 16px;
        }
        .title {
            font-weight: 500;
            font-size: .875rem;
            color: $main-text-color;
            margin-right: 50px;
        }
        .graphTypeDropdownGroupTitle {
            display: none;
        }

        .graphActions {
            display: flex;
            align-items: center;
            cursor: pointer;

            > div {
                margin-right: 16px;
            }

            .extraClassNameInputElement {
                background-color: $secondaryButtonColorDefault;
                border: 1px solid $secondaryButtonColorDefault;
                        
                &::placeholder {
                    color: $main-text-color;
                    opacity: 1;
                }
            }
        }
        .settingsListOption {
            display: flex;
            padding: 10px;
            align-items: center;
            cursor: pointer;
        }
    }

    .body {
        display: flex;
        &.link {
            height: calc(100% - 81px);
        }
        &.pack {
            height: calc(100% - 50px);
            overflow-y: auto;
            overflow-x: hidden;
            @include customScrollbar;

        }

        .childrenContainer {
            flex: 1;
            margin: 10px;

			.graphContainer {
				overflow: hidden;
				display: flex;
				justify-content: center;
				height: 100%;
				width: 100%;
			}
        }
    }
}