.card {
  max-width: 540px;
  flex: 1;
  align-content: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.iconContainer {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cg id='Group_1863' data-name='Group 1863' transform='translate(-509 -642)'%3E%3Ccircle id='Ellipse_39' data-name='Ellipse 39' cx='16' cy='16' r='16' transform='translate(509 642)' fill='%234cd964'/%3E%3Cpath id='check-solid' d='M45.631,96.318a1,1,0,0,1,0,1.415l-8,8a1,1,0,0,1-1.415,0l-4-4a1,1,0,0,1,1.415-1.415l3.293,3.29,7.293-7.289a1,1,0,0,1,1.415,0Z' transform='translate(486.075 556.976)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 40px;
  height: 40px;
  margin: 10px;
}