@use "Styles/colors.module.scss" as *;
@use "Styles/customScrollbar.module.scss" as customScrollbarMixin;

.onboardingChecklist {
  display: flex;
  flex-direction: column;
  background-color: $blue-100;
  border-radius: 8px;
  padding: 16px;
  overflow-x: hidden;
  overflow-y: auto;
  @include customScrollbarMixin.customScrollbar;
  height: 100%;

  .headerContainer {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;
    .header {
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 16px;
    }
    .description {
      font-size: 0.875rem;
      font-weight: 450;
      line-height: 19.6px;
      color: $main-text-color;
    }
  }

  .listContainer {
    display: flex;
    flex-direction: column;
    .onboardingChecklistOption {
      display: flex;
      align-items: center;
      font-weight: 450;
      font-size: 0.875rem;
      border-radius: 4px;
      margin-bottom: 8px;
      &:not(:first-child) {
        background-color: #EBF4FF;
      }
      .leftIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        width: 20px;
        min-width: 20px;
        margin: 6px;
        border-radius: 50%;
        background-color: $blue-100;
        &.isCompleted {
          height: 18px;
          width: 18px;
          min-width: 18px;
          margin: 7px 6px;
          background-color: $green-400;
          color: white;
        }
        svg {
          width: 10px;
        }
      }
      .onboardingChecklistOptionIcon {
        min-width: 12px;
        margin-left: auto;
        margin-right: 8px;
        color: $findest-blue;
      }
    }
  }
  
  .footer {
    font-size: 14px;
    font-weight: 450;
    line-height: 32px;
    margin-top: auto;
    .anchor {
      text-decoration: none;
      font-weight: 600;
      color: $findest-blue;
      cursor: pointer;
    }

  }
}
