@import 'Styles/colors.module.scss';

.textarea {
    border: 1px solid $findest-gray;
    border-radius: 4px;
    min-height: 58px;
    max-height: 200px;
    outline: none;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.252px;
    width: 100% !important;
    padding: 8px;

    &::placeholder {
        font-style: italic;
        color: $findest-dark-gray;
    }

    &:disabled {
        background-color: unset;
    }
}