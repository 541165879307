@import "Styles/customScrollbar.module.scss";
@import "Styles/colors.module.scss";

.profileMenu {
  background: $white;
  border: 1px solid $light-gray;
  border-radius: 8px;
  box-shadow: 0px 8px 10px 0px rgba(76, 21, 6, 0.08);
  width: 340px;
  z-index: 10;

  .divider {
    border-bottom: 1px solid $light-gray;
    margin: 0 8px;
  }

  h4 {
     color: $main-black;
     font-size: 1rem;
     font-weight: 700;
     padding:8px 0 0 8px;
  }

  .tenantSearchBarInput {
    margin: 12px;
    width: 84%;
  }

  .searchIcon {
    top: 14px;
    left: 14px;
  }

  .userDetails {
    display: flex;
    align-items: center;
    padding: 14px;
    font-size: 1em;

    .userIcon {
      width: 28px;
      height: 28px;
      margin: 0;
    }

    .username {
      color: $main-black;
      padding-left: 10px;
    }
  }

  .menuItems {
    list-style: none;
    padding: 0;

    .iconContainer {
      > svg {
        padding: 6px;
      }
    }

    .resourcesIconContainer {
      padding: 0 8px 0 0;
    }

    .icon {
      color: $findest-dark-gray;
      padding: 6px;
      font-size: 1rem;
    }

    .starIcon {
      color: $findest-yellow;
    }

    li {
      display: flex;
      align-items: center;
      padding: 4px 4px;
      font-size: 1em;

      :hover {
        background-color: $findest-background-03 !important;
      }

      a {
        color: $main-text-color;
        text-decoration: none;
        flex-grow: 1;
        display: flex;
        align-items: center;
      }
    }

    .logout {
      padding: 4px 4px;
      color: $main-light-red;

      .icon {
        padding: 6px;
      }

      :hover {
        background-color: $findest-background-03 !important;
      }
    }
  }
}

