.mainPageContainer {
    display: flex;
    height: 100%;

    .mainContentContainer {
        width: calc(100% - 288px);
        display: flex;
        flex-direction: column;

        &.fullWidth {
            width: 100%;
        }

        .contentContainer {
            display: flex;
            height: calc(100% - 316px);
            flex: 1;
        }
    }
}