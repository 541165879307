@import 'Styles/colors.module.scss';
@import 'Styles/customScrollbar.module.scss';
@import 'Styles/Fontawesome/scss/solid.scss';

// Markdown custom blocks styling
.editor-stars {
    appearance: none;
    color: transparent;
    width: auto;
    display: inline-block;
    vertical-align: baseline;
    font-size: 25px;
    font-weight: 900;

    @for $i from 0 through 50 {
        &[value^="#{0.1 * $i}"] {
            --percent: #{($i * 2) + '%'};
        }
    }
}

.editor-stars::before {
    font-family: "Font Awesome 6 Pro", sans-serif;
    content: '\f005 \f005 \f005 \f005 \f005';
    top: 0;
    left: 0;
    color: $light-gray-2;
    background: linear-gradient(90deg, $findest-yellow var(--percent), $light-gray-2 var(--percent));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.editor-table-container {
    display: block;
    overflow-x: auto;
    overflow-y: hidden;
    @include customScrollbar;
    td {
        position: relative;
        &.editor-has-inline-stars > p {
            margin-right: 190px !important;
        }
    }
}
.editor-meter-container {
    display: inline-flex;
    align-items: center;
    float: right;
    height: 36px;
    min-width: 174px;
    justify-content: flex-end;
    position: absolute;
    top: calc(50% - 18px);
    right: 12px;
    &:after {
        font-family: "Font Awesome 6 Pro", sans-serif;
        content: "\f111";
        color: $notification-red;
        font-weight: 900;
        height: inherit;
        line-height: 12px;
        font-size: 12px;
        visibility: hidden;
    }
    &.editor-rating-is-needed::after {
        visibility: visible;
    }
}

.editor-td-to-merge {
    display: none;
}

.file-reference {
    padding: 0.5rem 1rem;
    border-radius: 4px;
    display: table;
    margin: 8px 0 16px;
    background-color: white;
    box-shadow: 0px 0px 6px #00000025;
    cursor: default;
    border: 1px solid transparent;

    .file-reference-link {
        display: block;
        cursor: pointer;
        text-decoration: none;
        color: $findest-blue;

        &:hover {
            text-decoration: underline;
        }
    }
}

.highlight-reference {
    background-color: $light-gray;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    margin: 8px 0 16px;
    transition: 0.3s ease-in-out;
    display: block;
    cursor: default;


    p {
        display: inline;
        margin-right: 4px !important;
    }

    .reference-link {
        color: $findest-blue;
        text-decoration: none;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}

.image-reference {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem auto;
    border-radius: 4px;
    transition: 0.3s ease-in-out;
    cursor: default;

    .image-reference-img {
        object-fit: cover;
        max-width: 75%;
    }

    .image-reference-figcaption {
        &.empty-caption {
            display: flex;
        }

        .image-reference-figcaption-text {
            margin: 0;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.252px;
            display: inline;
            margin-right: 4px !important;
            color: $findest-dark-gray;
        }

        .reference-link {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.252px;
            color: $findest-blue;
            text-decoration: none;
            word-break: normal;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.entity-reference,
.study-reference {
    display: inline-flex;
    border: 1px solid;
    align-items: center;
    padding: 0 16px 0 8px;
    border-radius: 16px;
    cursor: default;

    .reference-link {
        color: $findest-blue;
        text-decoration: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    &::before {
        display: flex;
        height: 32px;
        width: 32px;
        min-width: 32px;
        align-items: center;
        justify-content: center;
    }
}

.entity-reference {
    background-color: $entity-color-10;
    border-color: $entity-color-10;

    &::before {
        font: var(--fa-font-solid);
        content: "\f6d1";
        color: $entity-count-color;
    }
}

.study-reference {
    background-color: $study-color-10;
    border-color: $study-color-10;

    &::before {
        font: var(--fa-font-solid);
        content: "\f5da";
        color: $study-count-color;
    }
}

.intake-sheet-confirmation {
    padding: 24px 24px 16px;
    display: block;
    border-radius: 4px;
    box-shadow: 0px 0px 6px $buttonShadowColor;
    max-width: 528px;
    margin: 0 auto;

    .editor-title {
        font-weight: 600;
        color: $main-text-color;
    }

    button {
        padding: 6px 16px;
        border: none;
        border-radius: 4px;
        text-transform: uppercase;
        color: white;
        background-color: $primaryButtonColorDefault;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        height: 32px;

        &:hover {
            background-color: $primaryButtonColorHover;
        }
    }

    .intake-sheet-confirmation-not-accepted {
        margin-top: 8px;

        p {
            color: $main-text-color;
            font-size: 1rem;
            line-height: 1.5;
        }
    }

    .intake-sheet-confirmation-accepted {
        margin-top: 12px;
        position: relative;

        &:before {
            position: absolute;
            content: " ";
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cg id='Group_1863' data-name='Group 1863' transform='translate(-509 -642)'%3E%3Ccircle id='Ellipse_39' data-name='Ellipse 39' cx='16' cy='16' r='16' transform='translate(509 642)' fill='%234cd964'/%3E%3Cpath id='check-solid' d='M45.631,96.318a1,1,0,0,1,0,1.415l-8,8a1,1,0,0,1-1.415,0l-4-4a1,1,0,0,1,1.415-1.415l3.293,3.29,7.293-7.289a1,1,0,0,1,1.415,0Z' transform='translate(486.075 556.976)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E%0A");
            top: 0;
            left: 0;
            width: 32px;
            height: 32px;
        }

        .editor-title {
            font-weight: 500;
            padding-left: 48px;
        }

        p {
            padding-left: 48px;
        }

        button {
            background-color: $secondaryButtonColorDefault;
            color: $main-text-color;

            &:hover {
                background-color: $secondaryButtonColorHoverLight;
            }
        }
    }
}

.inline-reference {
    display: inline-flex;
    border: 1px solid;
    align-items: center;
    padding: 0 16px 0 8px;
    border-radius: 16px;
    cursor: default;

    &::before {
        display: flex;
        height: 32px;
        width: 32px;
        min-width: 32px;
        align-items: center;
        justify-content: center;
    }

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

.inline-reference[data-inline-reference-type="1"] {
    background-color: $entity-color-10;
    border-color: $entity-color-10;

    &::before {
        font: var(--fa-font-solid);
        content: "\f6d1";
        color: $entity-count-color;
        text-decoration: underline;
        text-decoration-color: $entity-color-10;
    }
}

.inline-reference[data-inline-reference-type="4"] {
    background-color: $study-color-10;
    border-color: $study-color-10;

    &::before {
        font: var(--fa-font-solid);
        content: "\f5da";
        color: $study-count-color;
        text-decoration: underline;
        text-decoration-color: $study-color-10;
    }
}

// Markdown custom blocks styling when in edit mode
div[class*="_isInEditMode__"] {
    .inline-reference {
        &:hover {
            border: 1px solid $findest-gray;
        }
    }

    .inline-reference[selected="true"] {
        border-color: $findest-blue;
    }

    .file-reference,
    .entity-reference,
    .study-reference {
        display: flex;
        max-width: max-content;
        margin-bottom: 4px;

        &:hover {
            border: 1px solid $findest-gray;
        }

        &.selected {
            border-color: $findest-blue;
        }
    }

    .highlight-reference {
        display: block;
        border: 1px solid transparent;

        &:hover {
            border: 1px solid $findest-gray;
        }

        &.selected {
            border-color: $findest-blue;
        }
    }

    .image-reference {
        border: 1px solid transparent;

        &:hover {
            border: 1px solid $findest-gray;
        }

        &.selected {
            border-color: $findest-blue;
        }
    }
}