.action-highlight {
    background: rgba(231, 72, 86, 0.7);
    background: #E7485670;
}

.object-highlight {
    background: rgba(255, 140, 0, 0.7);
    background: #FF8C0070;
}

.subject-highlight {
    background: #FF8C0070;
}

.noun-phrase-highlight {
    background: rgba(0, 204, 106, 0.66);
    background: #0078d766;
}

.noun-highlight {
    background: rgba(0, 204, 106, 0.7);
    background: #00CC6A70;
}

.phrase-highlight {
    background: rgba(122, 0, 204, 0.439);
}