@import "Styles/colors.module.scss";
@import "Styles/Legacy/colors.module.scss";
@import 'Styles/Legacy/scrollbarCustom.module.scss';

.divDropdown {
    margin: 0 1.5rem 0 0.5rem;
}

.divDropdownSelect {
    width: 288px;
    background-color: white;
    height: 2rem;
    border-radius: 4px;
    padding: 6px 8px;
    border: 1px solid $findest-gray;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.3s ease-in-out;

    &:hover {
        border-color: $findest-dark-gray;
    }

    :first-child {
        flex: 1;
    }

    svg {
        color: #757575;
        margin-left: 0.5rem;
        font-size: 12px;
    }
}

.divDropdownList {
    width: 306px;
    max-height: 296px;
    overflow-y: auto;
    overflow-x: hidden;
    position: fixed;
    background-color: white;
    margin-top: 0.25rem;
    border-radius: 4px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.26);
    padding: 4px 0;
    z-index: 2;

    div {
        div {
            height: 24px;
            padding: 0 8px;
            overflow: hidden;
            display: flex;
            align-items: center;
            cursor: pointer;

            &:hover {
                background-color: $light-background-color;
            }
        }
    }
}