@use "Styles/colors.module.scss" as colors;

.technologyOverviewWizard {
  border: 1px solid colors.$findest-gray;
  border-radius: 8px;
  padding: 10px 12px 10px 14px;

  background-color: white;

  &.compactView {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 4px;
    .header {
      .technologyOverviewHeader {
        font-weight: 450;
      }
    }
  }

  .header {
    display: flex;
    gap: 12px;
    height: 34px;
    align-items: center;
    .technologyOverviewHeader {
      font-size: 1rem;
      font-weight: 600;
    }
  }

  .textArea {
    margin: 12px 0;
  }

  .toggleSwitch {
    display: inline-flex;
    flex-direction: row-reverse;
    gap: 16px;
    font-size: 0.875rem;
  }

  .description {
    font-size: 0.875rem;
    color: colors.$findest-dark-gray;
    line-height: 18.2px;
    font-weight: 450;
    margin-top: 12px;
  }

  .searchButton {
    margin-top: 2px;
  }

  .searchingState {
    font-size: 0.875rem;
    margin-bottom: 16px;
    .loadingIndicatorContainerContainer {
      background-color: colors.$light-gray;
    }
    .description {
      color: colors.$main-text-color;
    }
    .naturalLanguageQuery {
      margin: 16px 0;
    }
  }

  .footer {
    display: flex;
    gap: 12px;
  }
}
