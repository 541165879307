@import 'Styles/colors.module.scss';

.actionsButton {
    margin: 0;
    > button {
        padding: 0 !important;
        background-color: transparent;

        svg {
            color: $moreIconColor;
            height: 17px;
        }

        &.dropdownButtonHover, &:hover {
            background-color: $light-gray;
        }
    }
    .buttonIconContainer {
        width: 32px;
        height: 32px;
    }
}

.optionText {
    text-transform: uppercase;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 500;
    letter-spacing: 1.246px;
}
