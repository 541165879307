@import "Styles/colors.module.scss";

.attachmentsContainer {
    display: inline-flex;
    flex-direction: column;
    position: relative;
    min-height: 118px;
    width: 100%;

    h3.attachmentsTitle {
        text-align: right;
        margin-right: 1rem;
        font-size: 1.063rem;
        line-height: 1.25rem;
        letter-spacing: 0.1275px;
    }

    .attachments {
        display: flex;
        justify-content: flex-end;
        padding: 0 8px;

        .attachment {
            border: 1px solid $findest-gray;
            border-radius: 4px;
            padding: 4px;
            display: flex;
            align-items: center;
            width: 192px;
            margin: 4px 8px;
    
            &:hover {
                text-decoration: underline;
                .deleteFileIconContainer {
                    visibility: visible;
                }
            }
    
            .attachmentIconContainer {
                min-width: 2rem;
                min-height: 2rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }
    
            .attachmentText {
                font-size: 0.75rem;
                line-height: 1rem;
                letter-spacing: 0.396px;
                display: -webkit-box;
                line-clamp: 2;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                cursor: pointer;
            }
    
            .deleteFileIconContainer {
                min-width: 1rem;
                display: flex;
                margin-left: auto;
                visibility: hidden;
            }
    
            .deleteFileIcon {
                height: 0.625rem;
                margin-right: 0.206rem;
                margin-left: 0.25rem;
                color: $findest-dark-gray;
                cursor: pointer;
            }
        }
    }
    .moreItemsCountIndicator {
        font-size: .75rem;
        line-height: 1rem;
        letter-spacing: 0.396px;
        color: $primaryButtonColorDefault;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        margin: 0 16px 0 auto;
        .chevronIcon {
            margin-left: 8px;
        }
    }

    .popover {
        z-index: 2;
        right: -2px;
        max-width: 432px !important;
        min-width: 0 !important;
        top: 20px;

        .attachments {
            flex-wrap: wrap;
            min-width: 432px;
            overflow-y: auto;
            overflow-x: hidden;
            padding: 0 8px;
        }
    }
}

@media screen and (max-width: 1135px) {
    .attachments.insideCard {
        flex-direction: column;
        align-items: flex-end;
        .attachment {
            width: 100%;
        }
    }
}