// Font families
$font-primary: "IBM Plex Sans";
$font-secondary: "Roboto";
$font-tertiary: sans-serif;
$font-quaternary: "IBM Plex Mono";

// Font weights
$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-450: 450;
$font-weight-500: 500;
$font-weight-700: 700;

// Font sizes
$font-size-xs: 0.625rem; // 10px
$font-size-s: 0.75rem; // 12px
$font-size-m: 0.875rem; // 14px
$font-size-l: 1rem; // 16px
$font-size-xl: 1.125rem; // 18px

// Line heights
$line-height-m: 1rem; // 16px

// Spacings
$spacing-4: 4px;
$spacing-6: 6px;
$spacing-8: 8px;

// Border radius
$border-radius-4: 4px;
$border-radius-8: 8px;
