@import "Styles/colors.module.scss";

.savedDocumentListHeader {
    padding: 16px 24px 16px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 1;
    height: 64px;
    background: white;

    .addFilterButtonContainer {
        margin-right: 32px;
    }

    .trashIcon {
        display: none;
        padding: 9px 10px;
        border-radius: 4px;
        height: 14px;
        background-color: $main-light-red;
        color: $white;
        cursor: pointer;
        &:hover {
            background-color: $main-dark-red;
        }
    }

    .linkIcon {
        display: none;
        padding: 9px 7px;
        border-radius: 4px;
        background-color: $main-purple;
        height: 14px;
        color: $white;
        cursor: pointer;
        &:hover {
            background-color: $main-purple-hover;
        }
    }

    .unlinkIcon {
        display: none;
        padding: 9px 7px;
        border-radius: 4px;
        background-color: $main-light-red;
        height: 14px;
        color: $white;
        cursor: pointer;
        &:hover {
            background-color: $main-dark-red;
        }
    }

    .askIgorContainer {
        display: inline-flex;
        align-items: center;
        gap: 8px;
        margin-left: 10px;
        padding: 8px 12px;
        height: 34px;
        width: 130px;
        border: 1px solid $light-gray;
        border-radius: 4px;
        box-shadow: $light-gray -1px 1px 10px;
        color: $purple-2;
        cursor: pointer;

        p {
            font-size: 13px;
            font-weight: 500;
            line-height: normal;
        }
    }

    &Left {
        display: flex;
        align-items: center;
        label[class*="checked"], label[class*="partiallySelected"] {
            & ~ svg {
                &:first-of-type {
                    margin-left: 16px;
                }
                display: block;
                margin-left: 10px;
            }
        }
    }

    &Right {
        display: flex;
        align-items: center;
        height: 100%;

        .listHeaderPopover {
            &.hidden {
                visibility: hidden;
            }
            z-index: 1;
            .popoverHeader {
                color: $findest-dark-gray;
                text-transform: uppercase;
                font-size: 10px;
                font-weight: 500;
                padding: 8px 12px;
            }
            li {
                list-style: none;
                padding: 6px 12px;
                cursor: pointer;
            }
        }

        .addFilterButtonContainer {
            margin-right: 32px;
            position: relative;
            .addFilterButton {
                padding: 8px 12px;
                height: 100%;
                background-color: $main-gray;
                color: $main-text-color;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 1.246px;
                font-weight: 500;
                border-radius: 4px;
                text-transform: uppercase;
                white-space: nowrap;

                &:hover {
                    background-color: $secondaryButtonColorHover;
                }
    
                > svg {
                    margin-right: 10px;
                }
            }
            
            .listHeaderPopover {
                .selected {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-color: #D9EBFF;
                }
                li:not(.selected):hover {
                    background-color: $main-gray;
                }
            }
        }
        .countIndicatorContainer {
            font-size: 14px;
            position: relative;
            .countIndicator {
                padding: 8px 12px;
                color: $findest-dark-gray;
                height: 100%;
                border-radius: 4px;
                white-space: nowrap;
                &:hover {
                    background-color: $main-gray;

                    &:before {
                        content: " ";
                        display: block;
                        position: absolute;
                        height: 4px;
                        top: -4px;
                        left: 0;
                        right: 0;
                    }
                    &:after {
                        content: " ";
                        display: block;
                        position: absolute;
                        height: 4px;
                        bottom: -4px;
                        left: 0;
                        right: 0;
                    }
                }
            }
            .listHeaderPopover {
                li.active {
                    cursor: default;
                    color: $findest-gray;
                }
            }
        }

        .arrow {
            height: 100%;
            padding: 8px 13px;
            border-radius: 4px;
            cursor: pointer;
            &:hover {
                background-color: $main-gray;
            }
            &.disabled {
                cursor: not-allowed;
                pointer-events: none;
                opacity: 0.3;
                &:hover {
                    background: initial;
                }
            }
        }
    }
}