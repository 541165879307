@import "Styles/colors.module.scss";

.paginatorContainer {
    display: flex;
    align-items: center;

    .countIndicatorContainer {
        font-size: 14px;
        position: relative;
        padding: 0 12px;
        user-select: none;
        color: $findest-dark-gray;
    }

    .arrow {
        height: 32px;
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
            background-color: $main-gray;
        }
        &.disabled {
            cursor: not-allowed;
            pointer-events: none;
            opacity: 0.3;
            &:hover {
                background: initial;
            }
        }
    }
}