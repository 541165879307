@import 'Styles/colors.module.scss';

.floatingTableMenu {
  display: flex;
  justify-content: center;
  min-width: unset !important;
  min-height: unset !important;
  padding: 0;
  z-index: 10000;

  button {
    width: 18px;
    height: 18px;
    border-radius: 4px;
    background-color: $light-gray;
    font-size: .75rem;
    cursor: pointer;
    border: none;
    &:hover {
      background-color: $findest-gray;
    }
  }
}

.editorTableCellCommandsPopover {
  padding: 8px 0;
  z-index: 10000;
}