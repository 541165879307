.emptyStateContainer {
  width: 100%;
  display: flex;
  flex-direction: column;

  .header {
    padding: 8px 16px;
  }

  .content {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    .contentBox {
      max-width: 520px;
      text-align: center;

      .descriptionHeader {
        margin-bottom: 24px;
        font-weight: 600;
        font-size: 1.25rem;
      }

      .paragraph {
        font-size: 16px;
        font-weight: 450;
        line-height: 22.4px;

        &:last-of-type {
          margin: 24px 0;
        }
      }

      .buttonsContainer {
        display: flex;
        justify-content: center;
        gap: 16px;
      }
    }
  }
}