@import 'Styles/colors.module.scss';

.referenceSidebarSelectedTab {
    .loadingIndicatorContainer {
        display: flex;
        justify-content: center;
        margin-top: 150px;
    }
    .noReferences {
        padding: 0 1rem;
        font-style: italic;
        cursor: default;
        color: $findest-dark-gray;
        margin-top: 50px;
        font-size: 0.875rem;
    
        h4 {
            margin: 1rem 0;
        }
    }
}
