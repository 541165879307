@import "Styles/colors.module.scss";

.title {
    display: flex;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    color: $main-text-color;
    letter-spacing: 0.15px;
    margin-bottom: 2px;
    .iconContainer {
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}