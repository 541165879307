@import "Styles/colors.module.scss";

.editableComboBox {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 4px;

  .placeholder {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0px;
    background-color: $findest-background-03;
    height: 56px;
    border-radius: 8px;

    .tenantText {
      display: inline-block;
      padding: 8px;
      cursor: pointer;
      width: 100%;
    }

    p {
      font-size: 1em;
    }

    .leftIcon {
      padding: 6px;
      margin: 0 0 0 8px;
      color: $findest-dark-gray;
      font-size: 1em;
    }

    .rightIcon {
      margin: 0 12px;
      color: $findest-dark-gray;
      font-size: 1em;
    }

    &:hover {
      background-color: $light-gray-3;
    }
  }

  .inputContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: $findest-background-03;
    padding: 10px 12px;
    border-radius: 8px;
    height: 56px;

    .iconContainerLeft {
      height: 100%;
    }

    .input {
      width: calc(100% - 16px);
      align-self: center;
      border: 2px solid $findest-blue;
      height: 40px;
      border-radius: 8px;
      font-size: 1em;
    }

    .clearButton {
      cursor: pointer;
      align-self: center;
      justify-content: center;
      background-color: unset;
      color: $findest-dark-gray;
      padding: 0;
    }
  }

  .dropdown {
    background-color: $findest-background-01;
    border-radius: 8px;
  }

  .dropdownItem {
    padding: 8px;
    cursor: pointer;

    &:hover {
      background-color: $light-background-color;
    }
  }
}
