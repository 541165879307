.synonymsContainer {
    display: flex;
    position: relative;
    
    .isDisabled {
        display: none;
    }

    &:after {
        content: " ";
        display: block;
        position: absolute;
        height: 16px;
        width: 100%;
        bottom: -16px;
        left: 0;
        right: 0;
    }

}

.searchSubTerm {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 16px;
}