@import 'Styles/customScrollbar.module.scss';
@import 'Styles/colors.module.scss';

.caseDataSelectionContainer {

    h3 {
        margin: 16px 0px;
    }

    .actionButtons {
        margin: 16px 0px;
        display: flex;

        > * { // Selects all direct children of .caseDataSelection
            margin-right: 10px; // Adds a bit of space to the right of each child

            &:last-child {
                margin-right: 0; // Removes the margin from the last child to prevent extra space on the end
            }
        }
    }
    
    .phasesContainer {
        overflow-x: hidden;
        overflow-y: auto;
        max-height: calc(100vh - 252px);
        margin: 16px 0;
        @include customScrollbar;

        .phase {
            background-color: $light-gray;
            border-radius: 4px;
            padding: 10px;
            &:not(:last-of-type) {
                margin-bottom: 10px;
            }

            &:not(:first-of-type) {
                margin-top: 10px;
            }
            .phaseName {
                font-size: 1.25rem;
                font-weight: 500;
            }
            .conceptName {
                font-size: 1.125rem;
            }
            .technologyName {
                font-size: 1rem;
            }

            .phaseNameContainer, .conceptNameContainer, .technologyNameContainer {
                display: flex;
                align-items: center;
                cursor: pointer;
                height: 40px;
                &:hover {
                    background-color: $secondaryButtonColorHover;
                }
            }

            .phaseConcepts {
                padding-left: 30px;
            }

            .technologies {
                padding-left: 30px;

                .technology {
                    display: flex;
                    align-items: center;
                }
                .technologiesWithoutConceptTitle {
                    padding: 8px;
                    font-weight: 500;
                    font-style: italic;
                }
            }
        }
    }
}