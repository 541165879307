@import 'Styles/colors.module.scss';
@import 'Styles/typography.module.scss';

.mainTitleStyle {
    border: none;
    outline: none;
    font-size: 34px;
    font-weight: 400;
    letter-spacing: 0px;
    padding: 5px 0 0 0;
    margin-top: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.notEditable {
        white-space: unset;
        cursor: default;
        line-height: 36px;
        padding: 7px 0 2px 0;
    }
}