@import 'Styles/Legacy/colors.module.scss';
@import 'Styles/Legacy/buttons.module.scss';

.findestSynonym {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 2rem;
    padding: 0 0.75rem;

    &:nth-child(odd) {
        background-color: $findest-background-03;
    }

    label {
        display: flex;
        align-items: center;
        flex: 1;

        input[type="text"] {
            padding: 0.125rem 0;
            border: none;
            font-family: 'IBM Plex Sans', sans-serif;
            font-size: 14px;
            outline: none;
            width: 100%;
            text-overflow: ellipsis;
            background-color: transparent;

            &::placeholder {
                color: $findest-dark-gray;
                font-style: italic;
            }
        }
    }
}

.deleteSynonym {
    color: $findest-gray;
    cursor: pointer;
    padding-left: 0.5rem;
    font-size: 0.8rem;
    transition: 0.3s ease-in-out;
    &.hidden {
        visibility: hidden;
    }

    &:hover {
        color: $findest-red;
    }
}

.showSynonym {
    color: $findest-gray;
    cursor: pointer;
    padding-left: 0.5rem;
    font-size: 0.8rem;
    transition: 0.3s ease-in-out;
    &.hidden {
        visibility: hidden;
    }

    &:hover {
        color: $main-purple;
    }
}

.synonymsPopover {
    padding: 16px 32px 24px;
    flex-direction: column;
    .title {
        color: $findest-dark-gray;
        font-size: 0.625rem;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        margin-bottom: 12px;
    }
    .synonymsList {
        flex-direction: column;
    }
}
.addSynonymTextBox {
    margin-left: 28px;
    width: calc(100% - 28px);
}
