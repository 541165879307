@import "Styles/customScrollbar.module.scss";
@import "Styles/colors.module.scss";
@import "Styles/objectIcon.module.scss";

.referencePopoverComponent {
    padding: 0 !important;
    overflow: visible !important;
    max-width: unset !important;
    max-height: 282px !important;
    z-index: 9999 !important;

    &::before {
        content: " ";
        display: block;
        position: absolute;
        width: 100%;
        height: 8px;
        top: -8px;
        left: 0;
    }

    &::after {
        content: " ";
        display: block;
        position: absolute;
        width: 100%;
        height: 8px;
        bottom: -8px;
        left: 0;
    }
}

.referencePopover {
    padding: 16px 32px 24px;
    width: 820px;
    min-width: 820px;
    max-height: 282px;
    background-color: white;
    border-radius: 4px;
    overflow: hidden;

    &.documentReferencePopover {
        min-width: 333px;
        width: 333px;
        max-height: fit-content;
        padding-bottom: 16px;
    }

    .popoverContent {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .loadingIndicatorContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .section {
        display: flex;
        justify-content: space-between;
        .properties {
            display: flex;
            flex-direction: column;
            max-width: 100%;
            gap: 8px;

            .type {
                display: flex;
                color: $main-text-color;
                .objectIcon {
                    margin-right: 8px;
                    @include objectIcon;
                }
            }
            .title {
                display: flex;
                align-items: center;
                svg {
                    margin-right: 8px;
                    color: $study-count-color;
                }
                &.entity {
                    svg {
                        color: $entity-count-color;
                    }
                }
                .titleText {
                    color: $main-text-color;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 1.25rem;

                    &.documentTitleText {
                        text-align: left;
                        white-space: wrap;
                        font-size: 16px;
                        line-height: 20px;
                        font-weight: 700 !important;
                    }
                }
            }
            .authorships {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                
                p {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                }
            }
        }
    }

    .moreActionsButtons {
        display: flex;
        justify-self: flex-end;
        button:not(:last-child) {
            margin-right: 16px;
        }
    }

    .popoverBody {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        flex: 1;

        .markdownContainer {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            max-height: 4.375rem;

            h1, h2, h3 {
                font-size: 16px !important;
                font-weight: 400 !important;
            }
        
            table, figure, blockquote, div[class*="node-intakeSheet"] {
                display: none !important;
            }
        }

        .entityLikeImageContainer {
            position: relative;
            text-align: center;
            min-width: calc(35% - 64px);
            max-width: calc(35% - 64px);
            padding-left: 2rem;
            margin-top: 4px;

            .entityLikeImageAspectRatioBox {
                height: 0;
                overflow: hidden;
                padding-bottom: 56.25%;
                position: relative;
                width: 100%;

                .entityLikeImageAspectRatioBoxContent {
                    display: flex;
                    height: 100%;
                    justify-content: center;
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 100%;

                    img {
                        max-height: 100%;
                        max-width: 100%;
                        object-fit: contain;
                    }
                }
            }

            .entityLikeImageCaption {
                margin-top: 0.5rem;
            }
        }
    }
}

.referenceLoadingIndicator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 333px;
    height: 80px;
    margin-top: 12px;
}

.referenceModalOverlay {
    display: block;
}

.referenceModal {
    display: flex;
    inset: 24px 88px;
    transform: unset !important;
    width: unset !important;
    padding: 0 !important;

    .header {
        position: absolute !important;
        right: 16px;
        top: 17px;
        z-index: 5;
    }

    .referenceModalMainContentContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        &.fullWidth {
            width: 100%;
        }

        .objectTopbarContainer {
            border-radius: 4px;
            padding: 1rem 6.5rem 1rem 4rem;
        }

        .restoreContainer {
            display: flex;
            height: 48px;
            align-items: center;
            padding: 0 64px;
            margin: auto;
            width: 100%;
            max-width: 1024px;

            .restoreButton {
                background-color: $primaryButtonColorDefault;
                &:hover {
                    background-color: $primaryButtonColorHover;
                }
            }
        }
        
        .referenceModalMainContent {
            display: flex;
            height: calc(100% - 65px);

            &.fullHeight {
                height: 100%;
            }
        }
    }
}

.navigateToButton {
    position: absolute;
    right: 48px;
    top: 17px;
    z-index: 5;
}

.hasNavigateToButton {
    padding-right: 6.5rem !important;
}
