@import 'Styles/colors.module.scss';
@import 'Styles/customScrollbar.module.scss';

.overviewTableModal {
    display: flex;
    height: 100%;
    overflow: hidden;
    padding: 0;
    .header {
        position: absolute;
        right: 21px;
        top: 11px;
    }
    .tableOptionsContainer {
        padding: 48px 64px;
        width: 266px;
        min-width: 266px;
        border-right: 0.5px solid $findest-gray;
        overflow-y: auto;
        overflow-x: hidden;
        @include customScrollbar;
        display: flex;
        flex-direction: column;

        .optionTitle {
            margin-bottom: 4px;
        }
        .firstOption {
            margin-top: 8px;
        }
        .buttonsContainer {
            margin-top: auto;
            padding-top: 50px;
            button:nth-of-type(2) {
                margin-top: 16px;
            }
        }
        .information {
            margin-top: 10px;

            .layersInformationBox {
                border: none;
            }
        }
    }
    .contentContainer {
        flex: 1;
        padding: 48px 0 48px 48px;
        overflow-x: auto;
        overflow-y: auto;
        @include customScrollbar;
        .contentHeader {
            display: flex;
            align-items: center;
            padding-right: 64px;
            .headerRightContent {
                margin-left: auto;
                .maturityScaleContainer {
                    width: 256px;
                    min-width: 256px;
                    height: 26px;
                    .maturityScaleActualStep {
                        display: none;
                    }
                }

                .maturityLevelScaleStepsTextsContainer {
                    padding-top: 2px;
                }
            }
            .headerButtons {
                display: flex;
                margin-left: auto;
                :first-child {
                    margin-right: 16px;
                }
            }
            .modalTitle {
                font-size: 1.75rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding: 0 16px 0 0;
                margin: 0;
                color: $main-text-color;
                font-weight: 500;
                letter-spacing: 0.013rem;
            }

            .aiGenerationInformationBox {
                border: none;
                margin-right: 16px;
            }
        }

        .modalBody {
            display: flex;
            flex-direction: column;
            margin-right: 64px;
            height: calc(100% - 48px);
            overflow-y: auto;
            overflow-x: auto;
            @include customScrollbar;

            .scoutingServiceOptionTableContainer {
                overflow: visible;
            }

            .descriptionTextArea {
                border: none;
                padding: 0;
                margin-top: 16px;
                resize: none;
            }
        }
    }
}