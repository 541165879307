@import "Styles/colors.module.scss";
@import "../../loginCommonStyles.module.scss";

.emailBox {
    margin: 30px 0;
    .emailInput {
        border: none;
        border-bottom: 1px solid  $login-title-color;
        background-color: transparent;
        font-size: 1rem;
        line-height: 1.5rem;
        outline: none;
        width: 100%;
        margin-bottom: 20px;

        &:hover, &:focus {
            border-bottom: 1px solid $inputFocusBorderColor;
        }
    }

    .checkboxContainer {
        display: flex;

        label {
            cursor: pointer;
        }
    }
}
