@import 'Styles/colors.module.scss';

.title {
    line-height: 16px;
    font-size: 10px;
    font-weight: 500;
    color: $main-text-color;
    text-transform: uppercase;
    margin-bottom: 4px;
    letter-spacing: 1.5px;
}

.section {
    margin-bottom: 24px;
}

.footer {
    display: flex;
}

.addButton {
    margin-right: 16px;
}

.foundDocument {
    background-color: rgba(76, 217, 100, 0.10);
    margin-top: 0.5rem;
    border-radius: 4px;
    padding: 16px;
}

.customInputRightIcon {
    position: absolute;
    top: 5px;
    right: 8px;
}

.webpageTitle {
    font-weight: 500;
    margin-top: 8px;
    word-break: break-all;
}

.selectedFileContainer {
    display: flex;
    align-items: center;
    margin-top: 16px;
    .attachFileIcon {
        margin-right: 16px;
    }
    .trash {
        margin-left: auto;
        cursor: pointer;
        height: 0.875rem;
        &:hover {
            color: $main-light-red;
        }
    }
}

.orContainer {
    margin: 0 0 0.75rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 24px;

    &:before, &:after {
        content: "";
        background: $findest-gray;
        height: .8px;
        width: 100%;
    }
    span {
        text-align: center;
        padding: 0 8px;
        color: $findest-gray;
        font-size: 0.625rem;
        font-weight: 500;
    }
}