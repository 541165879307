@import 'Styles/colors.module.scss';

.baseButton {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 14px;
    cursor: pointer;
    line-height: 16px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 0.25rem 0.5rem;
    outline: none;
    border-radius: 0.25rem;
    transition: 0.3s ease-in-out;
}

.deleteButton {
    color: $findest-gray;
    cursor: pointer;
    padding-left: 0.5rem;
    font-size: 0.8rem;

    &:hover {
        color: $findest-red;
    }
}

.coloredButton {
    @extend .baseButton;
    color: white;
    border: none;

    &.purple {
        background-color: $findest-purple;
    }

    &:hover {
        color: $findest-text;
        background-color: white;
        box-shadow: -3px 3px 16px rgba(0, 0, 0, 0.16);
    }
}

.whiteButton {
    @extend .baseButton;
    
    color: $findest-text;
    background-color: white;
    border: 1px solid $findest-gray;

    &:hover {
        background-color: white;
        box-shadow: -2px 2px 6px rgba(0, 0, 0, 0.05);
        border-color: $findest-text;
    }
}

.cancelButton {
    @extend .baseButton;
    background-color: $light-background-color !important;
    color: $findest-dark-gray !important;

    &:hover {
        color: $findest-text !important;
    }
}

.marginRight {
    margin-right: 1rem;
}