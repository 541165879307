// background-color
$primary-background-color: #003171;
$secondary-background-color: #E5EAF0;
$light-secondary-background-color: #FAFAFA;
$light-background-color: #F2F2F2;

// color
$unselected-color: #CCCCCC;
$selected-color: #FF9500;

$findest-blue: #007AFF;
$findest-red: #FF3B30;
$findest-green: #4CD964;
$findest-light-green:#a5d94c;
$findest-dark-green: #3dab3d;
$findest-darker-green: #1e861e;
$findest-orange: #FF9500;
$findest-yellow: #FFCC00;
$findest-purple: #5856D6;
$findest-pink: #FF2D55;
$primary-purple: #A30076;

$findest-gray: #CCCCCC;
$findest-dark-gray: #757575;
$findest-light-blue: #5AC8FA;
$findest-chat-blue: #5AC8FA50;

$findest-text: #212121;
$findest-text-dark-blue: #04111e;
$findest-title: #110e22;
$findest-light-text: #9d9c9c;

// border-colors
$findest-border-gray: #757575;
$findest-border-light-gray: #F2F2F2;

// In order for webpack to use the colors:
:export {
    findestPurple: $findest-purple;
    primaryPurple: $primary-purple;
    findestBlue: $findest-blue;
    findestYellow: $findest-yellow;
    primaryBackgroundColor: $primary-background-color;
    findestGreen: $findest-green;
}

// menu-colors
$findest-background-01: #FCFCFD;
$findest-background-02: #FAFBFC;
$findest-background-03: #F7F8FA;
$findest-background-04: #F2F4F7;
$findest-background-05: #EFF1F6;

// footer navigation buttons
$footer-navigation-color: #3e3c8c;
$footer-navigation-background-color: #f5f5ff;
$footer-navigation-background-color-center: #e8f3ff;
$footer-navigation-border-color: #c9cfe6;

// custom radio button
$radio-button-border-color: #003171;
$radio-button-hover-background-color: #bdc7d9;
$radio-button-selected-background-color: #144496;
$image-radio-button-border-color: #6887a8;

// modal close icon
$modal-close-icon-color: #e5e6f1;
$modal-close-icon-hover-color: #cfd1e1;