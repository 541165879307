@import 'Styles/colors.module.scss';

.objectTopbarContainer {
    display: flex;
    justify-content: space-between;
    padding: 8px 16px 8px 16px;
    border-bottom: 1px solid $findest-gray;
    background-color: white;
    z-index: 2;

    .rightSide {
        display: flex;
        margin-left: auto;

        .editViewToggleButton {
            margin-right: 16px;
        }

        .standaloneButtons {
            margin-right: 4px;

            &:first-of-type {
                margin-left: 4px;
            }

            button {
                text-transform: capitalize;
                font-size: 1rem;
                font-weight: normal;
                height: 32px;
            }
        }
    }

    .actionsButton {
        margin: 0;

        .actionsButtonIconContainer {
            width: 32px;
            height: 32px;
        }

        > button {
            padding: 0 !important;
            background-color: white;

            svg {
                color: $moreIconColor;
                height: 17px;
            }

            &.actionsDropdownButtonHover, &:hover {
                background-color: $light-gray;
            }
        }
    }
}

.optionText {
    text-transform: uppercase;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 500;
    letter-spacing: 1.246px;
}
