@import 'Styles/colors.module.scss';

.commentsPopover {
    min-width: 270px !important;
    z-index: 1001 !important;
    div[class*="comment_comment__"]:last-of-type {
        margin-bottom: 4px;
    }
    .newCommentContainer {
        padding: 8px 16px 8px 32px;
        border-top: 1px solid $light-gray;
        .newCommentButton {
            height: 32px;
            padding: 0 10px;
            border-radius: 0.25rem;
            font-size: 14px;
            line-height: 16px;
            text-transform: uppercase;
            font-weight: 500;
            letter-spacing: 1.246px;
            cursor: pointer;
            border: 0;
            outline: none;
            background-color: $secondaryButtonColorDefault;
            color: $main-text-color;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            svg {
                margin-right: 10px;
            }
        
            &:hover {
                background-color: $secondaryButtonColorHover;
            }
        }
    }
}