@use "Styles/colors.module.scss" as *;

.container {
  height: 100%;
  margin: 27px 65px 105px;
  background-color: $grey-200;

  .explanationContainer {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .header {
      margin-bottom: 16px;
    }

    .explanation {
      font-size: 0.875rem;
      &:first-of-type {
        margin-bottom: 16px;
      }
    }

    .excuteQueryButton {
      margin-top: 16px;
    }
  }
}