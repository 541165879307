@import 'Styles/colors.module.scss';

.rightSidebar {
    position: relative;
    transition: .3s ease-in-out;
    border-left: 1px solid $findest-gray;
    margin-left: auto;

    &.open {
        min-width: 360px;
        max-width: 360px;
    }

    &.collapsed {
        min-width: 0;
        max-width: 0;
    }
    .hidden {
        display: none;
    }

    .contentContainer {
        height: 100%;
    }

    &.isInReferenceModal {
        &.open {
            min-width: 300px;
            max-width: 300px;
        }
    }
    .collapseButton {
        display: flex;
        width: 2rem;
        height: 2rem;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 4px;
        color: $findest-dark-gray;

        &:hover {
            background-color: $light-gray;
            color: $findest-text;
        }
    }
}
