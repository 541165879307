@import "Styles/Legacy/colors.module.scss";

.textSelectionMenuPopupItemContainer {
    cursor: pointer;
    font-size: 1rem;
    color: $findest-text;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 16px 0 4px;

    &:hover {
        background-color: $light-background-color;
    }

    .textSelectionMenuPopupItemIconContainer {
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.textSelectionMenuPopupItemSecondaryContainer {
    padding: 0.25rem 0.5rem 0.75rem 2rem;

    label {
        font-size: 1rem;
    }

    div {
        margin-top: 0.25rem;
        display: flex;
        align-items: center;

        select {
            outline: none;
            font-size: 1rem;
            color: $findest-text;
            font-family: 'IBM Plex Sans', sans-serif;
            cursor: pointer;
            padding: 1px 0.125rem;
            border-radius: 0.25rem;
            border-color: $findest-gray;
            margin-right: 0.5rem;
            max-width: 10rem;

            option {
                max-width: 5rem;
            }
        }

        input {
            border: none;
            font-size: 14px;
            font-family: 'IBM Plex Sans', sans-serif;
            text-transform: uppercase;
            border-radius: 0.25rem;
            padding: 0.25rem 0.5rem;
            font-weight: 500;
            color: white;
            background-color: $primary-purple;
            cursor: pointer;
        }
    }

}