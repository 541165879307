.caseSelectionContainer {
    .section {
        margin-bottom: 30px;
        h3 {
            margin-bottom: 16px;
        }
        div {
            max-width: 806px;
        }
    }
}