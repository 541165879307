@import 'Styles/colors.module.scss';
.navigateToButton {
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: 0.3s ease-out;
    
    svg {
        color: $findest-dark-gray;
    }
    &:hover {
        background-color: $light-background-color;
        svg {
            color: $findest-text;
        }
    }
}
