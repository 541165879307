@import "Styles/colors.module.scss";

.createdByAccountContainer {
    position: relative;
    .userIcon {
        height: 32px;
        width: 32px;
        margin-right: 0;
        font-size: 16px;
        font-weight: 500;
        user-select: none;
    }
    .dropdownContainer {
        position: absolute;
        box-shadow: -1px 1px 10px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        background: white;
        padding: 12px 16px;
        margin-top: 4px;
        z-index: 2;
        right: 0;
        white-space: nowrap;
        cursor: default;

        &:before {
            content: " ";
            display: block;
            position: absolute;
            height: 4px;
            top: -4px;
            left: 0;
            right: 0;
        }
    }
}