@import 'Styles/colors.module.scss';

.requirementsTableContainer {
    padding: 0 3rem;
    margin-top: 56px;
    .requirementsTableHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .requirementsTableTitle {
            font-size: 1.75rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0 16px 0 0;
            margin: 0;
            color: $main-text-color;
            font-weight: 500;
            letter-spacing: 0.013rem;
        }
        .requirementsTableEditingDetails {
            display: flex;
            align-items: center;
            button:nth-of-type(2) {
                margin-left: 16px;
            }
            .lastUpdatedDate {
                font-size: 0.875rem;
                line-height: 1.5rem;
                letter-spacing: 0.016rem;
                color: $main-text-color;
                display: flex;
                margin-left: 16px;
                white-space: nowrap;
                .dateAdded {
                    margin-left: 4px;
                }
            }
        }
    }
}