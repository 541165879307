@use "Styles/colors.module.scss" as colors;
.extractedMetadata {
  margin-bottom: 10px;
  .extractedTechnologyTitle {
    background-color: colors.$fuschia-100;
    padding: 0 2px;
    font-size: 14px;
    margin-right: 4px;
  }

  .justification {
    font-size: 12px;
    margin-top: 10px;
    font-style: italic;
  }

  .chip {
    color: colors.$findest-gray;
    background-color: transparent;

    &:hover {
      background-color: colors.$turquoise-200;
      color: colors.$main-black;
    }
  }
}
