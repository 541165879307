@import 'Styles/Legacy/colors.module.scss';
@import 'Styles/Legacy/buttons.module.scss';

.findestKeywordContainer {
    display: block;
    padding-right: 64px;

    &.searchTermPriorityDropdownStyle {
        &:nth-child(odd) {
            .findestKeyword {
                background-color: $findest-background-03;
            }
        }
    }

    &.searchTermPriorityButtonStyle {
        .findestKeyword {
            border-bottom: 1px solid $findest-gray;
        }
    }

    .findestKeyword {
        display: flex;
        align-items: center;
        height: 2rem;
        padding-right: 8px;
        position: relative;

        .separator {
            margin: 8px 16px;
            background-color: $findest-gray;
            width: 1px;
            height: 16px;
        }

        .priorityContainer {
            display: flex;
            align-items: center;
            column-gap: 4px;
        }

        .valueContainer {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 16px;

            input[type="text"] {
                padding: 0.125rem 0;
                border: none;
                font-family: 'IBM Plex Sans', sans-serif;
                font-size: 14px;
                letter-spacing: 0.496px;
                outline: none;
                width: 100%;
                text-overflow: ellipsis;
                background-color: transparent;

                &::placeholder {
                    color: $findest-dark-gray;
                    font-style: italic;
                }
            }

            .synonymsButtonContainer {
                &:after {
                    content: " ";
                    display: block;
                    position: absolute;
                    height: 16px;
                    width: 100%;
                    bottom: -16px;
                    left: 0;
                    right: 0;
                }
            }
        }

        .deleteContainer {
            position: absolute;
            right: -40px;
            height: 32px;
            width: 32px;
            display: none;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            cursor: pointer;

            svg {
                font-size: 14px;
                color: $findest-dark-gray;
            }

            &:hover {
                background-color: $light-gray;

                svg {
                    color: $findest-red;
                }
            }
        }

        &.findestKeywordDisabled {
            .separator {
                opacity: 0;
            }

            .priorityContainer {
                .prioritySelection {
                    visibility: hidden;
                }
            }

            .valueContainer {
                opacity: 0.3;
                pointer-events: none;
            }
        }
    }

    &:hover {
        .deleteContainer {
            display: flex;
        }
    }
}

.findestSynoymsContainer {
    display: block;
    margin-bottom: 0.5rem;

    .findestTextBox {
        display: flex;
        width: 100%;
    }
}