@import 'Styles/colors.module.scss';

.pageCommentsContainer {
    display: flex;
    flex-direction: column;

    h1 {
        margin-top: 56px;
        user-select: none;

    }

    .pageCommentsThread {
        border-radius: 4px;
        border: 1px solid $light-gray;
        padding: 4px 0;
        margin-bottom: 16px;
        margin-top: 8px;

        .pageComment {
            padding: 8px 32px;
            cursor: default;

            &:hover {
                .pageCommentHeader {
                    .pageCommentActionsContainer {
                        display: flex;
                    }
                }
                
            }

            .pageCommentHeader {
                display: flex;
                justify-content: space-between;

                .pageCommentMetadataContainer {
                    display: flex;

                    .pageCommentIcon {
                        margin: 0;
                    }
                    .pageCommentMetadata {
                        margin-left: 16px;

                        .pageCommentDate {
                            color: $findest-dark-gray;
                        }
                    }
                }
                .pageCommentActionsContainer {
                    display: none;
                    gap: 16px;
                }
            }
            .pageCommentContent {
                margin: 12px 0 16px 48px;
            }
        }

        .createPageComment {
            padding: 8px 32px;
            display: flex;
            align-items: center;

            &.editing {
                align-items: flex-start;
            }

            .createPageCommentIcon {
                margin: 0;
            }

            .createPageCommentPlaceholder {
                color: $findest-dark-gray;
                font-style: italic;
                width: 100%;
                cursor: text;

                &.comment {
                    padding: 4px 0 4px 16px;
                }

                &.reply {
                    border-radius: 4px;
                    border: 1px solid $findest-gray;
                    margin-left: 48px;
                    padding: 12px 16px;

                    &:hover {
                        border-color: $findest-dark-gray;
                    }
                }
            }

            .createPageCommentEditing {
                width: 100%;
                margin-left: 16px;

                &.extraMargin {
                    margin-left: 48px;
                }
            }
        }
    }
}