@import "Styles/colors.module.scss";
@import "Styles/variables.module.scss";

.tooltip {
    background-color: $findest-dark-gray;
    color: white;
    padding: 4px 12px;
    z-index: 100000;
    font-size: $font-size-s;
    min-width: unset !important;
    h4 {
        font-size: $font-size-s !important;
    }
}
