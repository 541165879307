@import "Styles/colors.module.scss";
@import 'Styles/customScrollbar.module.scss';

.settingsItem {
    padding: 0 0 32px 32px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    @include customScrollbar;

    h2 {
        padding: 16px 0 24px 0;
    }

    .settingsItemContentBody {
        .settingsItemInputs {
            .section {
                margin-bottom: 20px;

                .textbox {
                    width: 50%;
                    @media screen and (max-width: 826px) {
                        width: 100%;
                    }
                }

                .sectionTitle {
                    margin-bottom: 8px;
                    color: $main-text-color;
                    font-size: 0.75rem;
                    font-weight: 500;
                    letter-spacing: 1.2px;
                    line-height: 1rem;
                    text-transform: uppercase;
                }

                .footerText {
                    padding: 8px 0;
                    p {
                        display: inline;
                        font-size: 0.875rem;
                        line-height: 1.25rem;
                        letter-spacing: 0.252px;
                        &.link {
                            color: $findest-blue;
                            font-weight: 500;
                            margin-left: 4px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .passwordRequirements {
            p {
                font-size: 0.875rem;
                font-weight: 500;
            }

            .requirementsList {
                font-size: 0.875rem;

                li {
                    padding: 4px 0;

                    &.requirementMet {
                        color: $main-dark-green;
                    }

                    &.requirementNotMet {
                        color: $main-dark-red;
                    }

                    .requirementIcon {
                        margin-right: 6px;
                    }
                }
            }
        }
    }

    .settingsItemFooter {
        margin-top: 16px;
    }

    .disabled {
        opacity: .5;
        pointer-events: none;
    }
    
}
