@import "Styles/colors.module.scss";

.querySearchResultsAnswerContainer {
  .querySearchResultsAnswerTitle {
    font-size: 16px;
    font-weight: 600;
  }
  
  .querySearchResultsAnswerContent {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    margin: 12px 0;
  
    .querySearchResultsIcon {
      width: 32px;
      height: 32px;
  
      svg {
        width: 16px;
      }
    }
  
    .querySearchResultsAnswerBox {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 16px 24px;
      background-color: $light-gray;
      border-radius: 8px;
      gap: 10px;
  
      p {
        font-size: 14px;
        line-height: 20px;
      }
  
      button {
        font-size: 14px;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 8px;
      }
  
      .querySearchResultsContent {
        &.hideContent {
          display: none;
        }
      }
    }
  }
}
