@import "Styles/colors.module.scss";
@import 'Styles/customScrollbar.module.scss';

.groupedListContainer {
    @include customScrollbar;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: white;
    border-radius: 4px;
    z-index: 2;
    min-width: 100%;

    .groupedList {
        .groupTitle {
            font-weight: 500;
            padding: 4px 12px 8px;
            color: $light-text-color;
            font-size: 10px;
            text-transform: uppercase;
            cursor: initial;
            white-space: nowrap;
            line-height: 1rem;
            letter-spacing: 1.5px;
        }
        &:not(:first-of-type) {
            margin-top: 10px;
        }
    }

    div {
        div {
            padding: 6px 12px;
            overflow: hidden;
            display: flex;
            align-items: center;
            cursor: pointer;
        }
        .option {
            font-size: 1rem;
            line-height: 1.5;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &.selected {
                color: white;
                background-color: $secondaryButtonColorSelected;
            }
            &:not(.selected):hover {
                background-color: $main-gray;
            }
        }
    }
}

.groupedListOptionDetailsPopover {
    z-index: 9999 !important;
}

.layerSelectionDropdownPreview {
    .layerPreviewTitle {
        padding: 8px 16px;
        font-weight: 500;
        border-bottom: 1px solid $light-gray;
    }
    .layerPreviewlistItem {
        cursor: initial;
    }
}
