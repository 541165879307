@import "./colors.module.scss";
  
@mixin objectIcon {
    color: $document-count-color;
    &.entity {
       color: $entity-count-color;
    }     
    &.study {
        color: $study-count-color;
    }
    &.query {
        color: $query-count-color;
    }
}
