@import 'Styles/colors.module.scss';
@import 'Styles/typography.module.scss';

.commonDropdown {
    margin-right: auto;

    &.rightAligned {
        margin-left: auto;
        margin-right: 0;
    }

    .grayDropdownSelect {
        background-color: $secondaryButtonColorDefault;
        border: none;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 1.246px;

        &:hover {
            background-color: $secondaryButtonColorHover;
        }
    }
}