@import "Styles/colors.module.scss";

.querySaveResultsContainer {
  &.sticky {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .queryAnswerResultIcon {
    border-radius: 4px;
    color: $white;
    cursor: pointer;
    padding: 8px;
    background-color: $main-purple;
    &:hover {
      background-color: $main-purple-hover;
    }
  }
  .querySaveResultsContent {
    .querySaveResultsHeader {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 6px 0;
      height: 52px;
      background-color: $white;
    
      .querySaveResultsButton {
        display: inline-flex;
        align-items: center;
        gap: 10px;
        padding: 8px 12px;
        background-color: $main-purple;
        border-radius: 4px;
        color: $white;
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1.25rem;
        cursor: pointer;
      }
    
      .querySaveResultsText {
        p {
          font-size: 14px;
        }
      }
    }
    .querySaveResultsPopover {
      background-color: #0000006b;
      position: fixed;
      inset: 0px;
      z-index: 1;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
  
      .querySaveResultsPopoverOverlay {
        background-color: $white;
        width: 512px;
      }
    }
  }
}
