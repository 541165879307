@use "Styles/colors.module.scss" as colors;
@use "Styles/customScrollbar.module.scss" as customScrollbarMixin;

.leftContainer {
  width: 40%;
  max-width: 380px;
  border-right: 1px solid colors.$grey-300;
  display: flex;
  flex-direction: column;
  height: inherit;
  padding-left: 64px;

  .chartContainer {
    padding-right: 16px;
  }

  .entitiesList {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .showAll {
      cursor: pointer;
      border-radius: 4px;
      text-align: left;
      font-size: 0.875rem;
      padding: 7px;
      margin-right: 16px;
      border-bottom: 1px solid colors.$light-gray;
      &:hover {
        background-color: colors.$light-gray;
      }
    }

    ul {
      margin-top: 10px;
      padding-right: 16px;
      overflow-y: auto;
      overflow-x: hidden;
      @include customScrollbarMixin.customScrollbar;
    }
    .entityItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 7px 7px 7px 32px;
      cursor: pointer;
      border-radius: 4px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 0.875rem;
      position: relative;
      width: 100%;

      &::before {
        content: '•';
        position: absolute;
        left: 14px;
        color: inherit;
      }

      &:hover {
        background-color: colors.$light-gray;
      }
      &.selected {
        background-color: colors.$blue-100;
        .icon {
          cursor: pointer;
          color: colors.$findest-blue;
          float: right;
        }
      }
    }
  }
}

.rightContainer {
  overflow-y: auto;
  overflow-x: hidden;
  @include customScrollbarMixin.customScrollbar;
  height: inherit;
  padding: 0 64px 0 32px;
  flex: 1;
  .entityContainer {
    & + .entityContainer {
      margin-top: 24px;
    }
    &:not(:last-of-type) {
      border-bottom: 1px solid colors.$findest-gray;
    }
    .entityTitleContainer {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 0.5rem 0;
      .technologyTitle {
        font-size: 24px;
        font-weight: 600;
        margin-right: 16px;
      }
      .backIcon {
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }
}
