@import "Styles/colors.module.scss";

.linkCreateEntityModal {
    padding: 24px 64px;
    display: flex;
    flex-direction: column;
    width: 800px;
    max-width: 80%;

    .linkCreateEntityModalHeader {
        position: absolute;
        right: 16px;
        top: 16px;
        z-index: 1;
    }

    .entityTypeDropdownContainer {
        margin-right: auto;
    }

    .connectedObjectContainer {
        margin: 16px 0 32px 0;

        h6 {
            color: $findest-dark-gray;
            margin-bottom: 4px;
        }
        .connectedObject {
            display: flex;
            column-gap: 16px;

            .connectToNewObject {
                font-style: italic;
                font-size: 1rem;
                color: $findest-dark-gray;
                display: flex;
                align-items: center;
                padding: 0 12px;
                height: 32px;
                border-radius: 4px;
                cursor: pointer;
        
                &:hover {
                    background-color: $secondaryButtonColorDefault;
                    color: $findest-text;
                }
            }

            .objectSearchPopupContainer {
                width: 512px;
                padding: 0 0 4px 0;
                overflow: hidden;
                display: flex;
            }
        }
    }

    .actionbuttonsContainer {
        display: flex;
        column-gap: 16px;
    }

    .isDisabled {
        opacity: 0.15;
        pointer-events: none;
    }
}