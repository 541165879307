@import 'Styles/customScrollbar.module.scss';
  
.popover {
	width: max-content;
	max-width: calc(100vw - 20px);
	background-color: white;
	box-shadow: -3px 3px 16px rgba(0,0,0,0.16);
	padding: 0.25rem 0rem;
	border-radius: 4px;
	font-size: 14px;
	overflow-y: auto;
	overflow-x: hidden;
	z-index: 5;
	@include customScrollbar;
}
