@import "Styles/colors.module.scss";

.maintenanceBox {
  background-color: $findest-warning-background-yellow;
  padding: 16px;
  align-items: flex-start;
  z-index: 1000;
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid $findest-warning-yellow;
  border-radius: 8px;

  .iconWrapper {
    display: inline-flex;
    width: 32px;
    height: 32px;
    padding: 8px;
    margin-top:8px;
    border-radius: 50%;    
    background-color: $findest-warning-yellow;
  }

  .maintenanceContent {
    padding:8px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
    font-size: 0.825rem;
    line-height: 20px;
  }

  .moreInformation {
    color: $findest-blue;
    font-size: 0.825rem;
    font-weight: 700;
    line-height: 20px;
    cursor: pointer;
    text-decoration: underline;
    padding-top: 16px;
  }
}
