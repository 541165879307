@import 'Styles/colors.module.scss';

.exportSearchResultsButton {
    height: 32px;
    padding: 0 16px;
    border-radius: 0.25rem;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1.246px;
    cursor: pointer;
    border: 0;
    font-family: 'IBM Plex Sans', sans-serif;
    outline: none;
    background-color: $primaryButtonColorDefault;
    color: $white;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    white-space: nowrap;

    &:hover {
        background-color: $primaryButtonColorHover;
    }

    > svg {
        margin-right: 10px;
    }

    &.disabled {
        opacity: .15;
        cursor: not-allowed;
        &:hover {
            background-color: $primaryButtonColorDefault;
        }
    }

    &.cancel {
        color: $primaryButtonColorDefault;
        background-color: white;
        &:hover {
            background-color: $primaryButtonColorDisabled;
        }
    }

    &.primary {
        color: $main-text-color;
        background-color: white;
        &:hover {
            background-color: $secondaryButtonColorDefault;
        }
    }

    &.secondary {
        color: $main-text-color;
        background-color: $secondaryButtonColorDefault;
        &:hover {
            background-color: $secondaryButtonColorHover;
        }
    }

    &.tertiary {
        color: $main-text-color;
        background-color: white;
        &:hover {
            background-color: $secondaryButtonColorDefault;
        }
    }
}