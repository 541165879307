.scrollbarCustomThin::-webkit-scrollbar {
    height: 14px;
    width: 6px;
    background: #ffffff00;
}
.scrollbarCustomThin::-webkit-scrollbar-thumb{background:#ddd}
.scrollbarCustomThin::-webkit-scrollbar-thumb:hover{background:#b2b2b2}
.scrollbarCustomThin::-webkit-scrollbar-thumb:active{background:#b2b2b2}

.scrollbarCustomThinX::-webkit-scrollbar {
    height: 4px;
    width: 6px;
    background: #ffffff00;
}
.scrollbarCustomThinX::-webkit-scrollbar-thumb{background:#ddd}
.scrollbarCustomThinX::-webkit-scrollbar-thumb:hover{background:#b2b2b2}
.scrollbarCustomThinX::-webkit-scrollbar-thumb:active{background:#b2b2b2}