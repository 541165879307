@import "Styles/colors.module.scss";

.checkboxContainer {
    cursor: pointer;
    display: flex;
    align-items: center;

    input {
        display: none;
    }

    &.disabled {
        cursor: not-allowed;
        opacity: .5;
    }

    .text {
        margin-left: 16px;
        font-size: 1rem;
    }

    .checkmark {
        height: 16px;
        width: 16px;
        border: 1px solid $findest-dark-gray;
        background-color: $white;
        border-radius: 2px;
        margin-right: 0.375rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .checkmark:before {
        background-position: 50%;
        background-repeat: no-repeat;
        content: "";
        display: inline-block;
        height: 14px;
        width: 14px;
    }

    input:checked ~ .checkmark:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7.457' viewBox='0 0 10 7.457'%3E%3Cpath id='check-solid' d='M3.4,72.409l-3.25-3.25a.5.5,0,0,1,0-.707l.707-.707a.5.5,0,0,1,.707,0L3.75,69.934,8.44,65.245a.5.5,0,0,1,.707,0l.707.707a.5.5,0,0,1,0,.707L4.1,72.409a.5.5,0,0,1-.707,0Z' transform='translate(0 -65.098)' fill='%23007aff'/%3E%3C/svg%3E%0A");
    }

    &.hide {
        .checkmark {
            visibility: hidden;
        }
    }

    &.black {
        padding: 8px;
        border-radius: 4px;
        &:not(.disabled):hover {
            background-color: $main-gray;
            .checkmark {
                border-color: $main-black;
            }
        }
        .checkmark {
            border-color: $findest-gray;
            margin: 0;
        }
        &.checked {
            .checkmark {
                border-color: $main-black;
                &:before {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7.457' viewBox='0 0 10 7.457'%3E%3Cpath id='check-solid' d='M3.4,72.409l-3.25-3.25a.5.5,0,0,1,0-.707l.707-.707a.5.5,0,0,1,.707,0L3.75,69.934,8.44,65.245a.5.5,0,0,1,.707,0l.707.707a.5.5,0,0,1,0,.707L4.1,72.409a.5.5,0,0,1-.707,0Z' transform='translate(0 -65.098)' fill='%23252525'/%3E%3C/svg%3E%0A");
                }
            }
        }
        &.partiallySelected {
            .checkmark {
                border-color: $main-black;
            }
            input ~ .checkmark:before {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='2' viewBox='0 0 9 2'%3E%3Cline id='Line_126' data-name='Line 126' x2='9' transform='translate(0 1)' fill='none' stroke='%23252525' stroke-width='2'/%3E%3C/svg%3E%0A");
            }
        }
    }

    &.blue {
        border-radius: 4px;
        .checkmark {
            border-color: $findest-blue;
            margin: 0;
        }
        &.checked {
            .checkmark {
                background-color: $findest-blue;
                &:before {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7.457' viewBox='0 0 10 7.457'%3E%3Cpath id='check-solid' d='M3.4,72.409l-3.25-3.25a.5.5,0,0,1,0-.707l.707-.707a.5.5,0,0,1,.707,0L3.75,69.934,8.44,65.245a.5.5,0,0,1,.707,0l.707.707a.5.5,0,0,1,0,.707L4.1,72.409a.5.5,0,0,1-.707,0Z' transform='translate(0 -65.098)' fill='white'/%3E%3C/svg%3E%0A");
                }
            }
        }
    }

    &.grayDisabled {
        background-color: $findest-gray;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7.457' viewBox='0 0 10 7.457'%3E%3Cpath id='check-solid' d='M3.4,72.409l-3.25-3.25a.5.5,0,0,1,0-.707l.707-.707a.5.5,0,0,1,.707,0L3.75,69.934,8.44,65.245a.5.5,0,0,1,.707,0l.707.707a.5.5,0,0,1,0,.707L4.1,72.409a.5.5,0,0,1-.707,0Z' transform='translate(0 -65.098)' fill='white'/%3E%3C/svg%3E%0A");
        background-position: 50%;
        background-repeat: no-repeat;
        border-radius: 4px;
        display: inline-block;
        height: 16px;
        pointer-events: none;
        .checkmark {
            border-color: $findest-gray;
            margin: 0;
            visibility: visible;
        }

        input:checked ~ .checkmark:before {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7.457' viewBox='0 0 10 7.457'%3E%3Cpath id='check-solid' d='M3.4,72.409l-3.25-3.25a.5.5,0,0,1,0-.707l.707-.707a.5.5,0,0,1,.707,0L3.75,69.934,8.44,65.245a.5.5,0,0,1,.707,0l.707.707a.5.5,0,0,1,0,.707L4.1,72.409a.5.5,0,0,1-.707,0Z' transform='translate(0 -65.098)' fill='white'/%3E%3C/svg%3E%0A");
        }
    }

    &.textLabel {
        padding: 4px 16px;

        &:hover {
            background-color: $main-gray;
            .checkmark {
                border-color: $main-black;
            }
        }

        .checkmark {
            border-color: $findest-gray;
            margin: 0;
        }
        
        span {
            margin-left: 8px;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.144px;
            user-select: none;
        }

        &.checked {
            .checkmark {
                border-color: $main-black;
                &:before {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7.457' viewBox='0 0 10 7.457'%3E%3Cpath id='check-solid' d='M3.4,72.409l-3.25-3.25a.5.5,0,0,1,0-.707l.707-.707a.5.5,0,0,1,.707,0L3.75,69.934,8.44,65.245a.5.5,0,0,1,.707,0l.707.707a.5.5,0,0,1,0,.707L4.1,72.409a.5.5,0,0,1-.707,0Z' transform='translate(0 -65.098)' fill='%23252525'/%3E%3C/svg%3E%0A");
                }
            }
        }
    }
}