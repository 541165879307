@import 'Styles/colors.module.scss';

.objectSwitcher {
    .objectSwitcherMainContent {
        border-radius: 4px;
        border: 1px solid $findest-blue;

        .selectedObject {
            margin: 4px 8px;
            .itemText {
                font-size: .875rem;
            }
        }

        .title {
            font-size: 0.875rem;
            letter-spacing: 0.252px;
            line-height: 1.375rem;
        }
    }

    .objectSwitcherButton {
        display: flex;
        align-items: center;
        background-color: $findest-blue;
        padding: 8px 4px 8px 16px;
        border-radius: 2px;
        cursor: pointer;

        .objectCount {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: $white;
            color: $main-text-color;
            font-size: 14px;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 8px;
        }

        .iconContainer {
            font-size: .75rem;
            margin-left: auto;
            min-width: 24px;
            min-height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            padding: 2px 4px;

            &:hover,
            &.active {
                background-color: $white-25;
            }
        }

        &:hover {
            .iconContainer {
                background-color: $white-25;
            }
        }
    }
}

.objectSwitcherPopover {
    max-width: 480px !important;

    .popoverTitle {
        color: $light-text-color;
        padding: 8px 24px 2px;
    }

    .popoverListItemContainer {
        margin: 0;

        .popoverListItemRightContent {
            flex-direction: row-reverse;
        }

        .popoverListItemIconContainer {
            margin-left: 16px;
        }

        .popoverListItemButton {
            background-color: $light-gray;
            font-weight: 400;
            margin: 0 16px 0 32px;
            height: 28px;
        }

        &.selectedObject {
            cursor: default;

            .popoverListItemContent {
                cursor: default;
            }

            .popoverListItemButton {
                background-color: $secondaryButtonColorHover;
                color: $findest-blue;
                cursor: default;
            }
        }

        &:hover {
            &:not(.selectedObject) {
                .popoverListItemButton {
                    background-color: $findest-gray;
                }
            }
        }
    }
}

.listItemIconContainer {
    margin-left: 0 !important;
}
